import { useEffect, useRef } from 'react'

import { useEventListenerProps } from './useEventListener.types'

export default function useEventListener({
  eventType,
  callback,
  element = window
}: useEventListenerProps) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (element == null) return
    const handler = (e) => callbackRef.current(e)
    element.addEventListener(eventType, handler)

    return () => element.removeEventListener(eventType, handler)
  }, [eventType, element])
}
