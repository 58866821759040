import { makeAxiosHttpClient } from '@k_frontend/core'
import { RemoteCartGatewayProtocol } from 'data/protocols/remoteCartGatewayProtocol'
import { RemoteCartGateway } from 'infra/gateway/cart/remoteCart'
import { BACKEND_ORDER_CARRINHO, endpoint } from 'settings/kernel'

export function makeRemoteCartGateway(): RemoteCartGatewayProtocol {
  return new RemoteCartGateway(
    makeAxiosHttpClient(),
    `${endpoint}${BACKEND_ORDER_CARRINHO}`
  )
}
