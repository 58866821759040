import { tv } from 'tailwind-variants'

export const optionStyle = tv({
  slots: {
    input:
      'flex appearance-none items-center max-w-16 max-h-16 min-w-16 min-h-16 border border-solid border-black-700 rounded-full',
    price: 'text-xs font-normal text-right text-black-700 items-center'
  },
  variants: {
    checked: {
      true: {
        input: `border-secondary-500 
				after:content-[''] 
				after:block
				after:w-8 
				after:h-8 
				after:bg-secondary-500
				after:rounded-full
				after:relative
				after:-translate-x-1/2
				after:left-1/2`,
        price: 'font-bold'
      }
    }
  }
})
