const SUCCESS_FALSE_ERROR = 'SUCCESS_FALSE_ERROR'
const REQUEST_ERROR = 'REQUEST_ERROR'
const AXIOS_EXCEPTION_ERROR = 'AXIOS_EXCEPTION_ERROR'
const AUTH_EXCEPTION_ERROR = 'AUTH_EXCEPTION_ERROR'

module.exports = {
  SUCCESS_FALSE_ERROR,
  REQUEST_ERROR,
  AXIOS_EXCEPTION_ERROR,
  AUTH_EXCEPTION_ERROR
}
