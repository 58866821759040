import styled from 'styled-components'

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.xs};
  gap: ${({ theme }) => theme.spacing.xxxs};

  > span {
    display: flex;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    padding: ${({ theme }) => theme.spacing.sm} 0;
  }
`
