import { Request, Response, StatusCode } from './httpClient.types'

export class HttpClient {
  async request<T, R = any>(data: Request): Promise<Response<T, R>> {
    let httpClientTimeout: NodeJS.Timeout | null = null
    const controller = new AbortController()
    const { signal } = controller
    const hasTimeout = !!data?.timeout

    if (hasTimeout) {
      httpClientTimeout = setTimeout(() => controller.abort(), data.timeout)
    }

    try {
      const urlWithParams = this.buildUrlWithParams(data.url, data.params)
      const validContentTypes = ['application/json', 'application/vnd.api+json']

      const response = await fetch(urlWithParams, {
        method: data.method,
        headers: data.headers,
        body: data.body ? JSON.stringify(data.body) : undefined,
        cache: data.cache,
        signal: signal
      })

      let responseBody
      const contentType = response.headers.get('Content-Type')

      if (validContentTypes.some((type) => contentType?.includes(type))) {
        responseBody = await response.json()
      } else {
        responseBody = await response.text()
      }

      if (response.ok) {
        return {
          body: responseBody,
          statusCode: response.status
        }
      } else {
        return {
          error: responseBody,
          statusCode: response.status
        }
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'Erro de conexão desconhecido'
      return {
        error: {
          message: 'Erro de conexão',
          details: errorMessage
        } as R,
        statusCode: StatusCode.serverError
      }
    } finally {
      if (hasTimeout) clearTimeout(httpClientTimeout)
    }
  }

  private buildUrlWithParams(
    url: string,
    params?: Record<string, string | number | boolean>
  ): string {
    if (!params) {
      return url
    }

    const queryString = Object.entries(params)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value.toString())}`
      )
      .join('&')

    return `${url}?${queryString}`
  }
}
