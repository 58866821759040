const self = {}
self.ConcludeRequest = (data) => {
  // pegar informações do post de confirmação
  const paymentHash = data.req.body.paymentHash ? data.req.body.paymentHash : ''
  const paymentCode = data.req.body.paymentOption
    ? data.req.body.paymentOption
    : '0'

  const paypal = data.req.body.paypalPaymentId
    ? {
        payment_id: data.req.body.paypalPaymentId,
        payer_id: data.req.body.paypalPayerId
      }
    : {}

  const pagamento = {
    codigo: paymentCode,
    hash: paymentHash,
    paypal
  }

  const { donationValue, institutionCode, gReCaptchaResponse } = data.req.body
  let doacao = {}
  if (parseInt(donationValue)) {
    doacao = {
      instituicao_codigo: parseInt(institutionCode),
      valor: parseFloat(donationValue)
    }
  }

  const remoteAddress = data.req.socket.remoteAddress || data.req.ip || 0
  const httpXForwardedFor = data.req.headers['x-forwarded-for'] || 0

  return {
    loja: '001',
    cupom: data.coupon ? data.coupon : '',
    id_cliente: data.clientId,
    session: data.session,
    pagamento,
    doacao,
    origem: data.origin || '',
    remote_addr: remoteAddress,
    http_x_forwarded_for: httpXForwardedFor,
    'g-recaptcha-response': gReCaptchaResponse
  }
}

self.ConcludeResponse = (data) => ({
  sellers: data?.sellers || [],
  totals: {
    subtotal: data.totals.subtotal,
    cashPrice: data.totals.cash_price,
    discount: data.totals.cash_save,
    shipping: data.totals.shipping
  },
  coupon: data?.cupom?.id_cupom || '',
  payment: {
    code: data.payment.code,
    method: data.payment.method,
    emv: data.payment.emv,
    image_base64: data.payment.image_base64,
    category: data.payment.category,
    discount: data.payment.cashDiscount || 0,
    couponDiscount: data.payment.couponDiscount || 0
  },
  order: {
    action: data.order.action,
    code: data.order.code
  }
})

module.exports = self
