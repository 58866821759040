import { tv } from 'tailwind-variants'

export const discount = tv({
  base: 'text-xs flex rounded-8 text-white-500 px-4 h-16 group-hover:hidden desktop:mt-4 desktopLarge:mt-2',
  variants: {
    isPrime: {
      true: 'bg-black-900',
      false: 'bg-secondary-500'
    },
    isClosedOffer: {
      true: 'bg-black-500'
    }
  }
})
