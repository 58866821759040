import { MouseEvent } from 'react'

import { IconInformation } from '@k_frontend/icons'
import { ProductServiceState, useProductService } from 'hooks/useProductService'
import { tailwindTheme } from 'theme/tailwindTheme'
import { currency } from 'utils/functions'
import { shallow } from 'zustand/shallow'

import {
  OPTION_DESCRIPTION_PREFIX,
  OPTION_DESCRIPTION_SUFIX
} from './Option.constants'
import { OptionProps } from './Option.types'

const useProductServiceSelector = (state: ProductServiceState) => ({
  selectedServices: state.selectedServices,
  setSelectedServiceOption: state.setSelectedServiceOption,
  setSelectedServicesPrice: state.setSelectedServicesPrice
})

function Option({
  option,
  caption = '',
  description = '',
  recommended,
  serviceCode,
  handleOpenModal
}: OptionProps) {
  const optionDescriptionPrefix = OPTION_DESCRIPTION_PREFIX[serviceCode]
  const optionDescriptionSufix = OPTION_DESCRIPTION_SUFIX[serviceCode]

  const {
    selectedServices,
    setSelectedServiceOption,
    setSelectedServicesPrice
  } = useProductService(useProductServiceSelector, shallow)

  const selectedService = selectedServices[serviceCode] || {
    code: 0,
    name: serviceCode
  }

  const showRecommended = recommended && (
    <span className='block w-full bg-secondary-500 p-4 text-center text-xs font-semibold uppercase text-white-500'>
      Recomendado
    </span>
  )

  const optionDescription =
    option?.code !== 0
      ? `${optionDescriptionPrefix} ${option?.description} ${optionDescriptionSufix}`
      : option.description

  const optionPrice =
    option?.parceledPrice > 0 ? currency(option.parceledPrice) : ''

  const choosed = selectedService.code === option.code

  const showTerms = option?.code !== 0 && (
    <button
      type='button'
      className='px-4'
      onClick={openModal}
      aria-label='Termos de uso e autorização'
    >
      <IconInformation
        color={tailwindTheme.colors.secondary[500]}
        height={16}
        width={16}
        aria-hidden
      />
    </button>
  )

  const showDescription = description && (
    <span className='text-xs text-black-800'>{description}</span>
  )

  function handleOptionChange() {
    setSelectedServicesPrice(serviceCode, option.parceledPrice, option.price)
    setSelectedServiceOption(serviceCode, option.code, option.description)
  }

  function openModal(e: MouseEvent<HTMLButtonElement>) {
    e.stopPropagation()
    e.preventDefault()
    handleOptionChange()
    handleOpenModal(option.code)
  }

  return (
    <li
      className={` flex flex-col items-center self-end overflow-hidden rounded-4 border border-solid text-center ${
        choosed ? 'border-secondary-500' : 'border-black-500'
      }`}
    >
      <label className='flex w-full cursor-pointer select-none flex-col'>
        {showRecommended}

        <div className='flex:1 flex h-max min-h-[62px] items-center gap-8 p-12'>
          <input
            className='flex size-[1.125rem] min-w-[1.125rem] cursor-pointer appearance-none items-center justify-center rounded-full 
                       border border-solid border-black-500 checked:border-secondary checked:before:size-12
                     checked:before:rounded-full checked:before:bg-secondary
                      '
            type='radio'
            checked={selectedService.code === option.code}
            value={option.code}
            name={serviceCode}
            onChange={handleOptionChange}
          />

          <div className='flex flex-col items-start'>
            <div className='text-left'>
              <span className='text-xs font-semibold text-black-800'>
                {optionDescription}
              </span>
              {showTerms}
            </div>
            {showDescription}
          </div>

          <div className='ml-auto flex flex-col items-end'>
            <span className='text-right text-[0.625rem] text-black-700'>
              {caption}
            </span>
            <strong className='text-xs font-bold text-black-800'>
              {optionPrice}
            </strong>
          </div>
        </div>
      </label>
    </li>
  )
}

export default Option
