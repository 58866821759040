import { setCookie } from 'cookies-next'
import { STORE_CODE_SHIPPING, STORE_CODE_SHIPPING_MOBILE, MOBILE_STORE, DESKTOP_STORE } from 'settings/kernel'
import Cookies from 'universal-cookie'

export function handleStore():number {
  const cookieInstance = new Cookies()
  const storeCookie = cookieInstance.get('storeCode')
  const store = storeCookie === '001' ? STORE_CODE_SHIPPING : STORE_CODE_SHIPPING_MOBILE
  return store
}

export function handleCacheStore(req, res) {
  let isMobileScreen = req?.cookies?.isMobile
  const isMobileDevice =
    req?.headers['cloudfront-is-mobile-viewer'] === 'true' ||
    req?.headers['cloudfront-is-tablet-viewer'] === 'true'

  if (typeof isMobileScreen === 'undefined') {
    isMobileScreen = isMobileDevice
  } else {
    isMobileScreen = isMobileScreen === 'true'
  }
  setCookie('isMobile', isMobileScreen.toString(), { req, res })
  setCookie('isMobileDevice', isMobileDevice.toString(), { req, res })

  if (!req?.cookies?.storeCode) {
    const storeCode = isMobileDevice ? MOBILE_STORE : DESKTOP_STORE
    setCookie('storeCode', storeCode, { req, res })
  }
}
