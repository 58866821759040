import { useEffect, useRef, useState } from 'react'

import { IconCollapseHeader } from '@k_frontend/icons'
import ThemeDefault from 'theme'

import * as S from './DropdownColumn.styles'
import { DirectionEnum, DropdownColumnProps } from './DropdownColumn.types'

export default function DropdownColumn({
  list,
  handleOpenMenu = () => {
    /** intentional empty function */
  },
  handleMouseLeave = () => {
    /** intentional empty function */
  },
  onClick = () => {
    /** intentional empty function */
  },
  hideArrow = false,
  showRedirectLink
}: DropdownColumnProps) {
  const [showUpButton, setShowUpButton] = useState(true)
  const [showDownButton, setShowDownButton] = useState(false)
  const [scrollInterval, setScrollInterval] = useState(null)
  const [selectedItem, setSelectedItem] = useState(null)
  const colRef = useRef()

  function handleOpenMenuAndSelect(slug, name) {
    setSelectedItem(slug)
    handleOpenMenu(slug, name)
  }

  function handleScroll(target: HTMLDivElement) {
    const { scrollTop, scrollHeight, offsetHeight } = target
    const newShowUpButton = scrollTop > 0
    const newShowDownButton = scrollTop + offsetHeight < scrollHeight

    if (newShowUpButton !== showUpButton) setShowUpButton(newShowUpButton)
    if (newShowDownButton !== showDownButton)
      setShowDownButton(newShowDownButton)
  }

  function handleButtons(target: HTMLDivElement, direction: DirectionEnum) {
    const SCROLL_UNIT = 32
    const top = {
      [DirectionEnum.UP]: -SCROLL_UNIT,
      [DirectionEnum.DOWN]: SCROLL_UNIT
    }

    target.scrollBy({
      top: top[direction],
      behavior: 'smooth'
    })
  }

  function getButtonArrow() {
    return (
      <IconCollapseHeader
        width={ThemeDefault.spacing.sm}
        height={ThemeDefault.spacing.sm}
        color={ThemeDefault.colors.black(800)}
      />
    )
  }

  function getLinkArrow(linkChildren: number) {
    const showArrow = !hideArrow && linkChildren > 0

    if (!showArrow) return null
    return (
      <div>
        <IconCollapseHeader
          width={ThemeDefault.spacing.xs}
          height={ThemeDefault.spacing.xs}
          color={ThemeDefault.colors.black(800)}
        />
      </div>
    )
  }

  useEffect(() => handleScroll(colRef.current), [list, colRef.current])
  useEffect(() => {
    const listReachedTopOrBottom = !showUpButton || !showDownButton
    if (listReachedTopOrBottom) {
      clearInterval(scrollInterval)
    }
  }, [showUpButton, showDownButton])

  return (
    <S.Wrapper>
      <S.Col
        ref={colRef}
        onScroll={({ target }) => handleScroll(target as HTMLDivElement)}
      >
        {showRedirectLink}
        {showUpButton && (
          <S.ScrollUpButton
            onMouseEnter={() =>
              setScrollInterval(
                setInterval(
                  () => handleButtons(colRef.current, DirectionEnum.UP),
                  250
                )
              )
            }
            onMouseLeave={() => clearInterval(scrollInterval)}
          >
            {getButtonArrow()}
          </S.ScrollUpButton>
        )}
        {showDownButton && (
          <S.ScrollDownButton
            onMouseEnter={() =>
              setScrollInterval(
                setInterval(
                  () => handleButtons(colRef.current, DirectionEnum.DOWN),
                  250
                )
              )
            }
            onMouseLeave={() => clearInterval(scrollInterval)}
          >
            {getButtonArrow()}
          </S.ScrollDownButton>
        )}
        {list.map((item) => (
          <S.Item
            className={`itemCategoriaMenu ${
              selectedItem === item.slug ? 'selected' : ''
            }`}
            key={item.slug}
            onClick={() => {
              handleOpenMenuAndSelect(item.slug, item.name)
              onClick({
                slug: item.slug,
                name: item.name,
                countChildren: item.countChildren
              })
            }}
            onMouseLeave={() => handleMouseLeave()}
          >
            {item.name}
            {getLinkArrow(item.countChildren)}
          </S.Item>
        ))}
      </S.Col>
    </S.Wrapper>
  )
}
