import { LocalIsMobileGatewayProtocol } from 'data/protocols/localIsMobileGatewayProtocol'
import { LoadIsMobileUseCaseDomain } from 'domain/usecases/loadIsMobile'

export class LoadIsMobileUseCase implements LoadIsMobileUseCaseDomain {
  private readonly localIsMobileGateway: LocalIsMobileGatewayProtocol

  constructor(localIsMobileGateway: LocalIsMobileGatewayProtocol) {
    this.localIsMobileGateway = localIsMobileGateway
  }

  load(): boolean {
    return this.localIsMobileGateway.load()
  }
}
