import { IconFlashBadgeLeftTip } from '@k_frontend/icons'
import { OfferInfoHeaderTagStyledProps } from 'components/Product/Product.types'
import { Ellipsis } from 'components/Typograph'
import { m } from 'framer-motion'
import styled, { css } from 'styled-components'
import { tv } from 'tailwind-variants'
import { getCardColorByState } from 'utils/getCardColorByState'
import {
  ContainerFooterHover,
  ContainerFooterNoHover
} from '../../Footer/Footer.styles'

export const cardWrapper = tv({
  base: 'p-[2px] rounded-4 group',
  variants: {
    type: {
      normal:
        'bg-white shadow-[0_0_1px_rgba(40,41,61,0.08),0_0.5px_2px_rgba(96,97,112,0.16)] hover:shadow-lg',
      legendary: 'card-wrapper card-wrapper-legendary',
      blackLegendary: 'card-wrapper card-wrapper-legendary-black'
    }
  }
})

export const CardHeader = tv({
  slots: {
    headerWrapper:
      'relative flex items-start justify-between w-full p-8 pb-0 h-48',
    headerNoHover: 'flex items-center justify-between',
    headerHover: 'hidden gap-2 items-start justify-between'
  },
  variants: {
    isHovered: {
      true: {
        headerNoHover: 'hidden',
        headerHover: 'block'
      }
    }
  }
})

export const ContainerHeaderHover = styled.div`
  display: none;
  gap: ${({ theme }) => theme.rem(2)};
  align-items: flex-start;
  justify-content: space-between;
  button {
    background: transparent;
  }
`

export const ContainerHeaderNoHover = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Flash = styled(IconFlashBadgeLeftTip)`
  position: absolute;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.md};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.colors.primary(700)};
  right: ${({ theme }) => theme.rem(-10)};
  top: 0;
  transform: translateX(${({ theme }) => theme.rem(40)});
  transition: transform 200ms ease;
  will-change: transform;
`

export const Product = styled(m.article)`
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: ${({ theme }) => theme.rem(474)};
  background: ${({ theme }) => theme.colors.white(500)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: box-shadow 200ms ease;

  img {
    object-fit: contain;
  }

  &:hover {
    ${ContainerHeaderHover} {
      display: flex;
    }

    ${ContainerHeaderNoHover} {
      display: none;
    }

    ${ContainerFooterHover} {
      display: flex;
    }

    ${ContainerFooterNoHover} {
      display: none;
    }

    ${Flash} {
      transform: translateX(0);
    }
  }
`

export const ProductBody = styled.a<{ hasNoImage: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spacing.xxs} 0 0`};
  text-decoration: none;
  width: 100%;
  overflow: hidden;

  ${({ hasNoImage }) => {
    if (hasNoImage)
      return css`
        img {
          display: none;
        }
      `
  }}

  img {
    height: ${({ theme }) => theme.rem(162)};
    width: auto;
    max-width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    img {
      padding: 0 ${({ theme }) => theme.spacing.xxs};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    img {
      padding: 0;
    }
  }
`

export const BodyInfo = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacing.xxs}`};

  > button {
    display: flex;
  }
`

export const IconGiftContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  svg path {
    fill: ${({ theme }) => theme.colors.primary(300)};
  }
`

export const GiftTooltipText = styled.span`
  ${({ theme }) => theme.font(12, 18, 400)};
  color: ${({ theme }) => theme.colors.white(500)};
`

export const ContentProductName = styled.div`
  width: 100%;
`

export const ProductName = styled(Ellipsis)`
  ${({ theme }) => theme.font(14, 20, 600)};
  margin-top: ${({ theme }) => theme.spacing.xxs};
  color: ${({ theme }) => theme.colors.black(800)};
  min-height: ${({ theme }) => theme.rem(60)};
`

export const ProductFooter = styled.div`
  width: 100%;
  margin-top: auto;
  padding: ${({ theme }) => theme.spacing.xxs};
`

export const HeaderButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xxs};
`

export const OfferInfoHeaderColumn = styled.div`
  line-height: 1;
`

export const WrapperRatingStars = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.font(10)}
  gap: ${({ theme }) => theme.spacing.xxs};
  color: ${({ theme }) => theme.colors.black(700)};
`

export const SponsoredText = styled.span`
  display: flex;
  flex: 1;
  ${({ theme }) => theme.font(12)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black(600)};
`

export const linkToProductPage = styled.a`
  display: flex;
  align-items: center;
`
