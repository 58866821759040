import { dataLayerEventAddService } from '@k_frontend/core'
import { Button } from '@kbm/button'
import * as Dialog from '@radix-ui/react-dialog'
import { ProductServiceState, useProductService } from 'hooks/useProductService'
import { NavigationProps } from './Navigation.types'

import { IconServices } from '@k_frontend/icons'
import { currency } from 'utils/functions'
import { StepsEnum } from '../MiniPreCart.types'

const useProductServiceSelector = (state: ProductServiceState) => ({
  clearSelectedServices: state.clearSelectedServices,
  formatServicesToDatalayer: state.formatServicesToDatalayer,
  servicesTotalPrice: state.servicesTotalPrice
})

export default function Navigation({ step, setStep }: NavigationProps) {
  const {
    clearSelectedServices,
    formatServicesToDatalayer,
    servicesTotalPrice
  } = useProductService(useProductServiceSelector)

  const primaryServiceButton = servicesTotalPrice
    ? 'Adicionar serviços'
    : 'Avançar sem serviços'
  const secondaryServiceButton = servicesTotalPrice
    ? ' Continuar sem serviço'
    : 'Continuar comprando'

  function redirectToCart() {
    window.location.href = '/carrinho'
  }

  function addServices() {
    dataLayerEventAddService(formatServicesToDatalayer())
    setStep(StepsEnum.PRODUCT)
  }

  function skipServiceAddition() {
    clearSelectedServices()
    setStep(StepsEnum.PRODUCT)
  }

  if (step === StepsEnum.PRODUCT)
    return (
      <div className='flex flex-col gap-8 mt-8 px-16'>
        <Button
          data-testid='go-to-cart'
          key='go-to-cart'
          variant={{
            full: true,
            size: '48'
          }}
          onClick={redirectToCart}
        >
          Ir para o carrinho
        </Button>
        <Dialog.Close asChild>
          <Button
            variant={{
              full: true,
              size: '48',
              type: 'outlined'
            }}
          >
            Continuar comprando
          </Button>
        </Dialog.Close>
      </div>
    )

  if (step === StepsEnum.SERVICE) {
    return (
      <div className='flex flex-col gap-8 mt-8 px-16'>
        <div className='flex gap-8 items-center'>
          <IconServices height={14} width={14} aria-hidden />
          <span
            className='text-base text-black-800 font-bold uppercase'
            aria-label='Valor total dos Serviços'
          >
            Serviços
          </span>
          <span className='ml-auto text-sm text-black-800 font-bold uppercase'>
            {currency(servicesTotalPrice)}
          </span>
        </div>

        <Button
          data-testid='add-service'
          key='add-service'
          variant={{
            full: true,
            size: '48'
          }}
          onClick={addServices}
        >
          {primaryServiceButton}
        </Button>

        <Button
          variant={{
            full: true,
            size: '48',
            type: 'outlined'
          }}
          onClick={skipServiceAddition}
        >
          {secondaryServiceButton}
        </Button>
      </div>
    )
  }
}
