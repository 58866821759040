const { BACKEND_LOGOUT } = require('../../settings/kernel')

const self = {}
// self.redirectToCart = (e) => {
//   e.setRedirect(CART_URL)
//   return e
// }

// self.redirectToPayment = (e) => {
//   e.setRedirect(PAYMENT_URL)
//   return e
// }

self.redirectToLogout = (e) => {
  e.setRedirect(BACKEND_LOGOUT)
  return e
}

self.redirectToHome = (e) => {
  e.setRedirect('/')
  return e
}

module.exports = self
