import styled, { css } from 'styled-components'

import { OfferContainerStyledProps } from '../Product.types'

export const FooterPrime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  max-height: ${({ theme }) => theme.rem(38)};

  width: 100%;
  height: 100%;

  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.colors.black(900)};
  color: ${({ theme }) => theme.colors.white(500)};

  span {
    ${({ theme }) => theme.font(12, 14, 400)};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${({ theme }) => theme.spacing.xxxs};
    padding: 0 ${({ theme }) => theme.spacing.xxxs};
  }

  b {
    ${({ theme }) => theme.font(12, 14, 700)};
    width: fit-content;
  }

  svg {
    display: flex;
    flex: 1;
  }
`

export const OfferTimerContainerWrapper = styled.div<OfferContainerStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.colors.white(500)};
  box-shadow: inset 0 0 0 1px
    ${({ theme, isPrime }) =>
      isPrime ? theme.colors.black(900) : theme.colors.secondary()};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  overflow: hidden;
`

export const OfferIconContainer = styled.div<OfferContainerStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: 100%;

  background: ${({ theme, isPrime, isBlackLegendary }) =>
    isPrime || isBlackLegendary
      ? theme.colors.black(900)
      : theme.colors.secondary()};
  width: ${({ theme }) => theme.rem(56)};
`

export const OfferTimerContainer = styled.div<OfferContainerStyledProps>`
  ${({ theme }) => theme.font(10, 14, 400)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: ${({ theme, isPrime }) =>
    isPrime ? theme.colors.black(800) : 'transparent'};

  color: ${({ theme, isPrime, isBlackLegendary }) =>
    isPrime || isBlackLegendary
      ? theme.colors.white(500)
      : theme.colors.secondary()};

  div {
    span {
      ${({ theme }) => theme.font(14, 14, 700)};
      color: ${({ theme, isPrime, isBlackLegendary }) =>
        isPrime || isBlackLegendary
          ? theme.colors.white(500)
          : theme.colors.secondary()};
    }
  }

  ${({ isBlackLegendary, isPrime }) => {
    if (isBlackLegendary && !isPrime) {
      return css`
         background: ${({ theme }) => theme.colors.black(900)};
         background: linear-gradient(46.73deg, #101010 -22.68%, #1D1D1D 68.08%, #A1A1A1 122.53%);
      `
    }
  }}
`

const ContainerFooter = styled.div`
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.rem(36)};
  width: 100%;

  button {
    border-radius: ${({ theme }) => theme.borderRadius.md};
  }
`

export const ContainerFooterHover = styled(ContainerFooter)`
  display: none;
  height: ${({ theme }) => theme.rem(38)};
`

export const ContainerFooterNoHover = styled(ContainerFooter)`
  display: flex;
  height: ${({ theme }) => theme.rem(38)};
`

export const OutOfStockFooter = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.error(100)};
  border-radius: ${(props) => props.theme.borderRadius.md};
  cursor: pointer;
  text-decoration: none;
  height: ${({ theme }) => theme.rem(38)};

  span {
    ${(props) => props.theme.font(12, 18, 700, 'uppercase')};
    color: ${(props) => props.theme.colors.error(500)};
  }
`

export const UnavailableFooter = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.xxs};
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.black(550)};
  border-radius: ${(props) => props.theme.borderRadius.md};
  cursor: pointer;
  text-decoration: none;
  height: ${({ theme }) => theme.rem(38)};

  span {
    ${(props) => props.theme.font(12, 18, 700, 'uppercase')};
    color: ${(props) => props.theme.colors.white(500)};
  }

  svg * {
    fill: ${(props) => props.theme.colors.white(500)};
  }
`
export const UnavailableFooterOpenBox = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.spacing.xxs};
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.primary(400)};
  border-radius: ${(props) => props.theme.borderRadius.md};
  cursor: pointer;
  text-decoration: none;
  span {
    ${(props) => props.theme.font(12, 18, 700, 'uppercase')};
    color: ${(props) => props.theme.colors.white(500)};
  }

  svg * {
    fill: ${(props) => props.theme.colors.white(500)};
  }
`

export const ButtonOfferComing = styled.div<{ isPrime: boolean }>`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.rem(38)};
  border: 1px solid
    ${({ theme, isPrime }) =>
      isPrime ? theme.colors.black(900) : theme.colors.secondary()};
  color: ${({ theme, isPrime }) =>
    isPrime ? theme.colors.black(900) : theme.colors.secondary()};
  border-radius: ${({ theme }) => theme.spacing.xxxs};
  text-transform: uppercase;
  ${({ theme }) => theme.font(12, 16, 600)}
  cursor: default
`
