export function formatStampsType(
  type: string
): 'legendary' | 'blackLegendary' | 'normal' {
  switch (type) {
    case 'lendario':
      return 'legendary'
    case 'lendario_preto':
      return 'blackLegendary'
    default:
      return 'normal'
  }
}
