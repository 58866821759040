import { StepsEnum } from '../MiniPreCart.types'
import LoadingStep from './LoadingStep'
import ProductStep from './ProductStep'
import ServiceStep from './ServiceStep'
import { StepsProps } from './Steps.types'

function Steps({ isLoading, services, step }: StepsProps) {
  if (isLoading || step === StepsEnum.LOADING) return <LoadingStep />

  if (step === StepsEnum.SERVICE) return <ServiceStep services={services} />

  if (step === StepsEnum.PRODUCT) return <ProductStep />

  return null
}

export default Steps
