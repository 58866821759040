import {
  IconHeaderFavorites,
  IconHeaderSupport,
  IconNotification
} from '@k_frontend/icons'
import { Badge } from '@k_frontend/ui'
import { AccessibilityMenu } from 'components/AccessibilityMenu'
import CartButton from 'components/Header/CartButton/CartButton'
import { useHeaderContext } from 'components/Header/Header.context'
import NavDrawer from 'components/Header/NavDrawer'
import ProfileAvatar from 'components/Header/ProfileAvatar'
import ProfileInfo from 'components/Header/ProfileInfo'
import { AccountMenuDrawer } from 'components/Header/ProfileInfo/AccountMenuDrawer'
import Searchbar from 'components/Header/Searchbar'
import { useNotificationsStore } from 'containers/NotificationsContainer'
import Link from 'next/link'
import { useMemo } from 'react'
import { useHeaderContainer } from './HeaderContainer.hook'
import * as S from './HeaderContainer.styles'

export function HeaderContainer() {
  const {
    isPrime,
    DesktopLogo,
    wishlist,
    isViewFloatingMenu,
    isMobile,
    isHover
  } = useHeaderContainer()
  const { isLoggedIn } = useHeaderContext()
  const { setIsOpenNotifications, isOpenNotifications, noticeQuantity } =
    useNotificationsStore()

  const renderAccessibilityMenu = useMemo(() => {
    if (isViewFloatingMenu) {
      return <AccessibilityMenu />
    }
  }, [isViewFloatingMenu])

  const DropdownHeader = useMemo(() => {
    if (isMobile && isViewFloatingMenu) {
      return (
        <div className={S.HeaderWrapper({ isPrime, isViewFloatingMenu })}>
          <NavDrawer />
          <div className='px-4 w-full'>
            <Searchbar inputSearchId='input-busca-floating' />
          </div>
          <CartButton />
        </div>
      )
    }

    if (!isLoggedIn && isViewFloatingMenu) {
      return (
        <div className={S.HeaderWrapper({ isPrime, isViewFloatingMenu })}>
          <div className='flex items-center justify-center max-w-[1504px] w-full content'>
            <div className='mr-32'>
              <NavDrawer />
            </div>
            <Link
              id='logo-kabum-header-dropdown'
              href='/'
              aria-label='Página inicial KaBuM!'
              className='mr-32 h-[50px]'
            >
              {DesktopLogo}
            </Link>
            <div className='max-w-[740px] w-full mr-32'>
              <Searchbar inputSearchId='input-busca-floating' />
            </div>
            <div className='flex items-center justify-center mr-32'>
              <div className='flex items-center justify-center min-w-[160px] ml-8'>
                <ProfileAvatar />
                <ProfileInfo />
              </div>
              <div className='mr-32'>{renderAccessibilityMenu}</div>
              <Link
                id='linkSacHeader'
                title='SAC'
                href='/faq'
                className='mr-32'
              >
                <IconHeaderSupport size={24} />
              </Link>
              <a
                id='linkFavoritosHeaderDropdown'
                title='Favoritos'
                href='/minha-conta/favoritos'
                className='mr-32'
              >
                <Badge
                  content={!!wishlist && wishlist.total_wishes}
                  position='topRight'
                >
                  <IconHeaderFavorites size={20} />
                </Badge>
              </a>
              <CartButton />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className={S.HeaderWrapper({ isPrime, isViewFloatingMenu })}>
        <div className='flex items-center justify-center max-w-[1504px] w-full content'>
          <div className='mr-32'>
            <NavDrawer />
          </div>
          <Link
            id='logo-kabum-header-dropdown'
            href='/'
            aria-label='Página inicial KaBuM!'
            className='mr-32 h-[50px]'
          >
            {DesktopLogo}
          </Link>
          <div className='max-w-[740px] w-full mr-32'>
            <Searchbar inputSearchId='input-busca-floating' />
          </div>
          <div className='flex items-center justify-center mr-32'>
            <ProfileAvatar />
            <nav className='relative'>
              <div className='flex items-center ml-8'>
                <AccountMenuDrawer />
              </div>
            </nav>
            <div className='ml-32'>{renderAccessibilityMenu}</div>
            <Link id='linkSacHeader' title='SAC' href='/faq' className='ml-32'>
              <IconHeaderSupport size={24} />
            </Link>
            <a
              id='linkFavoritosHeaderDropdown'
              title='Favoritos'
              href='/minha-conta/favoritos'
              className='ml-32'
            >
              <Badge
                content={!!wishlist && wishlist.total_wishes}
                position='topRight'
              >
                <IconHeaderFavorites size={20} />
              </Badge>
            </a>
            <div
              className='flex items-center justify-center w-[36px] h-[36px] ml-32'
              onClick={() => setIsOpenNotifications(!isOpenNotifications)}
              onKeyUp={() => setIsOpenNotifications(!isOpenNotifications)}
              data-testid='icon-notification'
            >
              <div className='relative cursor-pointer'>
                <IconNotification width={24} height={24} color='#ffffffcc' />
                {noticeQuantity !== '0' && (
                  <span className='flex items-center font-bold absolute h-20 px-6 bg-secondary-600 rounded-full -top-[10px] left-12 text-xs text-black-300'>
                    {noticeQuantity}
                  </span>
                )}
              </div>
            </div>
            <div className='ml-24'>
              <CartButton />
            </div>
          </div>
        </div>
      </div>
    )
  }, [isMobile, isHover, isViewFloatingMenu, isLoggedIn])

  return DropdownHeader
}
