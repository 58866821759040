import { Separator } from '@k_frontend/ui'
import * as Dialog from '@radix-ui/react-dialog'
import CrossCart from 'components/CrossCart'
import { Ellipsis } from 'components/Typograph'
import {
  ProductService,
  ProductServiceState,
  useProductService
} from 'hooks/useProductService'

const useProductServiceSelector = (state: ProductServiceState) => ({
  baseProductData: state.baseProductData,
  selectedServices: state.selectedServices
})

function ProductStep() {
  const { selectedServices, baseProductData } = useProductService(
    useProductServiceSelector
  )

  const listOfSelectedServices = Object.values(selectedServices).filter(
    (services) => services.code > 0
  )
  const hasSelectedServices = listOfSelectedServices.length > 0

  function getServiceDescription(service: ProductService) {
    switch (service.name) {
      case 'seguro':
        return `${service.description} de Garantia Estendida`
      case 'garantia':
        return `${service.description} de Seguro de Roubo e Furto + Quebra Acidental`
      default:
        return service.name
    }
  }

  return (
    <>
      <Dialog.Title className='flex gap-12 py-8 items-center px-16'>
        <span className='text-success-500 text-xs font-semibold uppercase'>
          Produto adicionado ao carrinho
        </span>
      </Dialog.Title>

      <div className='flex gap-6 items-center px-16 max-w-full mb-8'>
        <div className='min-h-56 max-h-56 min-w-56 max-w-56'>
          <img src={baseProductData.image} className='w-full' alt='' />
        </div>
        <p>
          <Ellipsis
            lines={2}
            lineHeight={20}
            className='text-sm text-black-800 font-semibold'
          >
            {baseProductData.name}
          </Ellipsis>
        </p>
      </div>

      {hasSelectedServices && (
        <div className='px-16 mb-8'>
          <h3 className='text-xs text-black-800 font-semibold uppercase'>
            Serviços adicionados
          </h3>
          <ul className='my-4'>
            {listOfSelectedServices.map((service) => (
              <li className='text-xxs text-black-800' key={service.code}>
                {getServiceDescription(service)}
              </li>
            ))}
          </ul>
          <Separator />
        </div>
      )}

      <div className='pl-16 max-w-[100vw]'>
        <CrossCart
          productsId={baseProductData.crossCart}
          cookieIsMobile='true'
          isMiniCrossCart
        />
      </div>
    </>
  )
}

export default ProductStep
