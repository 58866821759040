import { IconCloseModal } from '@k_frontend/icons'
import * as Dialog from '@radix-ui/react-dialog'
import { useMiniPreCart } from 'hooks/useMiniPreCart/useMiniPreCart'
import { useProductService } from 'hooks/useProductService'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { getProductWarranty } from 'services/warranty/products'
import { StepState, StepsEnum } from './MiniPreCart.types'
import Navigation from './Navigation'
import Steps from './Steps'

function MiniPreCart() {
  const { isOpen, toggleIsOpen } = useMiniPreCart()
  const {
    baseProductData,
    setAvailableServices,
    setSelectedServiceOption,
    setSelectedServicesPrice,
    clearSelectedServices
  } = useProductService()

  const [step, setStep] = useState<StepState>(StepsEnum.LOADING)

  const warrantyData = useMemo(
    () => ({
      produtos: [
        {
          produto_codigo: baseProductData.productCode,
          mercadoria_codigo: 0
        }
      ]
    }),
    [baseProductData.productCode]
  )

  const {
    data: services,
    isLoading,
    isFetched
  } = useQuery({
    queryKey: ['preCart', warrantyData],
    queryFn: () => getProductWarranty(warrantyData),
    enabled: baseProductData.sellerId === 0 && isOpen,
    onSuccess: (services) => {
      setAvailableServices(services.map((service) => service.code))

      if (services.length > 0) {
        services.forEach((service) => {
          const defaultOption = service.options[0]
          setSelectedServiceOption(
            service.code,
            defaultOption.code,
            defaultOption.description
          )
          setSelectedServicesPrice(
            service.code,
            defaultOption.parceledPrice,
            defaultOption.price
          )
        })
        setStep(StepsEnum.SERVICE)
      } else {
        setStep(StepsEnum.PRODUCT)
      }
    },
    onError: () => {
      setStep(StepsEnum.PRODUCT)
    }
  })

  useEffect(() => {
    if (!isFetched && baseProductData.sellerId !== 0 && isOpen) {
      setStep(StepsEnum.PRODUCT)
    } else {
      setStep(StepsEnum.LOADING)
    }
  }, [isOpen])

  if (!isOpen) return null

  return (
    <Dialog.Root open={isOpen} onOpenChange={toggleIsOpen}>
      <Dialog.Overlay
        onClick={clearSelectedServices}
        className='fixed inset-0 z-[9999] bg-black-950/60'
      />
      <Dialog.Content className='fixed inset-x-0 bottom-0 max-h-[80vh]  bg-white-500 z-[9999] py-16 grid grid-rows-[auto_auto_1fr_auto]'>
        <Dialog.Close asChild>
          <button
            type='button'
            className='size-32 bg-transparent absolute -top-32 z-[9999] right-16'
            aria-label='Fechar'
          >
            <IconCloseModal color='white' height={20} width={20} aria-hidden />
          </button>
        </Dialog.Close>

        <Steps isLoading={isLoading} services={services} step={step} />

        <Navigation step={step} setStep={setStep} />
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default MiniPreCart
