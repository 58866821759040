import { tv } from 'tailwind-variants'

export const productStyle = tv({
  slots: {
    wrapper:
      'flex items-center gap-6 border-0 border-t border-solid border-black-500 py-8',
    imageWrapper: 'max-h-[60px] min-h-[60px] min-w-[60px] max-w-[60px]',
    manufacturer: 'text-xs font-normal text-black-600'
  },
  variants: {
    assembly: {
      true: {
        wrapper: 'border-none',
        imageWrapper: 'max-h-56 min-h-56 min-w-56 max-w-56',
        manufacturer: 'text-xxs'
      }
    },
    hasAssembly: {
      true: {
        manufacturer: 'hidden'
      }
    }
  }
})
