import { onImageError } from '@k_frontend/core'
import { useMemo } from 'react'
import { NOTIFICATION_BANNER, NOTIFICATION_ICON } from 'settings/constants'
import { STATIC_KABUM_IMAGES_K5 } from 'settings/kernel'
import { useNotificationCard } from './NotificationCard.hook'
import * as S from './NotificationCard.styles'
import type { NotificationCardProps } from './NotificationCard.types'

export function NotificationCard({ notification }: NotificationCardProps) {
  const { description, onClickNotificationCard } = useNotificationCard({
    notification
  })

  const renderedIcon = useMemo(() => {
    if (
      !NOTIFICATION_ICON[notification?.notificationAdditionalData?.imageTitleId]
    )
      return null
    const Icon =
      NOTIFICATION_ICON[notification?.notificationAdditionalData?.imageTitleId]
        ?.Icon
    return (
      <Icon className={S.titleSvg({ isRead: Boolean(notification?.isRead) })} />
    )
  }, [notification])

  const renderedBanner = useMemo(() => {
    if (!NOTIFICATION_BANNER[notification?.notificationType]) return null
    const Icon = NOTIFICATION_BANNER[notification?.notificationType]?.Icon
    return <Icon className={S.banner()} />
  }, [notification])

  const renderPackageDescription = useMemo(() => {
    if (notification?.notificationAdditionalData?.packageDescription)
      return (
        <p className='text-xxs font-semibold text-black-700'>
          {notification?.notificationAdditionalData?.packageDescription}
        </p>
      )
    return null
  }, [notification?.notificationAdditionalData?.packageDescription])

  return (
    <div
      className={S.cardContainer()}
      onClick={onClickNotificationCard}
      onKeyDown={onClickNotificationCard}
      data-testid='notification-card'
    >
      <img
        src={notification.imageUrl}
        alt={'imagem do produto'}
        className={S.image()}
        onError={(e) => onImageError(e, STATIC_KABUM_IMAGES_K5, 'gridCard')}
      />
      <div className={S.mainContainer()}>
        <div className={S.contentWrapper()}>
          {renderedBanner}
          <div className={S.row()}>
            {renderedIcon}
            <span
              className={S.title({ isRead: Boolean(notification?.isRead) })}
            >
              {notification.title}
            </span>
          </div>
          {renderPackageDescription}
          <p
            className={S.description({ isRead: Boolean(notification?.isRead) })}
            dangerouslySetInnerHTML={description}
          ></p>
        </div>
        <div className={S.actionsWrapper()}>
          <span className={S.date()}>{notification.createdAt}</span>
          {!notification?.isRead && <div className={S.alertCircle()} />}
        </div>
      </div>
    </div>
  )
}
