import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(isSameOrAfter)

export const isTimeAfter = (current: number, limit: number): boolean => {
  return dayjs(current * 1000).isSameOrAfter(limit * 1000)
}

export const getRemainingTime = (end: number, start: number): number => {
  const endTime = new Date(end * 1000).getTime()
  const startTime = new Date(start * 1000).getTime()

  return (endTime - startTime) / 1000
}

export const getOfferLabel = (current: number, limit: number): string => {
  const term = isTimeAfter(current, limit) ? 'TERMINA' : 'COMEÇA'

  return `${term} EM:`
}
