import { useDispatch } from 'react-redux'

import { usePrices, useUser } from '@k_frontend/core'
import {
  IconAddToCart,
  IconTrackingCart as IconAddedInCart
} from '@k_frontend/icons'
import { useCart } from 'hooks/useCart'
import { CartState, ProductCookie } from 'hooks/useCart/types'
import theme from 'theme'
import handleAddToCartWithoutRedirect from 'utils/addRemoveToCart/handleAddToCartWithoutRedirect'
import handleRemoveCartDataLayer from 'utils/addRemoveToCart/handleRemoveCartDataLayer'
import { formatSellerId } from 'utils/formatSellerId'
import { shallow } from 'zustand/shallow'

import { useMiniCart } from 'hooks/useMiniCart/useMiniCart'
import React from 'react'
import * as S from './ButtonAddRemoveToCart.styles'
import { ButtonAddRemoveToCartProps } from './ButtonAddRemoveToCart.types'

const useCartSelector = (state: CartState) => ({
  productsInCart: state.productsInCart,
  removeProduct: state.removeProduct
})

function findProductByCodeAndSeller(
  productsInCart: ProductCookie[],
  productId: string,
  sellerId: string
): boolean {
  return productsInCart.some(
    (productInCart) =>
      productInCart.code === productId && productInCart.seller_id === sellerId
  )
}

export default function ButtonAddRemoveToCart({
  className = '',
  productData,
  minimalist = false,
  disabled = false,
  parentComponent
}: ButtonAddRemoveToCartProps) {
  const dispatch = useDispatch()
  const { productsInCart, removeProduct } = useCart(useCartSelector, shallow)
  const { setTriggerPosition } = useMiniCart()
  const isPrime = useUser((state) => state.isPrime)

  const sellerId = formatSellerId(productData.sellerId)

  const isInCart = findProductByCodeAndSeller(
    productsInCart,
    productData.code.toString(),
    sellerId
  )

  const buttonCardLabel =
    (!isInCart ? 'Adicionar no' : 'Remover do') + ' carrinho'

  let isFullPrime = false

  if ('priceDetails' in productData) {
    isFullPrime = isPrime && productData?.priceDetails?.isPrime
  }

  const { offer } = usePrices(
    (state) =>
      state.selectorPricesDetails({ state, productId: productData.code }),
    shallow
  )

  function verifyProductOffer() {
    if (productData.offer) return productData
    return { ...productData, offer }
  }

  function handleRemove() {
    removeProduct({
      code: productData.code.toString(),
      sellerId,
      originId: '1',
      offerId: productData.offer?.id?.toString() || offer?.id?.toString() || '',
      tagCode: productData.tagCode.toString() || ''
    })
    handleRemoveCartDataLayer({ productData })
  }

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (isInCart) {
      handleRemove()
    } else {
      setTriggerPosition(e.currentTarget.getBoundingClientRect())
      dispatch({
        type: 'ADD_PRODUCT_TO_CART',
        products: verifyProductOffer(),
        addType: 'direct',
        isWithoutRedirect: true,
        parentComponent,
        handleAddToCartWithoutRedirect
      })
    }
  }

  return (
    <S.ButtonHeaderCard
      className={className}
      onClick={handleClick}
      isInCart={isInCart}
      minimalist={minimalist}
      disabled={disabled}
      isFullPrime={isFullPrime}
      aria-pressed={isInCart}
      aria-label={buttonCardLabel}
    >
      {isInCart ? (
        <IconAddedInCart
          width={theme.rem(minimalist ? 24 : 25)}
          height={theme.rem(24)}
        />
      ) : (
        <IconAddToCart />
      )}
    </S.ButtonHeaderCard>
  )
}
