import axios from 'axios'
import {
  ProductWarrantyServiceModel,
  productWarrantyModel
} from 'models/warranty/products'
import { BACKEND_WARRANTY_SERVICES } from 'settings/kernel'

import {
  ProductWarrantyPayload,
  ProductWarrantyResponse
} from './products.types'

export async function getProductWarranty(
  products: ProductWarrantyPayload
): Promise<ProductWarrantyServiceModel[]> {
  try {
    const { data } = await axios.post<ProductWarrantyResponse[]>(
      BACKEND_WARRANTY_SERVICES,
      products
    )
    return productWarrantyModel(data)
  } catch (error) {
    console.error('PRE CART SERVICES', error)
    return []
  }
}
