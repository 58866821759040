import { LoadFeatureFlagUseCase } from 'data/usecases/loadFeatureFlag'
import { LoadFeatureFlagUseCaseDomain } from 'domain/usecases/loadFeatureFlag'
import { makeLocalFeatureFlagGateway } from 'factories/gateways/makeLocalFeatureFlagGateway'
import { makeRemoteFeatureFlagGateway } from 'factories/gateways/makeRemoteFeatureFlagGateway'

export async function makeLoadFeatureFlagUseCase(): Promise<LoadFeatureFlagUseCaseDomain> {
  return new LoadFeatureFlagUseCase(
    makeLocalFeatureFlagGateway(),
    await makeRemoteFeatureFlagGateway()
  )
}
