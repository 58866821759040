import * as S from './RatingStars.styles'
import * as T from './RatingStars.types'

export default function RatingStars({
  maxRating,
  rating,
  starSize = 14,
  starGap = 2
}: T.RatingStarsProps) {
  if (rating > maxRating) rating = maxRating

  const ratingToScreenReaders = `Classificação: ${
    maxRating === 1 ? `1 estrela` : `${rating} de ${maxRating} estrelas`
  }.`

  function createReviewStars() {
    const maxStars = maxRating * 2
    const stars: string[] = Array(maxStars)
      .fill('full', 0, Math.ceil(maxStars / 2))
      .fill('empty', Math.ceil(maxStars / 2), maxStars)
    const fullStars = rating - (rating % 1) / 1
    const halfStars = Math.ceil(rating % 1)
    const ratingStars = stars.slice(
      Math.ceil(maxStars / 2) - fullStars,
      maxStars - fullStars
    )

    if (halfStars) {
      ratingStars.splice(0 + fullStars, 1, 'half')
    }

    return ratingStars.map((star, index) => (
      <use
        x={`${index * (starSize + starGap)}`}
        key={index}
        width={starSize}
        height={starSize}
        aria-hidden="true"
        fill="#fff"
        href={`/static/icons/ratingStars.svg#${star}`}
      />
    ))
  }

  return (
    <S.RatingStarsContainer
      aria-label={ratingToScreenReaders}
      data-testid="ratingStars"
      starGap={starGap}
      className="ratingStarsContainer"
    >
      <svg
        width={maxRating * (starSize + starGap)}
        height={starSize}
        viewBox={`0 0 ${maxRating * (starSize + starGap)} ${starSize}`}
        fill="none"
        xmlns="https://www.w3.org/2000/svg"
        style={{ display: 'flex', gap: '16px' }}
      >
        {createReviewStars()}
      </svg>
    </S.RatingStarsContainer>
  )
}
