const LoggerException = require('../utils/logger/exception')
module.exports = class CustomException extends LoggerException {
  constructor({ level, dd, msg, content, errorType, http }) {
    super({ level, dd, msg, content, errorType, http })
  }

  setRedirect(redirectUrl) {
    this.hasRedirect = true
    this.redirectUrl = redirectUrl
  }

  getRedirect() {
    return this.redirectUrl
  }
}
