export type ContainerTagsStyledProps = {
  showTags: boolean
  showFilterTypes: boolean
}

export type PageOfertaProps = {
  offerName: string
  cookieIsMobile: boolean
}

export enum OFFER_TYPE {
  CLOSED = 'encerradas',
  SCHEDULED = 'agendadas',
  ACTIVE = 'ativas'
}
