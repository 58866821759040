import { CartDTO } from 'domain/dto/cartDTO.types'

import { CartCoupon } from './cartCoupon'
import { CartProduct } from './cartProduct'
import { CartSeller } from './cartSeller'

export class Cart {
  blackfriday: boolean
  couponLimit: number
  coupon: CartCoupon[]
  sellers: CartSeller[]
  totals: CartDTO.Totals

  constructor(cart: CartDTO.Props) {
    this.blackfriday = cart.blackfriday
    this.couponLimit = cart.couponLimit
    this.coupon = cart.coupon
    this.sellers = this.filterPcBuilderProducts(cart.sellers)
    this.totals = cart.totals
  }

  private filterPcBuilderProducts(sellers: CartSeller[]): CartSeller[] {
    const pcBuilderAssemblies = this.mapPcBuilderAssemblies(sellers)

    if (Object.keys(pcBuilderAssemblies).length === 0) return sellers

    return sellers.map((seller) => ({
      ...seller,
      origins: seller.origins.map((origin) => ({
        ...origin,
        products: origin.products
          .filter((product) => !product.pcBuildId)
          .map((product) => {
            if (pcBuilderAssemblies[product.code])
              product.setPcBuildAssemblyProducts(
                pcBuilderAssemblies[product.code]
              )
            return product
          })
      }))
    }))
  }

  private mapPcBuilderAssemblies(
    sellers: CartSeller[]
  ): Record<string, CartProduct[]> {
    const pcBuilderAssemblies: any = {}

    sellers.forEach((seller) =>
      seller.origins.forEach((origin) =>
        origin.products.forEach((product) => {
          if (product.pcBuildId) {
            if (pcBuilderAssemblies[product.pcBuildId]) {
              pcBuilderAssemblies[product.pcBuildId].push(product)
            } else {
              pcBuilderAssemblies[product.pcBuildId] = [product]
            }
          }
        })
      )
    )

    return pcBuilderAssemblies
  }

  stringifySellers(): string {
    const stringfiedSellers: string[] = []

    this.sellers.forEach((seller) => {
      const sellerId = seller.id || '0'
      seller.origins.forEach((origin) => {
        const selectedShipping = origin.shipping.find(
          (option) => option.selected
        )
        const originId = origin.id || '0'
        const shippingOption = selectedShipping?.id || 0
        const shippingDate = selectedShipping?.shippingDate || ''
        const shippingPeriod = selectedShipping?.shippingPeriod || ''
        const shippingName = selectedShipping?.name || ''

        origin.products.forEach((product) => {
          const id = product.id || '0'
          const productCode = product.code || 0
          const offerId = product.offerId || ''
          const tagCode = product.tagCode || ''
          const quantity = product.quantity || 1

          stringfiedSellers.push(
            `${sellerId}|${originId}|${shippingOption}|${shippingDate}|${shippingPeriod}|${shippingName}|${id}|${productCode}|${quantity}|${offerId}|${tagCode}`
          )
        })
      })
    })

    return stringfiedSellers.join('#')
  }

  stringifyServices(): string {
    const stringfiedServices: string[] = []

    this.sellers.forEach((seller) => {
      const sellerId = seller.id || '0'

      seller.origins.forEach((origin) => {
        const originId = origin.id || '0'

        origin.products.forEach((product) => {
          const productCode = product.code || 0

          product.services.forEach((service) => {
            const stringifiedService = service.stringifySelectedOption(
              sellerId,
              originId,
              productCode
            )

            if (stringifiedService) stringfiedServices.push(stringifiedService)
          })
        })
      })
    })

    return stringfiedServices.join('#')
  }

  stringifyValidCoupons(): string {
    return this.coupon
      .filter((coupon) => coupon.valid)
      .map((coupon) => coupon.id)
      .join(',')
  }
}
