import * as S from './styles'
import { EllipsisProps as Props } from './types'

function Ellipsis({
  children,
  lines = 2,
  className,
  heightByContent = false,
  lineHeight = 18
}: Props) {
  return (
    <S.Ellipsis
      heightByContent={heightByContent}
      height={lines * lineHeight}
      lines={lines}
      className={className}
    >
      {children}
    </S.Ellipsis>
  )
}

export { Ellipsis }
