import CookiesInterface from 'interfaces/cookies'

export function getCostumer() {
  const cookiesInterface = new CookiesInterface()

  const id_cliente = cookiesInterface.getClientId()
  const session = cookiesInterface.getSession()

  if (id_cliente) {
    return id_cliente
  }

  return session
}
