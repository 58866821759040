import { getValueOrDefault } from '@k_frontend/core'
import { CrossCartProductResponse } from 'services/crossCart/crossCart.types'
import { formatProductFriendlyName } from 'utils/friendlyName'

import { CrossCartProductModel } from './crossCart.types'

function crossCartModel(
  products: CrossCartProductResponse[]
): CrossCartProductModel[] {
  const getOldPrice = (product: CrossCartProductResponse) => {
    const { attributes } = product
    const { prime, offer, price } = attributes

    if (!!prime || !!offer) {
      return price
    }

    return attributes.old_price
  }

  return products.map((product, index) => {
    const { attributes } = product

    return {
      available: true,
      code: product.id,
      name: attributes.title,
      description: attributes.description,
      category: attributes.menu,
      manufacturer: attributes.manufacturer,
      image: attributes?.images.length ? attributes?.images[0] : '',
      price: attributes.price,
      primePrice: attributes.prime?.price || 0,
      friendlyName: formatProductFriendlyName(attributes.title),
      primePriceWithDiscount: attributes.prime?.price_with_discount || 0,
      discountPercentagePrime: attributes.prime?.discount_percentage || 0,
      oldPrice: getOldPrice(product),
      oldPrimePrice: getOldPrice(product),
      maxInstallment: attributes?.max_installment || '',
      priceWithDiscount: attributes.price_with_discount || 0,
      offerDiscount: attributes.offer?.discount_percentage || 0,
      quantity: attributes.offer?.quantity_available || 0,
      rating: attributes.score_of_ratings || 0,
      ratingCount: attributes.number_of_ratings,
      ufsFlash: attributes.ufs_flash,
      flags: {
        isOpenbox: !!attributes.is_openbox,
        isFreeShipping: !!attributes.has_free_shipping,
        isFreeShippingPrime: !!attributes.has_free_shipping_for_prime_user,
        isPixShipping: !!attributes.has_pix_shipping,
        isPreOrder: !!attributes.is_pre_order,
        isFlash: !!attributes?.ufs_flash?.length,
        isPrime: !!attributes.prime,
        isOffer: !!attributes.offer
      },
      prime: attributes.prime && {
        price: attributes.prime.price,
        priceWithDiscount: attributes.prime.price_with_discount,
        discountPercentage: attributes.prime.discount_percentage,
        save: attributes.prime.save,
        isLoggedUserExclusive: attributes.prime.is_logged_user_exclusive,
        maxInstallmentPrime: attributes?.max_installment_prime || ''
      },
      offer: attributes.offer && {
        id: attributes.offer.id,
        name: attributes.offer.name,
        referenceBanner: attributes.offer.reference_banner,
        endsAt: attributes.offer.ends_at,
        startsAt: attributes.offer?.starts_at || 0,
        isLoggedUserExclusive: attributes.offer.is_logged_user_exclusive,
        price: attributes.offer.price,
        priceWithDiscount: attributes.offer.price_with_discount,
        discountPercentage: attributes.offer.discount_percentage,
        quantityAvailable: attributes.offer.quantity_available,
        hashCode: attributes.offer.hash_code
      },
      sellerName: getValueOrDefault<string>(
        attributes.marketplace?.seller_name,
        'KaBuM!'
      ),
      sellerId: getValueOrDefault<number>(attributes.marketplace?.seller_id, 0),
      offerIdMarketplace: getValueOrDefault<number>(
        attributes.marketplace?.product_id,
        0
      ),
      position: index + 1,
      list: 'quem-comprou-comprou-tambem'
    }
  })
}

export default crossCartModel
