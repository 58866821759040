import { IconTruck } from '@k_frontend/icons'
import { tailwindTheme } from 'theme/tailwindTheme'

export function FreeShippingTag() {
  return (
    <div className='h-16 desktop:h-20 bg-success-500 text-white-500 text-xxs/3 tablet:text-xxs font-medium flex items-center gap-4 rounded-full px-6 py-2 w-max'>
      <IconTruck
        width={10}
        height={10}
        color={tailwindTheme.colors.white[500]}
      />
      Frete grátis*
    </div>
  )
}
