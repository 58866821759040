import { createWrapper } from 'next-redux-wrapper'

import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducers from 'state/ducks'
import sagas from 'state/sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    dataLayer: Record<string, unknown>[]
  }
}

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

function makeStore() {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    reducers,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  )
  sagaMiddleware.run(sagas)

  return store
}

export default createWrapper(makeStore)
