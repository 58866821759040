import { create } from 'zustand'
import { UseNotificationsContainerStoreProps } from './NotificationsContainer.types'

export const useNotificationsStore =
  create<UseNotificationsContainerStoreProps>((set) => ({
    isOpenNotifications: false,
    setIsOpenNotifications: (isOpenNotifications) =>
      set({ isOpenNotifications }),
    noticeQuantity: '0',
    setNoticeQuantity: (noticeQuantity) => set({ noticeQuantity })
  }))
