import { AnchorHTMLAttributes } from 'react'

import styled from 'styled-components'

export const RowLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    justify-content: flex-end;
  }
`

interface TextLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  marginRight?: number
  marginTop?: number
  mainColor?: string
}

export const TextLink = styled.a<TextLinkProps>`
  ${({ theme }) => theme.font(12, 16, 700)};

  text-transform: uppercase;
  text-decoration: none;
  margin-top: ${({ theme, marginTop }) => theme.rem(marginTop ?? 10)};
  color: ${(props) => props.mainColor ?? props.theme.colors.secondary(500)};
  cursor: pointer;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin-left: 10px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${({ theme }) => theme.font(14, 24, 700)};
  }
`
