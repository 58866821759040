import { CartOriginDTO } from 'domain/dto/cartOriginDTO.types'

import { CartProduct } from './cartProduct'
import { CartShipping } from './cartShipping'

export class CartOrigin {
  id: string
  products: CartProduct[]
  shipping: CartShipping[]

  constructor(origin: CartOriginDTO.Props) {
    this.id = origin.id
    this.products = origin.products
    this.shipping = origin.shipping
  }
}
