import { currency } from 'utils/functions'
import { SecondaryPriceProps } from './SecondaryPriceInfo.types'

import * as S from './SecondaryPriceInfo.styles'

export function SecondaryPriceInfo({
  installmentPrice,
  isScheduledOffer
}: SecondaryPriceProps) {
  if (installmentPrice) {
    return (
      <div className='priceTextCard flex flex-col items-start text-xs h-12 text-black-600 tablet:h-[28px] desktop:h-32'>
        À vista no PIX
        <span className='flex gap-4 text-xs text-black-600 desktop:leading-4'>
          ou até{' '}
          <b className={S.installmentPrice({ isScheduledOffer })}>
            {installmentPrice}
          </b>
        </span>
      </div>
    )
  }

  return null
}
