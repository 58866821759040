import { Storage } from '@k_frontend/core'
import { LocalServicesGatewayProtocol } from 'data/protocols/localServicesGatewayProtocol'
import { KABUM_COOKIE_URL } from 'settings/kernel'
export class LocalServicesGateway implements LocalServicesGatewayProtocol {
  private readonly storageClient: Storage

  constructor(storageClient: Storage) {
    this.storageClient = storageClient
  }

  parse() {
    const stringfiedServices: string = this.storageClient.get({
      name: 'services'
    })

    if (!stringfiedServices) {
      return []
    }

    return stringfiedServices.split('#').map((service) => {
      const [sellerId, originId, productCode, serviceCode, serviceOptionCode] =
        service.split('|')

      return {
        sellerId,
        originId,
        productCode: parseInt(productCode),
        serviceCode,
        serviceOptionCode: parseInt(serviceOptionCode)
      }
    })
  }

  update(stringfiedServices: string): void {
    this.storageClient.set({
      name: 'services',
      value: stringfiedServices,
      options: {
        domain: KABUM_COOKIE_URL
      }
    })
  }
}
