import { UpdateOfferModel } from 'domain/models'
import { OfferModel } from 'models/ofertas'
import { clearObject } from 'utils/functions'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { FilterPressEnum, OfferState } from './useOffer.types'

function removeOfferList(offerCode: number) {
  const offer = useOffer.getState().offer
  const offersFiltered = offer.data.filter(
    (offer) => offer.offerCode !== offerCode
  )
  useOffer.setState((state) => ({
    ...state,
    offer: { ...offer, data: offersFiltered }
  }))
}

function clearFilters(campaign: string, limit: number = 20) {
  useOffer.setState((state) => ({
    ...state,
    params: {
      campaign,
      page: 1,
      limit
    }
  }))
}

const useOffer = create<OfferState>()(
  devtools(
    (set) => ({
      filterPressed: FilterPressEnum.DEFAULT,
      loading: false,
      offer: undefined,
      params: undefined,
      stockUpdate: undefined,
      showingRemainingFilters: undefined,
      init: (initialState) => set((state) => ({ ...state, ...initialState })),
      setLoading: (loading: boolean) => set((state) => ({ ...state, loading })),
      setOffer: (offer: OfferModel) => set((state) => ({ ...state, offer })),
      setStockUpdate: (stockUpdate: UpdateOfferModel.Model) =>
        set((state) => ({ ...state, stockUpdate })),
      removeOfferList: (offerCode: number) => removeOfferList(offerCode),
      setShowingRemainingFilters: (showingRemainingFilters) =>
        set((state) => ({ ...state, showingRemainingFilters })),
      clearFilters: (campaign: string, limit: number = 20) =>
        clearFilters(campaign, limit),
      setParams: (
        params,
        filterPressed: string = FilterPressEnum.DEFAULT,
        page = 1
      ) =>
        set((state) => ({
          ...state,
          params: clearObject({ ...params, page }),
          filterPressed
        }))
    }),
    { name: 'useOffer' }
  )
)
export { useOffer }
