import produce from 'immer'
import { create } from 'zustand'
import { NamedSet, devtools, persist } from 'zustand/middleware'

import {
  INITIAL_STATE,
  TAKEAWAY_PERSISTED_VALUES,
  TAKEAWAY_STORAGE_NAME
} from './useTakeAwayShipping.constants'
import { TakeAwayShippingState } from './useTakeAwayShipping.types'

function getStorePoints() {
  const { orderBy, storePoints } = useTakeAwayShipping.getState()
  return storePoints[orderBy]
}

function reset(set: NamedSet<TakeAwayShippingState>) {
  set((state) => ({ ...state, ...INITIAL_STATE }))
}

const useTakeAwayShipping = create<TakeAwayShippingState>()(
  devtools(
    persist(
      (set) => ({
        ...INITIAL_STATE,
        getStorePoints,
        page: 0,
        setPage: (page: number) => {
          set(
            produce((state: TakeAwayShippingState) => {
              state.page = page
            })
          )
        },
        isLoading: false,
        setIsLoading: (isLoading) => {
          set(
            produce((state: TakeAwayShippingState) => {
              state.isLoading = isLoading
            })
          )
        },
        setHasError: (hasError) => {
          set(
            produce((state: TakeAwayShippingState) => {
              state.hasError = hasError
            })
          )
        },
        hasError: false,
        setButtonAbleToUse: (ableToUse) => {
          set(
            produce((state: any) => {
              state.buttonAbleToUse = ableToUse
            })
          )
        },
        setOrderBy: (orderBy) => {
          set(
            produce((state) => {
              state.orderBy = orderBy
            })
          )
        },
        setStorePointsShorterDistance: (storePoints) => {
          set(
            produce((state: TakeAwayShippingState) => {
              state.storePoints.shorterDistance = storePoints
            })
          )
        },
        setStorePointsShorterTerm: (storePoints) => {
          set(
            produce((state: TakeAwayShippingState) => {
              state.storePoints.shorterTerm = storePoints
            })
          )
        },
        setStorePointToPickUp: (pointToPickUp) => {
          set((state) => ({ ...state, pointToPickUp }))
        },
        setTakeBySomeoneCPF: (takeBySomeoneCPF: string) => {
          set(
            produce((state) => {
              state.takeBySomeoneCPF = takeBySomeoneCPF
            })
          )
        },
        setTakeBySomeoneName: (takeBySomeoneName: string) => {
          set(
            produce((state) => {
              state.takeBySomeoneName = takeBySomeoneName
            })
          )
        },
        setErrorsForm: (error) => {
          set(
            produce((state) => {
              state.errorsForm = {
                ...state.errorsForm,
                ...error
              }
            })
          )
        },
        setIsTakeAwayBySomeoneElse: (isTakeAwayBySomeoneElse: boolean) =>
          set((state) => ({ ...state, isTakeAwayBySomeoneElse })),
        setSearchBy: (searchBy: string) =>
          set((state) => ({ ...state, searchBy })),
        reset: () => reset(set)
      }),
      {
        name: TAKEAWAY_STORAGE_NAME,
        partialize: (state) =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) =>
              TAKEAWAY_PERSISTED_VALUES.includes(key)
            )
          )
      }
    ),
    {
      name: 'useTakeAwayShipping'
    }
  )
)

export { useTakeAwayShipping }
