import { SellerDTO } from 'domain/dto/cartSellerDTO.types'

import { CartOrigin } from './cartOrigin'

export class CartSeller {
  id: string
  name: string
  origins: CartOrigin[]

  constructor(seller: SellerDTO.Props) {
    this.id = seller.id
    this.name = seller.name
    this.origins = seller.origins
  }
}
