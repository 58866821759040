import { UpdateOfferModel } from 'domain/models'
import { OfferModel } from 'models/ofertas'
import { GetOffersParams } from 'services/ofertas'

export type OfferStateParams = {
  campaign: GetOffersParams['campaign']
  page: GetOffersParams['page']
  limit: GetOffersParams['limit']
  brands?: string[]
  order?: GetOffersParams['order']
  evaluation?: GetOffersParams['evaluation']
  minDiscount?: GetOffersParams['minDiscount']
  maxDiscount?: GetOffersParams['maxDiscount']
  minPrice?: GetOffersParams['minPrice']
  maxPrice?: GetOffersParams['maxPrice']
  dep?: GetOffersParams['dep']
  soldByKabum?: GetOffersParams['soldByKabum']
  sec?: GetOffersParams['sec']
  string?: GetOffersParams['string']
  type?: GetOffersParams['type']
  stamps?: GetOffersParams['stamps']
  freeShipping?: GetOffersParams['freeShipping']
  flashShipping?: GetOffersParams['flashShipping']
  isUserPrime?: boolean
}

type InitialState = {
  offer?: OfferModel
  params: OfferStateParams
}

export enum FilterPressEnum {
  DEFAULT = 'default',
  PAGINATION = 'pagination',
  BACK = 'back'
}

export type OfferState = {
  filterPressed: string
  loading: boolean
  offer: OfferModel
  showingRemainingFilters: Record<string, boolean>
  params: OfferStateParams
  stockUpdate: UpdateOfferModel.Model
  init: (initialState: InitialState) => void
  setLoading: (loading: boolean) => void
  setStockUpdate: (stockUpdate: UpdateOfferModel.Model) => void
  removeOfferList: (offerCode: number) => void
  setParams: (
    params: OfferStateParams,
    filterPressed?: string,
    page?: number
  ) => void
  setOffer: (offer: OfferModel) => void
  setShowingRemainingFilters: (
    showingRemainingFilters: Record<string, boolean>
  ) => void
  clearFilters: (campaign: string, limit?: number) => void
}
