import Skeleton from 'react-loading-skeleton'

import * as S from './SkeletonPrices.styles'

export default function SkeletonPrices() {
  return (
    <S.SkeletonContainer>
      <Skeleton width={70} height={12} />
      <Skeleton width={120} height={18} />
      <Skeleton width={80} height={12} />
    </S.SkeletonContainer>
  )
}
