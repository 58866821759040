import { Content, Arrow } from '@radix-ui/react-tooltip'
import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1); }
`

export const TooltipContent = styled(Content)`
  padding: ${({ theme }) => theme.spacing.xxxs};
  ${({ theme }) => theme.spacing.xxs};
  width: fit-content;
  max-width: var(--radix-tooltip-content-available-width);
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.black(600)};
  color: ${({ theme }) => theme.colors.white(500)};
  animation: ${fadeIn} ease-in-out 0.2s;
  ${({ theme }) => theme.font(12, 18, 400)}
  z-index: 2;
  text-transform: initial;

  b {
    ${({ theme }) => theme.font(12, 18, 700)}
  }
`

export const TooltipContainer = styled.button`
  cursor: unset;
  background: transparent;
  text-align: inherit;
`

export const TooltipArrow = styled(Arrow)`
  fill: ${({ theme }) => theme.colors.black(600)};
  margin-left: unset !important;
`
