const { generateShippingStars } = require('../utils/paymentOptions')
const PaymentOptionsRequest = (data) => {
  return {
    loja: '001',
    session: data.session,
    id_cliente: data.clientId,
    cupom: data.coupon || '',
    origem: data.origin || ''
  }
}

const PaymentOptionsResponse = (data, coupon = '') => {
  const {
    payments: { cash, card, cobranded, has_pix_shipping },
    sellers
  } = data

  const bankSlipDetails = cash.filter((x) => x.category === 'boleto')[0]

  const paypalDetails = cash.filter((x) => x.category === 'paypal')[0]

  const pixDetails = cash.filter((x) => x.category === 'pix')[0] || {}

  const creditCardDetails = card
  const creditCardParcels = creditCardDetails.parcels

  const cobrandedCardDetails = cobranded
  const cobrandedCardParcels = cobrandedCardDetails.parcels

  const formattedSellers = sellers.map((seller) => ({
    id: seller.id,
    name: seller.name,
    origins: seller.origins.map((origin) => ({
      id: origin.id,
      shipping: origin.shipping.map((shippingOption) => ({
        id: shippingOption.id,
        name: shippingOption.name,
        price: shippingOption.price,
        rate: shippingOption.rate,
        stars: generateShippingStars(shippingOption.rate),
        fromDate: shippingOption.from_date || '',
        toDate: shippingOption.to_date || '',
        selected: shippingOption.is_selected || false,
        date: shippingOption.shipping_date || '',
        period: shippingOption.shipping_period || ''
      })),
      products: origin.products.map((product) => ({
        id: product.id,
        code: product.code,
        tagCode: product.tag_code || '',
        offerId: product.offer_id || '',
        name: product.name,
        image: product.image,
        link: product.link,
        manufacturer: product.manufacture,
        category: product.category,
        isOpenbox: product.is_openbox || false,
        isPrime: product.is_prime || false,
        isOffer: product.is_offer || false,
        expiredOffer: product.is_expired_offer || false,
        gift: {
          code: product.gift.code || '',
          name: product.gift.name || '',
          image: product.gift.image || '',
          link: product.gift.link || ''
        },
        services: {
          warranty:
            product.services
              ?.filter((item) => item.code === 'garantia')
              .map((service) => {
                return service.options
                  .filter((it) => it.selected)
                  .map((option) => ({
                    code: option.code,
                    companyCode: option.warranty_code,
                    description: option.description,
                    companyName: option.warranty_name,
                    price: option.price
                  }))
              })[0] ?? [],
          insurance:
            product.services
              ?.filter((item) => item.code === 'seguro')
              .map((service) => {
                return service.options
                  .filter((it) => it.selected)
                  .map((option) => ({
                    code: option.code,
                    companyCode: option.warranty_code,
                    description: option.description,
                    companyName: option.warranty_name,
                    price: option.price
                  }))
              })[0] ?? []
        },
        discount: product.discount || 0,
        couponDiscount: product.coupon_discount || 0,
        maxQuantity: product.max_quantity,
        quantity: product.quantity,
        coupon: product.coupon_discount > 0 ? coupon : '',
        unitForwardPrice: product.unit_forward_price,
        totalForwardPrice: product.total_forward_price,
        unitCashPrice: product.unit_cash_price,
        totalCashPrice: product.total_cash_price
      }))
    }))
  }))

  return {
    sellers: formattedSellers,
    pix:
      Object.keys(pixDetails).length === 0 && pixDetails.constructor === Object
        ? {}
        : {
            discountValue: pixDetails.discount_value,
            total: pixDetails.forward_price,
            pixValue: pixDetails.cash_price,
            discountPercentage: pixDetails.discount
          },
    bankSlip: bankSlipDetails && {
      discountValue: bankSlipDetails.discount_value,
      total: bankSlipDetails.forward_price,
      bankSlipValue: bankSlipDetails.cash_price,
      discountPercentage: bankSlipDetails.discount
    },
    creditCard:
      creditCardParcels.length > 0
        ? {
            paymentMethod: creditCardParcels.map(
              ({ description, code, cash_price }, index) => {
                const selected = index === 0
                return {
                  selected,
                  label: description,
                  code: code,
                  value: cash_price
                }
              }
            )
          }
        : undefined,
    cobrandedCard: {
      paymentMethod: cobrandedCardParcels.map(
        ({ description, code, cash_price }, index) => {
          const selected = index === 0
          return {
            selected,
            label: description,
            code: code,
            value: cash_price
          }
        }
      )
    },
    paypal: { total: paypalDetails?.forward_price },
    has_pix_shipping
  }
}

module.exports = {
  PaymentOptionsRequest,
  PaymentOptionsResponse
}
