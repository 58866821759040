type RequestCache =
  | 'default'
  | 'force-cache'
  | 'no-cache'
  | 'no-store'
  | 'only-if-cached'
  | 'reload'

export enum StatusCode {
  ok = 200,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  serverError = 500
}

export type Response<T, R> = {
  body?: T
  error?: R
  statusCode: StatusCode
}

type Method = 'get' | 'post' | 'put' | 'delete'

export type Request = {
  body?: any
  cache?: RequestCache
  headers?: any
  method: Method
  params?: Record<string, string | number | boolean>
  timeout?: number
  url: string
}
