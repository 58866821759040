import { HttpClient } from '@k_frontend/core'
import { RemoteCartDTO } from 'data/dto/remoteCartDTO.types'
import { RemoteCartGatewayProtocol } from 'data/protocols/remoteCartGatewayProtocol'
import { Cart } from 'domain/entities/cart'
import { CartOrigin } from 'domain/entities/cartOrigin'
import { CartProduct } from 'domain/entities/cartProduct'
import { CartSeller } from 'domain/entities/cartSeller'
import { CartService } from 'domain/entities/cartService'
import { CartShipping } from 'domain/entities/cartShipping'
import { RemoteCartGatewayDTO } from 'infra/dto/cartGatewayDTO.types'

export class RemoteCartGateway implements RemoteCartGatewayProtocol {
  private readonly httpClient: HttpClient<RemoteCartGatewayDTO.LoadResponse>
  private readonly url: string

  constructor(
    httpClient: HttpClient<RemoteCartGatewayDTO.LoadResponse>,
    url: string
  ) {
    this.httpClient = httpClient
    this.url = url
  }

  private mapCartTotals(totals: RemoteCartGatewayDTO.LoadResponseTotals) {
    return {
      cashPrice: totals.cash_price,
      cashPriceProducts: totals.cash_price_products,
      cashSave: totals.cash_save,
      couponDiscount: totals.coupon_discount,
      coupons: totals.coupons,
      credit: totals.credit,
      hasTaxe: totals.has_taxe,
      priceProducts: totals.price_products,
      primeSave: totals.prime_save,
      services: totals.services,
      shipping: totals.shipping,
      cashPrimePriceProducts: totals.prime_price_products
    }
  }

  private mapCartProduct(product: RemoteCartGatewayDTO.LoadResponseProduct) {
    return new CartProduct({
      preOrderDate: product.pre_order_date,
      isPreOrder: product.is_pre_order,
      stamp: product.stamp,
      isOpenbox: product.is_openbox,
      isPrime: product.is_prime,
      hasAssembly: product.has_assembly,
      hasPixShipping: product.has_pix_shipping,
      pcBuildId: product.pc_build_id,
      expiredOffer: product.expired_offer,
      id: product.id,
      services: product.services?.map((service) =>
        this.mapCartService(service)
      ),
      totalPriceWarrantyPartial: product.total_price_warranty_partial,
      priceWarrantyPartial: product.price_warranty_partial,
      category: product.category,
      isDigital: product.is_digital,
      offerId: product.offer_id,
      code: product.code,
      name: product.name,
      image: product.image,
      link: product.link,
      tagCode: product.tag_code,
      manufacture: product.manufacture,
      isOffer: product.is_offer,
      gift: product.gift,
      discount: product.discount,
      maxQuantity: product.max_quantity,
      quantity: product.quantity,
      couponDiscount: product.coupon_discount,
      cashDiscountCoupon: product.cash_discount_coupon,
      unitForwardPrice: product.unit_forward_price,
      unitCashPrice: product.unit_cash_price,
      totalCashPrice: product.total_cash_price,
      evaluationNumber: product.evaluation_number,
      evaluationGrade: product.evaluation_grade,
      totalForwardPrice: product.total_forward_price,
      couponId: product.coupon_id,
      sku: product.sku,
      primePrice: product.price_prime_cash
    })
  }

  private mapCartShipping(
    cartShipping: RemoteCartGatewayDTO.LoadResponseShipping
  ): CartShipping {
    return new CartShipping({
      basketId: cartShipping.basket_id,
      deadlineParameters: cartShipping.deadline_parameters,
      deliveriesUUID: cartShipping.deliveries_uuid,
      discountPrime: cartShipping.discount_prime,
      fromDate: cartShipping.from_date,
      id: cartShipping.id,
      isFlash: cartShipping.is_flash,
      maxDeadline: cartShipping.max_deadline,
      minDeadline: cartShipping.min_deadline,
      name: cartShipping.name,
      price: cartShipping.price,
      rate: cartShipping.rate,
      selected: cartShipping.selected,
      serviceProvider: cartShipping.service_provider,
      shippingDate: cartShipping.shipping_date,
      shippingPeriod: cartShipping.shipping_period,
      shippingType: cartShipping.shipping_type,
      toDate: cartShipping.to_date
    })
  }

  private mapCartOrigin(
    cartOrigin: RemoteCartGatewayDTO.LoadResponseOrigin
  ): CartOrigin {
    return new CartOrigin({
      id: cartOrigin.id,
      shipping: cartOrigin.shipping.map((shipping) =>
        this.mapCartShipping(shipping)
      ),
      products: cartOrigin.products.map((product) =>
        this.mapCartProduct(product)
      )
    })
  }

  private mapCartSeller(
    seller: RemoteCartGatewayDTO.LoadResponseSeller
  ): CartSeller {
    return new CartSeller({
      id: seller.id,
      name: seller.name,
      origins: seller.origins.map((origin) => this.mapCartOrigin(origin))
    })
  }

  private mapCartService(
    productService: RemoteCartGatewayDTO.LoadResponseService
  ): CartService {
    return new CartService({
      code: productService.code,
      name: productService.name,
      option: productService.options.map((option) => ({
        code: option.code,
        description: option.description,
        name: option.name,
        parceledPrice: option.parceled_price,
        parcels: option.parcels,
        selected: option.selected
      }))
    })
  }

  async load(cart: RemoteCartDTO.Payload) {
    const { body } = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        address: {
          zip_code: cart.address.zip_code || '',
          code: cart.address.code || null
        },
        request_origin: cart.request_origin,
        client_id: cart.client_id || '',
        coupon: cart.coupon || [],
        media_origin: cart.media_origin || '',
        sellers: cart.sellers || [],
        session: cart.session || '',
        store: cart.store || '1',
        take_away: cart.take_away
      }
    })

    if (!body) throw new Error('Failed to fetch cart')

    return new Cart({
      sellers: body.sellers.map((seller) => this.mapCartSeller(seller)),
      coupon: body.coupon,
      couponLimit: body.coupon_limit,
      totals: this.mapCartTotals(body.totals),
      blackfriday: body.blackfriday
    })
  }
}
