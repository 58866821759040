import { makeAxiosHttpClient } from '@k_frontend/core'
import { RemoteLoadUpdateOffer } from 'data/usecases'
import { KABUM_COMPLETE_URL } from 'settings/kernel'

export function makeRemoteLoadUpdateOfferFactory() {
  return new RemoteLoadUpdateOffer(
    `${KABUM_COMPLETE_URL}/data_update.json`,
    makeAxiosHttpClient()
  )
}
