import { notify } from 'react-notify-toast'

export function pricesErrorNotify(error: boolean) {
  if (error) {
    return notify.show(
      'Tivemos um problema com os itens dessa lista. Por favor atualize a página.',
      'error',
      5000
    )
  }
}
