import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
    0px 2px 4px rgba(96, 97, 112, 0.16);
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  scroll-snap-type: y mandatory;

  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
  //firefox
  & {
    scrollbar-width: 0px;
  }
`

const ScrollButton = styled.button`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white(500)};
  width: 100%;
  height: ${({ theme }) => theme.spacing.lg};
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
`

export const ScrollUpButton = styled(ScrollButton)`
  top: 0px;
  background-color: ${({ theme }) => theme.colors.black(400)};
`

export const ScrollDownButton = styled(ScrollButton)`
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.black(400)};
  svg {
    transform: rotate(180deg);
  }
`

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.font(14, 24, 400)}
  border: 0;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.black(700)};
  padding: ${({ theme }) => `${theme.spacing.xxxs} ${theme.spacing.sm}`};
  text-align: left;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  scroll-snap-stop: normal;
  scroll-snap-align: end;

  svg {
    flex-basis: ${({ theme }) => theme.spacing.xs};
    transform: rotate(90deg);
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black(400)};
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.black(400)};
  }
`
