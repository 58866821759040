import { useEffect, useMemo } from 'react'
import { DebounceInput } from 'react-debounce-input'

import { IconHeaderSearch } from '@k_frontend/icons'
import { SrOnly } from '@k_frontend/ui'

import { useHeaderContainer } from 'containers/HeaderContainer/HeaderContainer.hook'
import * as S from '../Searchbar.styles'
import { PropsSearchbarHOC } from '../Searchbar.types'
import * as D from './SearchbarDesktop.styles'

function SearchbarDesktop({
  searchText,
  searchResult,
  handleChange,
  handleFormOnSubmit,
  inputId
}: PropsSearchbarHOC) {
  const { isViewFloatingMenu } = useHeaderContainer()

  useEffect(() => {
    const searchCorrectId = isViewFloatingMenu
      ? 'input-busca-floating'
      : 'input-busca'
    const inputRef = document.getElementById(
      searchCorrectId
    ) as HTMLInputElement

    const handleFocusInput = (event: KeyboardEvent) => {
      if (document.activeElement.tagName !== 'BODY') {
        return
      }

      if (event.code === 'KeyK') {
        event.preventDefault()
        inputRef?.focus()
      }
    }

    window.addEventListener('keydown', handleFocusInput)

    return () => {
      window.removeEventListener('keydown', handleFocusInput)
    }
  }, [inputId, isViewFloatingMenu])

  return (
    <D.Container isSearchResult={searchResult.length > 0}>
      <D.Form onSubmit={handleFormOnSubmit} action='/busca' method='GET'>
        <SrOnly as='label' htmlFor={inputId}>
          Buscar Produtos
        </SrOnly>
        <DebounceInput
          id={inputId}
          autoComplete='off'
          minLength={3}
          debounceTimeout={700}
          onChange={(event) => handleChange(event.target.value)}
          value={searchText}
          placeholder='Aperte o K e busque aqui'
          type='text'
          name='query'
          className='id_search_input'
        />
        <D.Button type='submit' aria-label='Buscar'>
          <IconHeaderSearch aria-hidden />
        </D.Button>
        <D.DropdownSearch className='DropdownSearchbar'>
          {searchResult.length !== 0 &&
            searchResult.map((item: any) => (
              <S.Link key={item.codigo} href={item.url_descricao}>
                <S.ItemDropdown>
                  <S.ContentItem>
                    <S.ImageItem backgroundImage={item.imagem} />
                    <D.TitleItem>{item.nome}</D.TitleItem>
                  </S.ContentItem>
                </S.ItemDropdown>
              </S.Link>
            ))}
        </D.DropdownSearch>
      </D.Form>
    </D.Container>
  )
}

export default SearchbarDesktop
