import { axios } from 'services/axios'
import { BACKEND_BLACKFRIDAY } from 'settings/kernel'

import { axiosException } from '../exception/axios'

export const getFlag = async () => {
  try {
    const { data } = await axios.get(BACKEND_BLACKFRIDAY)
    const { blackflag } = data

    if (blackflag === 'true') {
      return true
    }
    return false
  } catch (error) {
    axiosException(error, 'BLACK FRIDAY')
    return false
  }
}
