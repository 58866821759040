import { IconPrimeBlackHorizontal } from '@k_frontend/icons'
import { Separator } from '@k_frontend/ui'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { currency } from 'utils/paymentOptions'
import { TotalProps } from './Total.types'

function Total({ totals }: TotalProps) {
  const dispatch = useDispatch()

  if (!totals) return null

  const subTotalInfo = !totals.services
    ? { label: 'Subtotal dos produtos:', total: totals.cashPriceProducts }
    : {
        label: 'Subtotal dos produtos e serviços:',
        total: totals.cashPriceProducts + totals.services
      }

  const showPricePrime =
    totals.cashPriceProducts !== totals.cashPrimePriceProducts

  async function onAddToCartClick() {
    const { data: primeData } = await axios.get(`/api/core/compre-prime/1`)

    const products = [primeData]

    dispatch({ type: 'ADD_PRODUCT_TO_CART', products, addType: 'buyAsPrime' })
  }

  return (
    <>
      <span className='text-sm font-semibold text-black-800'>
        {subTotalInfo.label}
      </span>
      <p className='ml-auto text-black-800'>
        <b className='font-bold text-base'>{currency(subTotalInfo.total)}</b>{' '}
        <span className='text-sm leading-6'>À vista no PIX</span>
      </p>
      {showPricePrime && (
        <div className='col-span-full'>
          <Separator />
          <p className='text-right text-base text-black-900 mt-4'>
            <button
              type='button'
              onClick={onAddToCartClick}
              className='text-sm text-secondary-500 underline gap-4 font-semibold'
            >
              Assine
            </button>{' '}
            o <IconPrimeBlackHorizontal width={90} height={16} /> e pague{' '}
            <b> {currency(totals.cashPrimePriceProducts)}</b>
          </p>
        </div>
      )}
    </>
  )
}

export default Total
