import { CartProductDTO } from 'domain/dto/cartProductDTO.types'

import { CartService } from './cartService'

export class CartProduct {
  services: CartService[]
  preOrderDate: string
  isPreOrder: boolean
  stamp: string
  isOpenbox: boolean
  isPrime: boolean
  hasPixShipping: boolean
  pcBuildId: string
  pcBuildAssemblyProducts: CartProduct[]
  expiredOffer: boolean
  hasAssembly: boolean
  id: string
  totalPriceWarrantyPartial: number
  priceWarrantyPartial: number
  category: string
  isDigital: boolean
  offerId: string
  code: number
  name: string
  image: string
  link: string
  tagCode: string
  manufacture: string
  isOffer: boolean
  gift: any
  discount: number
  maxQuantity: number
  quantity: number
  couponDiscount: number
  cashDiscountCoupon: number
  unitForwardPrice: number
  unitCashPrice: number
  totalCashPrice: number
  evaluationNumber: number
  evaluationGrade: number
  totalForwardPrice: number
  couponId: string
  sku: number
  primePrice: number

  constructor(product: CartProductDTO.Props) {
    this.services = product.services
    this.preOrderDate = product.preOrderDate
    this.isPreOrder = product.isPreOrder
    this.stamp = product.stamp
    this.isOpenbox = product.isOpenbox
    this.isPrime = product.isPrime
    this.hasPixShipping = product.hasPixShipping
    this.pcBuildId = product.pcBuildId
    this.expiredOffer = product.expiredOffer
    this.id = product.id
    this.totalPriceWarrantyPartial = product.totalPriceWarrantyPartial
    this.priceWarrantyPartial = product.priceWarrantyPartial
    this.category = product.category
    this.isDigital = product.isDigital
    this.offerId = product.offerId
    this.code = product.code
    this.name = product.name
    this.image = product.image
    this.link = product.link
    this.tagCode = product.tagCode
    this.manufacture = product.manufacture
    this.isOffer = product.isOffer
    this.gift = product.gift
    this.discount = product.discount
    this.maxQuantity = product.maxQuantity
    this.quantity = product.quantity
    this.couponDiscount = product.couponDiscount
    this.cashDiscountCoupon = product.cashDiscountCoupon
    this.unitForwardPrice = product.unitForwardPrice
    this.unitCashPrice = product.unitCashPrice
    this.totalCashPrice = product.totalCashPrice
    this.evaluationNumber = product.evaluationNumber
    this.evaluationGrade = product.evaluationGrade
    this.totalForwardPrice = product.totalForwardPrice
    this.couponId = product.couponId
    this.sku = product.sku
    this.pcBuildAssemblyProducts = []
    this.primePrice = product.primePrice
    this.hasAssembly = product.hasAssembly
  }

  setPcBuildAssemblyProducts(products: CartProduct[]) {
    this.pcBuildAssemblyProducts = products
  }
}
