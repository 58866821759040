import styled, { css } from 'styled-components'

import { HeaderStyleProps } from './Header.types'

export const Container = styled.header<HeaderStyleProps>`
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  max-width: 100vw;

  ${(props) => {
    if (props.clean) {
      return css`
        border-bottom: ${({ theme }) => theme.rem(3)} solid
          ${props.theme.colors.secondary(500)};
      `
    }
  }}

  * {
    min-height: auto;
    min-width: auto;
  }

  /** Heart and cart icon on header weren't appearing without this on safari */
  .MuiBadge-root {
    display: inline-grid;
  }
`

export const ContainerWrapper = styled.div<HeaderStyleProps>`
  position: relative;
  z-index: 9998;
  width: 100%;
  background: ${(props) =>
    props.isPrime
      ? props.theme.colors.black(900)
      : props.theme.colors.primary(500)};
`

export const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    gap: ${({ theme }) => theme.spacing.sm};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    gap: ${({ theme }) => theme.spacing.md};
  }
`

export const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: ${({ theme }) => theme.rem(36)};
  height: ${({ theme }) => theme.rem(36)};
`

export const SearchbarSection = styled.div`
  display: flex;
  flex-direction: column;

  ${(props: HeaderStyleProps) => {
    if (props.showAddresses) {
      return css`
        margin-top: ${({ theme }) => theme.spacing.md};
      `
    }
  }}
`

export const LogoSection = styled.div`
  display: flex;
  box-sizing: border-box;
  min-width: ${({ theme }) => theme.rem(32)};
  min-height: ${({ theme }) => theme.rem(33)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    min-width: ${({ theme }) => theme.rem(220)};
    min-height: ${({ theme }) => theme.rem(88)};
  }
`

export const ContainerLogo = styled.a`
  cursor: pointer;
`
