const IconPopulars = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.3697 11.3692H21.454C21.1429 6.63343 17.3615 2.84743 12.6302 2.536V0.618325C12.6269 0.453206 12.559 0.295965 12.4412 0.180359C12.3234 0.0647535 12.165 0 12 0C11.835 0 11.6766 0.0647535 11.5588 0.180359C11.441 0.295965 11.3731 0.453206 11.3698 0.618325V2.536C6.63821 2.84743 2.85711 6.63318 2.546 11.3692H0.630309C0.46314 11.3692 0.302819 11.4356 0.184613 11.554C0.0664073 11.6723 0 11.8328 0 12.0001C0 12.1675 0.0664073 12.328 0.184613 12.4463C0.302819 12.5646 0.46314 12.6311 0.630309 12.6311H2.54575C2.85635 17.3676 6.6377 21.1528 11.3698 21.464V23.3817C11.3731 23.5468 11.441 23.704 11.5588 23.8196C11.6766 23.9352 11.835 24 12 24C12.165 24 12.3234 23.9352 12.4412 23.8196C12.559 23.704 12.6269 23.5468 12.6302 23.3817V21.464C17.362 21.1528 21.1434 17.3673 21.4543 12.6311H23.3697C23.5369 12.6311 23.6972 12.5646 23.8154 12.4463C23.9336 12.328 24 12.1675 24 12.0001C24 11.8328 23.9336 11.6723 23.8154 11.554C23.6972 11.4356 23.5369 11.3692 23.3697 11.3692ZM11.3696 18.943C8.0305 18.6416 5.36495 15.9731 5.0642 12.6311H6.32533C6.61647 15.2834 8.71994 17.3891 11.3696 17.6805V18.943ZM11.3696 6.31973C8.71994 6.61117 6.61647 8.71681 6.32533 11.3692H5.0642C5.36546 8.02767 8.03075 5.35887 11.3693 5.0573V6.31973H11.3696ZM12.6302 5.05755C15.9687 5.35887 18.634 8.02767 18.9353 11.3692H17.6744C17.3833 8.71656 15.2798 6.61092 12.6302 6.31973V5.05755ZM12.6302 18.943V17.6808C15.2798 17.3893 17.3833 15.2837 17.6744 12.6313H18.9353C18.6345 15.9731 15.969 18.6414 12.6302 18.943Z"
      fill="#FF6500"
    />
  </svg>
)

export { IconPopulars as default } 