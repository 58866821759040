export const currency = (value, decimalNumbers = 2) => {
  if (!isNaN(parseInt(value))) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: decimalNumbers,
      maximumFractionDigits: decimalNumbers
    }).format(value)
  }

  return null
}

export const percentage = (value) => {
  return `${value}%`
}

export const sanitizeCookies = (cookieString) => {
  return cookieString?.replace(/[()<>;]/g, '') || ''
}

export const sanitizeInputValue = (value) => {
  return value?.replace(/</g, '').replace(/>/g, '')
}

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const timestampToDatestring = (timestamp) => {
  const date = new Date(Number(timestamp) * 1000)

  return date.toLocaleDateString('pt-BR')
}

export function clearObject(obj) {
  for (const key in obj) {
    if (Array.isArray(obj[key]) && obj[key].includes('false')) {
      delete obj[key]
    }

    if (obj[key] === null || obj[key] === undefined || obj[key]?.length === 0) {
      delete obj[key]
    }
  }

  return obj
}

export function alternativeTextSanitization(text) {
  return text?.replace(/[0-9_]+|ADS|KBAR/g, ' ').trim()
}
