import { ReactElement } from 'react'

import { IconArrowOrangeRight } from '@k_frontend/icons'

import * as S from './LinkHome.styles'
import { Props } from './LinkHome.types'

function LinkHome({
  text,
  link,
  marginTop,
  marginRight,
  target,
  mainColor,
  id,
  className
}: Props): ReactElement {
  return (
    <S.RowLink className={className}>
      <S.TextLink
        className="linkListagemProdutos"
        href={link}
        rel="nofollow"
        target={target ?? '_self'}
        marginTop={marginTop}
        marginRight={marginRight}
        mainColor={mainColor}
        id={id}
      >
        {text} <IconArrowOrangeRight width="8px" />
      </S.TextLink>
    </S.RowLink>
  )
}

export default LinkHome
