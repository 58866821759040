import styled from 'styled-components'

export const Container = styled.section`
  width: 100%;
  margin: ${({ theme }) => theme.spacing.sm} 0;

  h2 {
    margin-right: ${({ theme }) => theme.spacing.sm};
  }
`
