import { Provider, Root, Trigger, Portal } from '@radix-ui/react-tooltip'

import * as S from './Tooltip.styles'
import { TooltipProps } from './Tooltip.types'

function Tooltip({ text = 'teste', children, position }: TooltipProps) {
  return (
    <Provider>
      <Root delayDuration={0} defaultOpen={false}>
        <Trigger asChild data-testid="tooltip">
          <S.TooltipContainer>{children}</S.TooltipContainer>
        </Trigger>
        <Portal>
          <S.TooltipContent side={position} sideOffset={8}>
            {text}
            <S.TooltipArrow />
          </S.TooltipContent>
        </Portal>
      </Root>
    </Provider>
  )
}

export default Tooltip
