import * as S from './UnavailablePrices.styles'
import { UnavailablePricesProps } from './UnavailablePrices.types'

export default function UnavailablePrices({ color }: UnavailablePricesProps) {
  return (
    <S.Prices className="unavailablePricesCard">
      <S.Price color={color} className="priceCard">
        R$&nbsp;----
      </S.Price>
    </S.Prices>
  )
}
