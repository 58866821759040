import { tv } from 'tailwind-variants'

export const container = tv({
  base: [
    'flex',
    'flex-wrap',
    'items-center',
    'justify-center',
    'gap-8',
    'bg-white-500',
    'px-16',
    'py-32',

    '[&_svg]:w-24',
    '[&_svg]:h-24',
    '[&_svg]:[&_path]:fill-secondary-500'
  ],
  variants: {
    fullWidth: {
      true: ['w-full'],
      false: ['w-fit']
    }
  }
})

export const svg = tv({
  base: ['w-32', 'h-32', '[&_path]:fill-secondary-500']
})

export const description = tv({
  base: ['text-black-700', 'text-base', 'font-semibold']
})
