import styled from 'styled-components'

export const SkeletonCarousel = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > span {
    display: grid;
    grid-template-columns: repeat(6, ${(props) => props.theme.rem(290)});
    height: ${({ theme }) => theme.rem(230)};
    gap: ${(props) => props.theme.spacing.xxs};
    margin-top: ${(props) => props.theme.spacing.xs};
    margin-bottom: ${(props) => props.theme.spacing.xxs};
    
    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      margin-bottom: ${(props) => props.theme.rem(5)};
      grid-template-columns: repeat(6, ${(props) => props.theme.rem(268)});
      height: ${({ theme }) => theme.rem(436)};
      gap: ${(props) => props.theme.spacing.sm};
    }
  }
`

export const SkeletonFilters = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0;

  > span {
    display: grid;
    grid-template-columns: repeat(6, ${(props) => props.theme.rem(82)});
    height: ${({ theme }) => theme.rem(36)};
    gap: ${(props) => props.theme.spacing.xxs};
    margin-top: ${(props) => props.theme.spacing.sm};

    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      grid-template-columns: repeat(6, ${(props) => props.theme.rem(82)});
      gap: ${(props) => props.theme.spacing.sm};
    }
  }
`

export const Container = styled.div`
  width: 100%;
  position: relative;
`

export const CarouselContainer = styled.div`
  position: relative;

  .productCard {
    display: flex;
    width: ${({ theme }) => theme.rem(290)};
  }

  .swiper {
    position: relative;
    padding: ${({ theme }) => theme.spacing.xs} 0;
  }

  .swiper-slide {
    width: fit-content;
  }

  .swiper-back,
  .swiper-forward {
    background: transparent;
    position: absolute;
    display: none;
  }

  .swiper-back.swiper-button-lock,
  .swiper-forward.swiper-button-lock {
    display: none;
  }

  .linkListagemProdutos {
    margin-top: 0px;
  }

  .linkFooter {
    margin-top: ${({ theme }) => theme.rem(16)};
  }

  .linkFooterCarousel {
    margin-right: ${({ theme }) => theme.rem(14)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    .linkFooterCarousel {
      margin-right: 0px;
    }

    .swiper-back,
    .swiper-forward {
      display: block;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
    }

    .swiper-forward {
      right: ${({ theme, overlappedArrow }) =>
        !overlappedArrow ? theme.rem(-36) : theme.rem(-15)};
    }
    .swiper-back {
      left: ${({ theme, overlappedArrow }) =>
        !overlappedArrow ? theme.rem(-36) : theme.rem(-8)};
    }
    .productCard {
      width: ${({ theme }) => theme.rem(268)};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}) {
    .swiper-forward {
      right: ${({ theme, overlappedArrow }) =>
        !overlappedArrow ? theme.rem(-48) : theme.rem(-15)};
    }
    .swiper-back {
      left: ${({ theme, overlappedArrow }) =>
        !overlappedArrow ? theme.rem(-48) : theme.rem(-8)};
    }
  }
`
