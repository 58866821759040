import LineLoader from 'components/Loaders/LineLoader'

import * as S from './Button.styles'
import { ButtonProps as Props } from './Button.types'

function Button({ text, loading, icon, isPrime, fnOnClick, ...rest }: Props) {
  return (
    <S.Button
      onClick={fnOnClick}
      isPrime={isPrime}
      $loading={loading}
      {...rest}
    >
      {loading ? <LineLoader size={22} thickness={2} /> : icon}
      {text}
    </S.Button>
  )
}

export default Button
