import {
  ProductWarrantyOption,
  ProductWarrantyResponse,
  ProductWarrantyService
} from 'services/warranty/products'

import {
  ProductWarrantyOptionModel,
  ProductWarrantyServiceModel
} from './products.types'

function mapOption(
  options: ProductWarrantyOption[]
): ProductWarrantyOptionModel[] {
  return options.map((option) => ({
    code: option.code,
    description: option.description,
    name: option.name,
    parceledPrice: option.parceled_price,
    price: option.price,
    warrantyCode: option.warranty_code,
    warrantyName: option.warranty_name
  }))
}

function mapServices(
  services: ProductWarrantyService[]
): ProductWarrantyServiceModel[] {
  return services.map((service) => ({
    code: service.code,
    name: service.name,
    options: mapOption(service.options)
  }))
}

export function productWarrantyModel(
  products: ProductWarrantyResponse[]
): ProductWarrantyServiceModel[] {
  return mapServices(products[0].services)
}
