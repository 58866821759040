import { LocalStoreCodeGatewayProtocol } from 'data/protocols/localStoreCodeGatewayProtocol'
import { LoadStoreCodeUseCaseDomain } from 'domain/usecases/loadStoreCode'

export class LoadStoreCodeUseCase implements LoadStoreCodeUseCaseDomain {
  private readonly localStoreCodeGateway: LocalStoreCodeGatewayProtocol

  constructor(localStoreCodeGateway: LocalStoreCodeGatewayProtocol) {
    this.localStoreCodeGateway = localStoreCodeGateway
  }

  load(): string {
    return this.localStoreCodeGateway.load()
  }
}
