import {
  DataLayerEventAddServicePayload,
  getValueOrDefault
} from '@k_frontend/core'
import { clearServicesFromCookie, handleCookie } from 'utils/cart'
import { SetState, create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { BaseProductData, ProductServiceState } from './useProductService.types'

function setSelectedServiceOption(
  serviceCode: string,
  optionCode: number,
  optionDescription: string,
  set: SetState<ProductServiceState>
) {
  const { baseProductData } = useProductService.getState()

  set((state) => {
    const selectedServices = { ...state.selectedServices }

    selectedServices[serviceCode] = {
      sellerId: baseProductData.sellerId,
      originId: baseProductData.originId,
      productCode: baseProductData.productCode,
      name: serviceCode,
      code: optionCode,
      description: optionDescription
    }

    return {
      ...state,
      selectedServices
    }
  })

  const { selectedServices } = useProductService.getState()
  handleCookie(selectedServices[serviceCode])
}

function setSelectedServicesPrice(
  serviceCode: string,
  parceledPrice: number,
  price: number,
  set: SetState<ProductServiceState>
) {
  set((state) => ({
    ...state,
    selectedServicesPrice: {
      ...state.selectedServicesPrice,
      [serviceCode]: {
        parceledPrice,
        price
      }
    }
  }))

  const { availableServices, selectedServicesPrice } =
    useProductService.getState()

  set((state) => ({
    ...state,
    servicesTotalPrice: availableServices.reduce(
      (total, service) => total + (selectedServicesPrice[service]?.price || 0),
      0
    )
  }))
}

const clearSelectedServices = (set: SetState<ProductServiceState>) => {
  const {
    baseProductData: { productCode }
  } = useProductService.getState()

  set((state) => ({
    ...state,
    selectedServices: {},
    selectedServicesPrice: {},
    servicesTotalPrice: 0
  }))

  clearServicesFromCookie(productCode)
}

function formatServicesToDatalayer(): DataLayerEventAddServicePayload {
  const {
    baseProductData,
    selectedServices,
    servicesTotalPrice,
    selectedServicesPrice
  } = useProductService.getState()

  return {
    product: {
      name: baseProductData.name,
      code: String(baseProductData.productCode),
      brand: baseProductData.brand,
      category: baseProductData.category,
      price: baseProductData.price,
      quantity: baseProductData.quantity,
      sellerName: baseProductData.sellerName
    },
    service: {
      secureDescription: getValueOrDefault(
        selectedServices?.seguro?.description,
        'Sem seguro'
      ),
      securePrice: getValueOrDefault(selectedServicesPrice?.seguro?.price, 0),
      totalPrice: servicesTotalPrice,
      warrantyDescription: getValueOrDefault(
        selectedServices.garantia?.description,
        'Sem garantia'
      ),
      warrantyPrice: getValueOrDefault(
        selectedServicesPrice?.garantia?.price,
        0
      )
    }
  }
}

export const useProductService = create<ProductServiceState>()(
  devtools(
    (set) => ({
      availableServices: [],
      selectedServices: {},
      selectedServicesPrice: {},
      baseProductData: {},
      servicesTotalPrice: 0,
      setAvailableServices: (availableServices: string[]) =>
        set((state) => ({ ...state, availableServices })),
      setBaseProductData: (baseProductData: BaseProductData) =>
        set((state) => ({ ...state, baseProductData })),
      setSelectedServiceOption: (
        serviceCode: string,
        optionCode: number,
        optionDescription: string
      ) =>
        setSelectedServiceOption(
          serviceCode,
          optionCode,
          optionDescription,
          set
        ),
      setSelectedServicesPrice: (
        serviceCode: string,
        parceledPrice: number,
        price: number
      ) => setSelectedServicesPrice(serviceCode, parceledPrice, price, set),
      clearSelectedServices: () => clearSelectedServices(set),
      formatServicesToDatalayer: () => formatServicesToDatalayer()
    }),
    {
      name: 'useProductService'
    }
  )
)
