import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  background: transparent;
`
export const ModalSelect = styled.div`
  display: flex;
  color: ${({ theme }) => theme.colors.white(500)};
  cursor: pointer;
`
