import * as Popover from '@radix-ui/react-popover'
import styled, { keyframes } from 'styled-components'

const slideUpAndFadeAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const ButtonTrigger = styled.button`
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.rem(36)};
  height: ${({ theme }) => theme.rem(36)};
`

export const Content = styled(Popover.Content)`
  position: relative;
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.spacing.sm};
  background-color: ${({ theme }) => theme.colors.white(500)};
  width: ${({ theme }) => theme.rem(404)};
  right: ${({ theme }) => theme.rem(-14)};
  top: ${({ theme }) => theme.rem(28)};
  box-shadow: 0px 2px 8px 0px rgba(96, 97, 112, 0.16),
    0px 0px 1px 0px rgba(40, 41, 61, 0.08);
  z-index: 9999;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='open'] {
    &[data-side='bottom'] {
      animation-name: ${slideUpAndFadeAnimation};
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: ${({ theme }) => theme.rem(-15)};
    right: ${({ theme }) => theme.rem(22)};
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid ${({ theme }) => theme.colors.white(500)};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    width: ${({ theme }) => theme.rem(300)};
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.font(20, 30, 700)};
  color: ${({ theme }) => theme.colors.secondary(500)};
  margin-bottom: ${({ theme }) => theme.spacing.xxs};
`

export const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing.sm};
  display: flex;
  gap: ${({ theme }) => theme.spacing.lg};
`

export const Item = styled.a`
  background: transparent;
  text-decoration: none;
  cursor: pointer;
  width: ${({ theme }) => theme.rem(170)};
  height: ${({ theme }) => theme.rem(148)};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.rem(6.5)}`};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.rem(20)};
  flex: 1;
  border: 2px solid transparent;
  box-shadow: 0px 0.5px 2px 0px rgba(96, 97, 112, 0.16),
    0px 0px 1px 0px rgba(40, 41, 61, 0.08);

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(96, 97, 112, 0.16),
      0px 2px 4px 0px rgba(40, 41, 61, 0.04);
  }

  &[aria-pressed='true'] {
    border: 2px solid #ff6500;
    box-shadow: 0px 8px 16px 0px rgba(96, 97, 112, 0.16),
      0px 2px 4px 0px rgba(40, 41, 61, 0.04);
  }
`

export const ItemTitle = styled.span`
  ${({ theme }) => theme.font(16, 24, 700)};
  color: ${({ theme }) => theme.colors.black(800)};

  @media (max-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    ${({ theme }) => theme.font(12, 18, 700)};
  }
`

export const Close = styled(Popover.Close)`
  background: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: ${({ theme }) => theme.rem(19)};
  right: ${({ theme }) => theme.rem(16)};

  &:hover {
    opacity: 0.9;
  }
`
