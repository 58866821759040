import styled from 'styled-components'

export const Placeholder = styled.div<{ isPrime: boolean }>`
  height: ${({ theme }) => theme.rem(46)};
  background: ${(props) =>
    props.isPrime
      ? props.theme.colors.black(900)
      : props.theme.colors.primary(500)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    height: ${({ theme }) => theme.rem(68)};
  }
`
export const Container = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.rem(46)};
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    width: 100%;
    height: ${({ theme }) => theme.rem(68)};
  }
`

export const Redirect = styled.a`
  width: 100%;
  height: 100%;
  outline-offset: ${({ theme }) => theme.rem(-3)};
`

export const CloseButton = styled.button`
  position: absolute;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  width: ${({ theme }) => theme.rem(40)};
  height: ${({ theme }) => theme.rem(40)};
  margin-right: ${({ theme }) => theme.spacing.md};
  cursor: pointer;

  svg {
    width: ${({ theme }) => theme.rem(20)};
    height: ${({ theme }) => theme.rem(20)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    width: ${({ theme }) => theme.spacing.xl};
    height: ${({ theme }) => theme.spacing.xl};
    margin-right: ${({ theme }) => theme.spacing.xl};

    svg {
      width: ${({ theme }) => theme.spacing.md};
      height: ${({ theme }) => theme.spacing.md};
    }
  }
`
