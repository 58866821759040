import { IconCollapseHeader, IconServices } from '@k_frontend/icons'
import * as Accordion from '@radix-ui/react-accordion'

import Service from './Service/Service'
import ServiceSummary from './ServiceSummary'
import { ServicesProps } from './Services.types'

function Services({ services, subtotalServices }: ServicesProps) {
  if (!services || services.length === 0) return null

  return (
    <Accordion.Root type='single' collapsible>
      <Accordion.Item value='service' className='bg-black-300 p-16 mb-12'>
        <Accordion.Header className='flex'>
          <Accordion.Trigger className='flex gap-8 items-center w-full group'>
            <IconServices width={13} height={13} />
            <span className='text-sm text-black-800 font-bold uppercase'>
              Serviços
            </span>
            <IconCollapseHeader className="size-12 ml-auto ease-linear duration-300 -rotate-180 group-[[data-state='open']]:rotate-0" />
          </Accordion.Trigger>
        </Accordion.Header>
        <Accordion.Content>
          {services.map((service) => (
            <Service key={service.code} service={service} />
          ))}
          <ServiceSummary
            services={services}
            subtotalServices={subtotalServices}
          />
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}

export default Services
