function ImportantInformations() {
  return (
    <section
      className='my-16 flex w-full flex-col justify-center px-16 desktop:p-0'
      aria-labelledby='important-info'
    >
      <h3
        className='mb-12 text-xs font-bold text-black-700'
        id='important-info'
      >
        Informações Importantes
      </h3>

      <p className='text-[0.625rem] text-black-700 tablet:text-xs'>
        Seguros de Garantia Estendida Original Ampliada, Processo Susep:
        15414.900275/2014-64 e Proteção Roubo e Furto Qualificado com Quebra
        Acidental, Processo Susep: 15414.900738/2014-98, garantidos pela Cardif
        do Brasil Seguros e Garantias S.A. CNPJ: 08.279.191/0001-84, com
        endereço na Avenida Presidente Juscelino Kubitscheck, Nº 1909, Torre
        Sul, 8º andar, São Paulo/SP, CEP: 04543-907 ou&nbsp;
        <a
          target='_blank'
          href='http://www.cardif.com.br'
          rel='noreferrer'
          className='text-[0.625rem] underline tablet:text-xs'
        >
          www.cardif.com.br.
        </a>
        &nbsp;Representantes: Kabum S.A. CNPJ: 05.570.714/0001-59 e Magazine
        Luiza S/A CNPJ: 47.960.950/0001-21. Corretora: Viotto Corretora de
        Seguros e Previdência LTDA, CNPJ: 56.170.061/0001-51 e Registro SUSEP:
        202069682. Capitalização: Icatu Capitalização S.A., CNPJ:
        74.267.170/0001-73, Processo SUSEP: 15414.900393/2019-87, Ouvidoria
        Icatu Seguros: 0800 286 0047. Modalidade Incentivo. Consulte o
        regulamento do sorteio no site:&nbsp;
        <a
          target='_blank'
          href='http://www.susep.gov.br'
          rel='noreferrer'
          className='text-[0.625rem] underline tablet:text-xs'
        >
          REGULAMENTOS DE CAPITALIZAÇÃO
        </a>
        . É proibida a venda de títulos de capitalização a menores de dezesseis
        anos - Art.3º,I do Código Civil. A contratação de seguro é opcional,
        sendo possível a desistência do contrato em até 7 (sete) dias corridos
        com a devolução integral do valor pago. É proibido condicionar desconto
        no preço do bem à aquisição do seguro. A comercialização de seguros é
        fiscalizada pela SUSEP.&nbsp;
        <a
          target='_blank'
          href='http://www.susep.gov.br'
          rel='noreferrer'
          className='text-[0.625rem] underline tablet:text-xs'
        >
          www.susep.gov.br{' '}
        </a>
        – 0800 021 8484. O registro do produto é automático e não representa
        aprovação ou recomendação por parte da Susep. É dever do intermediário
        disponibilizar ao cliente formal e previamente à contratação do seguro,
        o montante de sua remuneração e o prêmio do seguro. A distribuição dos
        seguros por meio da Kabum é realizada com exclusividade. Saiba mais
        sobre a privacidade dos seus dados pessoais e como serão mantidos em
        segurança: acesse&nbsp;
        <a
          target='_blank'
          href='https://bnpparibascardif.com.br/lei-geral-de-protecao-de-dados'
          rel='noreferrer'
          className='text-[0.625rem] underline tablet:text-xs'
        >
          LEI GERAL DE PROTEÇÃO DE
        </a>
        DADOS . Central de atendimento: SAC (Cancelamentos, reclamações e
        informações do seguro): 0800 200 0615. Abertura e Acompanhamento de
        Sinistros: 3003-4296 (Capitais e regiões metropolitanas) ou 0800 200
        0644 (Demais localidades) ou acesse o site&nbsp;
        <a
          target='_blank'
          href='http://www.acioneseuseguro.com.br'
          rel='noreferrer'
          className='text-[0.625rem] underline tablet:text-xs'
        >
          www.acioneseuseguro.com.br.
        </a>
        Deficiente Auditivo: 0800 725 0645, atendimento todos os dias, 24 horas.
        Ouvidoria Cardif: 0800 727 2482, atendimento em dias úteis, das 9h às
        18h (horário de Brasília) exceto feriados, ou&nbsp;
        <a
          target='_blank'
          href='https://bnpparibascardif.com.br/ouvidoria'
          rel='noreferrer'
          className='text-[0.625rem] underline tablet:text-xs'
        >
          ouvidoria.bnpparibascardif.com.br.
        </a>
      </p>
    </section>
  )
}

export default ImportantInformations
