import { IconCloseModal } from '@k_frontend/icons'
import * as Dialog from '@radix-ui/react-dialog'
import LoaderBanner from 'components/Loaders/KLoader'
import { useMemo } from 'react'
import { tailwindTheme } from 'theme/tailwindTheme'
import { NotificationList } from './NotificationList'
import { useNotificationsContainer } from './NotificationsContainer.hook'
import { useNotificationsStore } from './NotificationsContainer.store'
import { WithoutNotifications } from './WithoutNotifications'

export function NotificationsContainer() {
  const { isOpenNotifications, setIsOpenNotifications } =
    useNotificationsStore()
  const {
    onButtonClickAction,
    allNoticesLoading,
    allCustomerNotices,
    shouldRenderAlert
  } = useNotificationsContainer()

  const renderNotices = useMemo(() => {
    if (shouldRenderAlert) {
      return <WithoutNotifications />
    }

    if (allNoticesLoading) {
      return <LoaderBanner />
    }

    return <NotificationList notices={allCustomerNotices?.notifications} />
  }, [allNoticesLoading, shouldRenderAlert, allCustomerNotices])

  return (
    <Dialog.Root
      open={isOpenNotifications}
      onOpenChange={setIsOpenNotifications}
    >
      <Dialog.Overlay className='fixed inset-0 z-[9999] bg-black-950/60' />
      <Dialog.Content className='fixed inset-y-0 right-0 z-[9999] grid w-full tablet:w-[592px] grid-rows-[auto_1fr] rounded-2 bg-white p-16'>
        <Dialog.Title className='flex items-center gap-12 py-8'>
          <Dialog.Close data-testid='icon-close-notification-menu'>
            <IconCloseModal
              color={tailwindTheme.colors.black['800']}
              width={22}
              height={22}
            />
          </Dialog.Close>
          <div className='flex items-center w-full justify-between'>
            <span className='text-2xl font-bold uppercase text-secondary'>
              Notificações
            </span>
          </div>
        </Dialog.Title>
        <div className='mb-8 overflow-auto'>{renderNotices}</div>
        <div className='flex items-center justify-center'>
          <button
            className='w-full h-48 bg-secondary-500 text-black-300 text-sm font-bold rounded-4'
            type='button'
            onClick={onButtonClickAction}
            data-testid='button-go-to-notifications'
          >
            VER TODAS AS NOTIFICAÇÕES
          </button>
        </div>
      </Dialog.Content>
    </Dialog.Root>
  )
}
