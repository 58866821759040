import { useEffect, useState } from 'react'

import {
  dataLayerEventProductClick,
  dataLayerEventProductImpressions,
  useUser
} from '@k_frontend/core'
import IconPopulars from 'components/Icons/IconPopulars'
import { CatalogCarousel } from 'components/ProductsCarousel'
import { CrossCartProductModel } from 'models/crossCart/crossCart.types'
import getCrossCart from 'services/crossCart'

import * as S from './CrossCart.styles'
import * as Type from './CrossCart.types'
import MiniCrossCart from './MiniCrossCart/MiniCrossCart'

function CrossCart({
  productsId,
  cookieIsMobile,
  isMiniCrossCart
}: Type.CrossCartProps) {
  const [crossCartData, setCrossCartData] = useState<CrossCartProductModel[]>(
    []
  )
  const hasProductsId = productsId?.length > 0
  const isPrime = useUser((state) => state.isPrime)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const carouselTitle = 'Quem comprou, comprou também'

  async function getCrossCartData() {
    try {
      setCrossCartData(await getCrossCart(productsId, isPrime))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (hasProductsId) {
      getCrossCartData()
    }
  }, [])

  useEffect(() => {
    if (crossCartData.length > 0) {
      const pushProductImpressionsToDatalayer = (products) => {
        const productsFormatted = products.map((product) => ({
          name: product.name,
          id: product.code,
          price:
            product.priceWithDiscount.toFixed(2) || product.price.toFixed(2),
          brand: product.manufacturer?.name || '',
          list: product.list,
          category: product.category || '',
          position: product.position,
          dimension20: product.sellerId,
          dimension21: product.sellerName
        }))
        dataLayerEventProductImpressions({
          products: productsFormatted,
          uniqueEventId: 5
        })
      }
      pushProductImpressionsToDatalayer(crossCartData)
    }
  }, [crossCartData])

  const dataLayerProductClick = (product: CrossCartProductModel) => {
    const productFormatted = [
      {
        name: product.name,
        id: product.code.toString(),
        price: product.price,
        brand: product.manufacturer.name,
        category: product.category,
        position: product.position,
        dimension20: product.sellerId,
        dimension21: product.sellerName
      }
    ]

    dataLayerEventProductClick({
      products: productFormatted,
      list: product.list
    })
  }

  if (!hasProductsId) return null

  if (isMiniCrossCart)
    return (
      <MiniCrossCart
        products={crossCartData}
        onProductClick={dataLayerProductClick}
        isLoading={isLoading}
      />
    )

  return (
    <S.Container id='secaoAcabaramDeChegar' aria-label={carouselTitle}>
      <CatalogCarousel
        id='carousel-crossCart'
        isLoading={isLoading}
        list={crossCartData}
        onProductClick={dataLayerProductClick}
        header={{
          icon: <IconPopulars aria-hidden />,
          title: carouselTitle
        }}
        showAll={false}
        cookieIsMobile={cookieIsMobile}
      />
    </S.Container>
  )
}

export default CrossCart
