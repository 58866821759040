import { Storage } from '@k_frontend/core'
import { LocalIsMobileGatewayProtocol } from 'data/protocols/localIsMobileGatewayProtocol'
import { KABUM_COOKIE_URL } from 'settings/kernel'

export class LocalIsMobileGateway implements LocalIsMobileGatewayProtocol {
  private readonly storageClient: Storage

  constructor(storageClient: Storage) {
    this.storageClient = storageClient
  }

  load() {
    return (
      this.storageClient.get({
        name: 'isMobileDevice',
        options: {
          domain: KABUM_COOKIE_URL
        }
      }) === 'true'
    )
  }
}
