import { ResizeHOC } from '@k_frontend/core'
import { HeaderContainer } from 'containers/HeaderContainer'

import { HeaderContextProvider } from './Header.context'
import { HeaderControllerProps as Props } from './Header.types'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export function Header(props: Props) {
  const isDynamicProductPage = props.pathname.match(/^\/produto\/(.+)/)
  const showFloatingHeader = !isDynamicProductPage

  return (
    <HeaderContextProvider {...props}>
      <ResizeHOC
        MobileComponent={HeaderMobile}
        DesktopComponent={HeaderDesktop}
        cookieIsMobile={props.cookieIsMobile}
      />
      {showFloatingHeader && <HeaderContainer />}
    </HeaderContextProvider>
  )
}
