import { useId, useState } from 'react'

import dynamic from 'next/dynamic'

import { IconArrowDown, IconTrackingCheckFill } from '@k_frontend/icons'
import * as Accordion from '@radix-ui/react-accordion'
import { STATIC_KABUM } from 'settings/kernel'
import { tailwindTheme } from 'theme/tailwindTheme'

import Option from './Option'
import {
  servicesAdvantage,
  servicesHeader,
  withoutServiceOptions
} from './Service.constants'
import { ServiceProps } from './Service.types'

const ModalGeneralConditions = dynamic(
  () =>
    import(
      /* webpackChunkName: 'PreCheckoutTerms' */ './ModalGeneralConditions'
    ),
  { ssr: false }
)

function Service({ service, productCode }: ServiceProps) {
  const [isModalTermsOfUseOpen, setIsModalTermsOfUseOpen] =
    useState<boolean>(false)
  const [serviceCodeModal, setServiceCodeModal] = useState<number>()
  const serviceHeader = servicesHeader[service.code]
  const serviceAdvantage = servicesAdvantage[service.code]
  const withoutServiceOption = withoutServiceOptions[service.code]
  const advantagesOfServices = useId()

  function handleOpenModal(serviceCode: number) {
    setIsModalTermsOfUseOpen(true)
    setServiceCodeModal(serviceCode)
  }

  return (
    <>
      <Accordion.Item
        value={service.code}
        key={service.code}
        className='bg-white-500 w-full px-16'
      >
        <Accordion.Trigger className='group flex w-full items-center justify-between gap-8 py-8'>
          <serviceHeader.Icon
            height={14}
            width={14}
            className='min-w-[0.875rem]'
            aria-hidden
          />

          <Accordion.Header className='mr-auto text-base text-left font-bold uppercase text-black-800'>
            {serviceHeader.title}
          </Accordion.Header>
          <IconArrowDown
            className='transition-transform duration-300 group-data-[state=open]:rotate-180 min-w-16'
            height={9}
            width={16}
            aria-hidden
          />
        </Accordion.Trigger>

        <Accordion.Content>
          <p
            className='text-sm text-black-800'
            dangerouslySetInnerHTML={{ __html: serviceHeader.caption }}
          />

          <ul className='my-12 grid auto-rows-auto gap-12 tablet:grid-cols-2 desktop:grid-cols-[repeat(auto-fill,_minmax(288px,_1fr))]'>
            {service.options.map((option, index) => {
              return (
                <Option
                  option={option}
                  caption={'até 10x sem juros de'}
                  key={option.code}
                  recommended={index === 0}
                  serviceCode={service.code}
                  handleOpenModal={handleOpenModal}
                />
              )
            })}
            <Option
              option={withoutServiceOption}
              description={withoutServiceOption.caption}
              recommended={false}
              serviceCode={service.code}
              handleOpenModal={handleOpenModal}
            />
          </ul>

          <section aria-labelledby={advantagesOfServices}>
            <h4
              className='text-sm font-bold text-black-800'
              id={advantagesOfServices}
            >
              Está incluso em nossos serviços:
            </h4>

            <ul className='my-12 flex flex-col gap-4'>
              {serviceAdvantage.options.map((option) => (
                <li key={option.id} className='flex items-center gap-8'>
                  <IconTrackingCheckFill
                    className='min-w-16'
                    color={tailwindTheme.colors.success[500]}
                    height={16}
                    width={16}
                    aria-hidden
                  />
                  <p
                    className='text-sm text-black-800'
                    dangerouslySetInnerHTML={{ __html: option.content }}
                  />
                </li>
              ))}
            </ul>
          </section>

          <p className='text-xs text-black-800'>
            Ao adicionar {serviceAdvantage.finePrint}, declaro que tive acesso,
            li e aceito as{' '}
            <a
              className='text-xs text-secondary-500 underline'
              href={`${STATIC_KABUM}/conteudo/cardif/Termos_Cardif.pdf`}
              target='_blank'
              rel='noreferrer'
            >
              Condições gerais
            </a>
          </p>
        </Accordion.Content>
      </Accordion.Item>

      <ModalGeneralConditions
        serviceCodeModal={serviceCodeModal}
        productCode={productCode}
        isOpen={isModalTermsOfUseOpen}
        handleClose={() => setIsModalTermsOfUseOpen(false)}
        serviceName={service.code}
      />
    </>
  )
}

export default Service
