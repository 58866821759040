import { SetStateAction, useEffect, useState } from 'react'
/**
 *
 * @param {string} [settings] - The settings to the IntersectionObserver (Optional)
 * @param setValue - Function to update the state with true or false
 * @returns {IntersectionObserver} An IntersectionObserver to define wich element will be observed
 */
function useIsVisible(
  setValue: SetStateAction<any>,
  observerSettings: IntersectionObserverInit = {}
) {
  const [observer, setObserver] = useState<IntersectionObserver>(null)

  useEffect(() => {
    setObserver(
      new IntersectionObserver(([entry]) => {
        setValue(entry.isIntersecting)
      }, observerSettings)
    )
  }, [])

  return observer
}

export default useIsVisible
