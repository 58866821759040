const self = {}

self.formatProductFriendlyName = (name) => {
  return name
    ?.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z0-9\-\_]/gi, '-')
    .replace(/\-{2,}/gi, '-')
}

module.exports = self
