import { getCookie, setCookie } from 'cookies-next'
import { FeatureFlag } from 'utils/featureFlag/featureFlag'

export async function setMiniCartFeatureFlag(session: string) {
  const init = await FeatureFlag.getInstance()
  const miniCartFeatureFlag = await init.isFeatureEnabled(
    'campaign_mini_cart',
    session
  )
  setCookie('miniCartFeatureFlag', miniCartFeatureFlag, {
    maxAge: 600
  })
}

export function getMiniCartFeatureFlag() {
  return getCookie('miniCartFeatureFlag')
}

export function isMiniCartOn() {
  const miniCartFeatureFlag = getMiniCartFeatureFlag()

  if (typeof miniCartFeatureFlag === 'boolean') return miniCartFeatureFlag

  return miniCartFeatureFlag === 'true'
}
