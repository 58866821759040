// eslint-disable-next-line no-use-before-define
import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { IconCollapseHeader } from '@k_frontend/icons'
import ThemeDefault from 'theme'

import * as S from './DepartmentsMenu.styles'
import { MobileDepartmentsMenuProps, StepsEnum } from './DepartmentsMenu.types'

export default function DepartmentsMenuMobile({
  departments,
  sections,
  categories,
  currentDepartment,
  currentSection,
  handleEnterDepartment,
  handleEnterSection,
  handleBackMenu,
  isLoading,
  bannerData,
  bannerRef,
  dataLayerBannerClick
}: MobileDepartmentsMenuProps) {
  const hasSections = sections.length > 0
  const hasCategories = categories.length > 0
  let currentStep = StepsEnum.DEPARTMENTS
  if (hasCategories) currentStep = StepsEnum.CATEGORIES
  else if (hasSections) currentStep = StepsEnum.SECTIONS

  const titleMenuValues = {
    [StepsEnum.DEPARTMENTS]: 'Departamentos',
    [StepsEnum.SECTIONS]: currentDepartment.name,
    [StepsEnum.CATEGORIES]: currentSection.name
  }
  const titleMenu = titleMenuValues[currentStep]

  const linkMenuValues = {
    [StepsEnum.DEPARTMENTS]: '',
    [StepsEnum.SECTIONS]: `/${currentDepartment.path}`,
    [StepsEnum.CATEGORIES]: `/${currentSection.path}`
  }
  const linkMenu = linkMenuValues[currentStep]

  function getItemLink(slug: string): string {
    const linkValues = {
      [StepsEnum.DEPARTMENTS]: `/${slug}`,
      [StepsEnum.SECTIONS]: `/${currentDepartment.path}/${slug}`,
      [StepsEnum.CATEGORIES]: `/${currentSection.path}/${slug}`
    }
    return linkValues[currentStep]
  }

  function getContent() {
    const contentValues = {
      [StepsEnum.DEPARTMENTS]: { list: departments, showArrow: true },
      [StepsEnum.SECTIONS]: { list: sections, showArrow: true },
      [StepsEnum.CATEGORIES]: { list: categories, showArrow: false }
    }
    const { list, showArrow } = contentValues[currentStep]
    return getList(list, showArrow)
  }

  function getList(list, showArrow: boolean) {
    return (
      <S.List>
        {list.map((item, index) => (
          <S.ItemList
            className="itemCategoriaMenu"
            key={index}
            onClick={(e) =>
              handleClick(e, item.slug, item.name, !!item.countChildren)
            }
            href={getItemLink(item.slug)}
          >
            {item.name}
            {showArrow && item.countChildren > 0 && (
              <IconCollapseHeader
                width={ThemeDefault.spacing.xs}
                height={ThemeDefault.spacing.xs}
                color={ThemeDefault.colors.black(700)}
              />
            )}
          </S.ItemList>
        ))}
      </S.List>
    )
  }

  function renderBanner() {
    const banner = bannerData?.departamentos?.[0]

    if (isLoading)
      return (
        <S.BannerSkeleton>
          <Skeleton height={112} count={1} />
        </S.BannerSkeleton>
      )

    if (!banner) return null

    return (
      <S.Redirect
        ref={bannerRef}
        onClick={dataLayerBannerClick}
        href={banner?.destinationUrl}
        target="_blank"
        rel="noreferrer"
      >
        <img src={banner?.mediaUrl} alt={banner?.alt} />
      </S.Redirect>
    )
  }

  function handleClick(
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    slug: string,
    name: string,
    hasChildren: boolean
  ) {
    const shouldEnterMenu = !hasCategories && hasChildren
    if (shouldEnterMenu) {
      e.preventDefault()
      if (hasSections) handleEnterSection(slug, name)
      else handleEnterDepartment(slug, name)
    }
  }

  return (
    <S.Wrapper>
      <S.Header>
        <button onClick={handleBackMenu}>
          <IconCollapseHeader
            width={ThemeDefault.spacing.xs}
            height={ThemeDefault.spacing.xs}
            color={ThemeDefault.colors.secondary(500)}
          />
        </button>
        <S.Title>{titleMenu}</S.Title>
        {linkMenu && <S.MenuLink href={linkMenu}>Ver tudo</S.MenuLink>}
      </S.Header>
      <S.Content>{getContent()}</S.Content>
      {renderBanner()}
    </S.Wrapper>
  )
}
