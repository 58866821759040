import styled from 'styled-components'

export const Prices = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-content: center;
  padding: ${({ theme }) => theme.spacing.sm} 0;
`

export const Price = styled.span<{ color: string }>`
  color: ${({ color, theme }) => color || theme.colors.black(550)};
  ${({ theme }) => theme.font(20, 30, 700)};
`
