import React from 'react'

import { NewtailBannerData } from '@k_frontend/core'

import { DepartmentsMenuBaseProps } from '../DepartmentsMenu.types'

export interface MobileDepartmentsMenuProps extends DepartmentsMenuBaseProps {
  handleBackMenu: () => void
  currentMenuId: number
  getBannersByMenu: (menu: number) => any
  isLoading?: boolean
  bannerData?: NewtailBannerData
  dataLayerBannerClick?: () => void
  bannerRef?: React.RefObject<HTMLAnchorElement>
}

export enum StepsEnum {
  DEPARTMENTS = 'departments',
  SECTIONS = 'sections',
  CATEGORIES = 'categories'
}
