import { LocalSellerGatewayProtocol } from 'data/protocols/localSellerGatewayProtocol'
import { LoadSellerUseCaseDomain } from 'domain/usecases/loadSeller'

export class LoadSellerUseCase implements LoadSellerUseCaseDomain {
  private readonly localSellerGateway: LocalSellerGatewayProtocol

  constructor(localSellerGateway: LocalSellerGatewayProtocol) {
    this.localSellerGateway = localSellerGateway
  }

  load() {
    return this.localSellerGateway.parseSeller()
  }
}
