import styled, { css } from 'styled-components'

import { PriceStyleProps } from './Prices.types'

export const Prices = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.xxs} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}){
    padding: ${({ theme }) => theme.spacing.xxs} 0;
  }
`

export const OldPrice = styled.span`
  ${({ theme }) => theme.font(12, 22, 400)};
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.rem(12)};
  color: ${({ theme }) => theme.colors.black(700)};
  text-decoration: line-through;
`

export const Price = styled.span<PriceStyleProps>`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.font(14, 30, 700)};
  height: ${({ theme }) => theme.rem(24)};
  text-decoration: ${({ isClosedOffer, outOfStock }) =>
    isClosedOffer || outOfStock ? 'line-through' : 'none'};

  ${({
    available,
    isPrime,
    theme,
    outOfStock,
    isClosedOffer,
    isBlackLegendary
  }) => {
    if ((available && !isClosedOffer) || outOfStock) {
      return css`
        color: ${isPrime || isBlackLegendary ? theme.colors.black(900) : theme.colors.secondary(500)};
      `
    } else {
      return css`
        color: ${theme.colors.black(550)};
      `
    }
  }}

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}){
    ${({ theme }) => theme.font(20, 30, 700)};
    height: ${({ theme }) => theme.rem(28)};
    margin-top: ${({ theme }) => theme.spacing.xxxs};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktopLarge}){
    ${({ theme }) => theme.font(20, 30, 700)};
  }
`

export const SkeletonPricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxs};
  padding: ${({ theme }) => theme.spacing.xxs} 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    gap: ${({ theme }) => theme.spacing.xxs};
  }

  > span {
    display: flex;
  }
`
