import { Storage } from '@k_frontend/core'
import { LocalFeatureFlagGatewayProtocol } from 'data/protocols/localFeatureFlagGatewayProtocol'
import { KABUM_COOKIE_URL } from 'settings/kernel'

export class LocalFeatureFlagGateway
  implements LocalFeatureFlagGatewayProtocol
{
  private readonly storageClient: Storage

  constructor(storageClient: Storage) {
    this.storageClient = storageClient
  }

  load(campaignKey: string): string | undefined {
    return this.storageClient.get({
      name: campaignKey,
      options: {
        domain: KABUM_COOKIE_URL
      }
    })
  }

  save(campaignKey: string, value: boolean): void {
    this.storageClient.set({
      name: campaignKey,
      value: String(value),
      options: {
        domain: KABUM_COOKIE_URL,
        maxAge: 300
      }
    })
  }
}
