const { generateShippingStars } = require('../utils/paymentOptions')
const self = {}

self.confirmationRequest = (data) => {
  const creditCardData = {
    nome: data.cardName,
    numero: data.cardNumber,
    validade: `${data.cardMonth}/${data.cardYear}`,
    codigo_seguranca: data.cardCVV || '',
    cpf: data.cardDocument,
    data_nascimento: `${data.cardBirthDay}/${data.cardBirthMonth}/${data.cardBirthYear}`
  }

  const paypalData = {
    payment_id: data.paypalPaymentId,
    payer_id: data.paypalPayerId
  }

  return {
    loja: '001',
    session: data.session,
    id_cliente: data.clientId,
    cupom: data.cupom,
    pagamento: {
      codigo: data.paymentOption,
      dados: creditCardData.nome ? creditCardData : {},
      paypal: paypalData.payment_id ? paypalData : {}
    },
    origem: data.origin || ''
  }
}

self.creditCardBody = (data) => {
  const {
    cardName,
    cardNumber,
    cardCVV,
    cardDocument,
    paymentOption,
    cardValidity,
    cardBirth
  } = data

  const cardValidityArray = cardValidity.split('/')
  const cardBirthArray = cardBirth.split('/')
  const cardMonth = cardValidityArray[0]
  const cardYear = cardValidityArray[1]
  const cardBirthDay = cardBirthArray[0]
  const cardBirthMonth = cardBirthArray[1]
  const cardBirthYear = cardBirthArray[2]

  return {
    cardName,
    cardNumber,
    cardCVV,
    cardDocument,
    paymentOption,
    cardMonth,
    cardYear,
    cardBirthDay,
    cardBirthMonth,
    cardBirthYear
  }
}

self.confirmationResponse = (data, coupon) => {
  const { sellers, customer, address, payment, totals, donation } = data

  const formattedSellers = sellers.map((seller) => ({
    id: seller.id,
    name: seller.name,
    origins: seller.origins.map((origin) => ({
      id: origin.id,
      shipping: origin.shipping.map((shippingOption) => ({
        id: shippingOption.id,
        name: shippingOption.name,
        price: shippingOption.price,
        rate: shippingOption.rate,
        stars: generateShippingStars(shippingOption.rate),
        fromDate: shippingOption.from_date || '',
        toDate: shippingOption.to_date || '',
        selected: shippingOption.is_selected || false,
        date: shippingOption.shipping_date || '',
        period: shippingOption.shipping_period || ''
      })),
      products: origin.products.map((product) => ({
        id: product.id,
        code: product.code,
        tagCode: product.tag_code || '',
        offerId: product.offer_id || '',
        name: product.name,
        image: product.image,
        link: product.link,
        manufacturer: product.manufacture,
        category: product.category,
        isOpenbox: product.is_openbox || false,
        isPrime: product.is_prime || false,
        isOffer: product.is_offer || false,
        expiredOffer: product.is_expired_offer || false,
        gift: {
          code: product.gift.code || '',
          name: product.gift.name || '',
          image: product.gift.image || '',
          link: product.gift.link || ''
        },
        services: {
          warranty:
            product.services
              ?.filter((item) => item.code === 'garantia')
              .map((service) => {
                return service.options
                  .filter((it) => it.selected)
                  .map((option) => ({
                    code: option.code,
                    companyCode: option.warranty_code,
                    description: option.description,
                    companyName: option.warranty_name,
                    price: option.price
                  }))
              })[0] ?? [],
          insurance:
            product.services
              ?.filter((item) => item.code === 'seguro')
              .map((service) => {
                return service.options
                  .filter((it) => it.selected)
                  .map((option) => ({
                    code: option.code,
                    companyCode: option.warranty_code,
                    description: option.description,
                    companyName: option.warranty_name,
                    price: option.price
                  }))
              })[0] ?? []
        },
        discount: product.discount || 0,
        couponDiscount: product.coupon_discount || 0,
        maxQuantity: product.max_quantity,
        quantity: product.quantity,
        coupon: product.coupon_discount > 0 ? coupon : '',
        unitForwardPrice: product.unit_forward_price,
        totalForwardPrice: product.total_forward_price,
        unitCashPrice: product.unit_cash_price,
        totalCashPrice: product.total_cash_price
      }))
    }))
  }))

  const formattedDonation =
    Object.keys(donation).length === 0 && donation.constructor === Object
      ? {}
      : {
          code: donation.code,
          logo: donation.logo,
          description: donation.description,
          cnpj: donation.cnpj,
          im: donation.im,
          values: donation.values.map((item) => ({
            code: item.code,
            value: item.value
          }))
        }

  return {
    sellers: formattedSellers,
    client: {
      type: customer.type,
      name: customer.name,
      cpf: customer.cpf,
      rg: customer.rg,
      phone: customer.phone_number,
      cellphone: customer.cell_number,
      mail: customer.email,
      address: {
        street: address.street_name,
        number: address.number,
        zipCode: address.zip_code,
        complement: address.complement,
        district: address.neighborhood,
        city: address.city,
        state: address.state
      }
    },
    payment: {
      code: payment.code,
      method: payment.method,
      hash: payment.hash,
      installmentMonths: payment.parcels,
      installmentValues: payment.parcel_value,
      totalValue: totals.cash_price,
      cashDiscount: totals.cash_save || 0
    },
    donation: formattedDonation,
    prices: {
      shipping: totals.shipping,
      couponDiscount: totals.coupon_discount || 0,
      credit: totals.credit,
      subTotal: totals.subtotal_value,
      total: totals.cash_price,
      services: totals.services,
      cashDiscount: totals.cash_save
    }
  }
}

module.exports = self
