import theme from 'theme'

import * as S from './LineLoader.styles'
import { LineLoaderProps } from './LineLoader.types'

export default function LineLoader({
  size = 36,
  color = theme.colors.white(500, '55'),
  thickness = 4
}: Readonly<LineLoaderProps>) {
  return <S.LineLoader thickness={thickness} size={size} color={color} />
}
