import styled from 'styled-components'

import { EllipsisProps } from './types'

export const Ellipsis = styled.span<Partial<EllipsisProps>>`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lines};
  -webkit-box-orient: vertical;
  word-break: break-word;
  max-height: ${(props) =>
    props.heightByContent ? 'auto' : props.theme.rem(props.height)};
`
