import TopBanner from 'components/TopBanner'

import { useTopBannerContainer } from './topBanner.hook'
import { TopBannerContainerProps } from './topBanner.types'

export default function TopBannerContainer({
  isOpen,
  setIsOpen,
  isPrime,
  hasClosed
}: Readonly<TopBannerContainerProps>) {
  const {
    banner,
    onCloseBanner,
    onBannerClick,
    bannerRef,
    hasStorageBannerClosedAndNotOpen,
    hasImgAndIsOpen
  } = useTopBannerContainer({
    hasClosed,
    setIsOpen,
    isOpen
  })

  if (hasStorageBannerClosedAndNotOpen) return null

  return (
    <TopBanner
      isPrime={isPrime}
      bannerRef={bannerRef}
      hasImgAndIsOpen={hasImgAndIsOpen}
      banner={banner}
      onCloseBanner={onCloseBanner}
      onBannerClick={onBannerClick}
    />
  )
}
