export const TAKEAWAY_STORAGE_NAME = 'kbm_takeaway'

export const TAKEAWAY_PERSISTED_VALUES: string[] = [
  'isTakeAwayBySomeoneElse',
  'takeBySomeoneCPF',
  'takeBySomeoneName',
  'pointToPickUp'
]

export const INITIAL_STATE = {
  buttonAbleToUse: false,
  orderBy: 'shorterDistance',
  searchBy: '',
  storePoints: { shorterDistance: [], shorterTerm: [] },
  pointToPickUp: null,
  takenBySomeone: false,
  takeBySomeoneCPF: null,
  takeBySomeoneName: null,
  isTakeAwayBySomeoneElse: false,
  errorsForm: {
    nome: false,
    cpf: false
  }
}
