import { useHeaderContext } from 'components/Header/Header.context'
import { useAccountMenuDrawer } from 'components/Header/ProfileInfo/AccountMenuDrawer'
import useMediaQuery from 'hooks/useMediaQuery/useMediaQuery'
import { useCallback, useEffect, useState } from 'react'

export function useHeaderContainer() {
  const { isPrime, cartProductsQuantity, DesktopLogo, wishlist } =
    useHeaderContext()
  const { isHover, setIsHover } = useAccountMenuDrawer()
  const [isViewFloatingMenu, setIsViewFloatingMenu] = useState(false)
  const isMobile = useMediaQuery('(max-width: 1023px)')

  const onCloseAccountMenu = useCallback(() => {
    if (isHover) {
      setIsHover(false)
    }
  }, [isHover])

  const getViewFloatingMenu = useCallback(() => {
    let tabsComponent = document.getElementById('header-container')
    let positionTabs = tabsComponent?.getBoundingClientRect()

    if (positionTabs) setViewsFloating(positionTabs)

    document.addEventListener('scroll', () => {
      tabsComponent = document.getElementById('header-container')
      positionTabs = tabsComponent?.getBoundingClientRect()
      setViewsFloating(positionTabs)
    })
  }, [])

  function setViewsFloating(positionTabs: DOMRect) {
    if (positionTabs.bottom > 0) {
      setIsViewFloatingMenu(false)
    } else {
      setIsViewFloatingMenu(true)
    }
  }

  useEffect(() => {
    getViewFloatingMenu()
  }, [getViewFloatingMenu])

  return {
    isPrime,
    cartProductsQuantity,
    DesktopLogo,
    wishlist,
    onCloseAccountMenu,
    isViewFloatingMenu,
    isMobile,
    isHover,
    setIsHover
  }
}
