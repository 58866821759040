const IconComputer = ({ color = '#ff6500', width = '16', height = '16' }) => (
  <svg
    width={width}
    height={height}
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.39854 12.8487C4.52631 12.9765 4.68465 13.0404 4.87354 13.0404H7.5402C7.72909 13.0404 7.88742 12.9765 8.0152 12.8487C8.14298 12.7209 8.20687 12.5626 8.20687 12.3737C8.20687 12.1848 8.14298 12.0265 8.0152 11.8987C7.88742 11.7709 7.72909 11.707 7.5402 11.707H6.87354V11.0404H7.5402C7.72909 11.0404 7.88742 10.9765 8.0152 10.8487C8.14298 10.7209 8.20687 10.5626 8.20687 10.3737C8.20687 10.1848 8.14298 10.0265 8.0152 9.8987C7.88742 9.77092 7.72909 9.70703 7.5402 9.70703H2.20687V3.04036H11.5402V3.0737C11.5402 3.26259 11.6041 3.42092 11.7319 3.5487C11.8596 3.67648 12.018 3.74036 12.2069 3.74036C12.3958 3.74036 12.5541 3.67648 12.6819 3.5487C12.8096 3.42092 12.8735 3.26259 12.8735 3.0737V3.04036C12.8735 2.6737 12.743 2.35981 12.4819 2.0987C12.2208 1.83759 11.9069 1.70703 11.5402 1.70703H2.20687C1.8402 1.70703 1.52631 1.83759 1.2652 2.0987C1.00409 2.35981 0.873535 2.6737 0.873535 3.04036V9.70703C0.873535 10.0737 1.00409 10.3876 1.2652 10.6487C1.52631 10.9098 1.8402 11.0404 2.20687 11.0404H5.5402V11.707H4.87354C4.68465 11.707 4.52631 11.7709 4.39854 11.8987C4.27076 12.0265 4.20687 12.1848 4.20687 12.3737C4.20687 12.5626 4.27076 12.7209 4.39854 12.8487Z'
      fill={color}
    />
    <path
      d='M11.5402 11.1237C11.218 11.1237 10.9569 11.3849 10.9569 11.707C10.9569 12.0292 11.218 12.2904 11.5402 12.2904H12.8735C13.1957 12.2904 13.4569 12.0292 13.4569 11.707C13.4569 11.3849 13.1957 11.1237 12.8735 11.1237H11.5402Z'
      fill={color}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.2902 6.3737C9.2902 5.31515 10.1483 4.45703 11.2069 4.45703H13.2069C14.2654 4.45703 15.1235 5.31515 15.1235 6.3737V12.3737C15.1235 13.4322 14.2654 14.2904 13.2069 14.2904H11.2069C10.1483 14.2904 9.2902 13.4322 9.2902 12.3737V6.3737ZM11.2069 5.6237C10.7927 5.6237 10.4569 5.95948 10.4569 6.3737V12.3737C10.4569 12.7879 10.7927 13.1237 11.2069 13.1237H13.2069C13.6211 13.1237 13.9569 12.7879 13.9569 12.3737V6.3737C13.9569 5.95948 13.6211 5.6237 13.2069 5.6237H11.2069Z'
      fill={color}
    />
  </svg>
)

export { IconComputer as default }
