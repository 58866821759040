import { IconCloseBanner } from '@k_frontend/icons'

import * as S from './TopBanner.styles'
import { TopBannerProps } from './TopBanner.types'

export default function TopBanner({
  isPrime,
  bannerRef,
  banner,
  onCloseBanner,
  onBannerClick,
  hasImgAndIsOpen
}: Readonly<TopBannerProps>) {
  return (
    <S.Placeholder isPrime={isPrime}>
      {hasImgAndIsOpen ? (
        <S.Container
          ref={bannerRef}
          onClick={() => onBannerClick(banner?.clickUrl)}
        >
          <S.Redirect href={banner?.destinationUrl}>
            <img src={banner?.mediaUrl} alt={banner?.alt} />
          </S.Redirect>
          <S.CloseButton onClick={onCloseBanner} aria-label="Fechar banner">
            <IconCloseBanner aria-hidden />
          </S.CloseButton>
        </S.Container>
      ) : null}
    </S.Placeholder>
  )
}
