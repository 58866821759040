import { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'

import Countdown from 'components/Countdown'
import IconTimer from 'components/Icons/IconTimer'
import LineLoader from 'components/Loaders/LineLoader'
import { makeRemoteLoadTimeStampFactory } from 'factories'
import { useOffer } from 'hooks/useOffer'
import { useTimestamp } from 'hooks/useTimestamp'
import { getRemainingTime, isTimeAfter } from 'utils/timestamp'

import * as S from './OfferTimer.styles'
import { OfferTimerProps } from './OfferTimer.types'

export default function OfferTimer({
  config = {},
  offerInfo,
  showIcon = true,
  offerCode
}: OfferTimerProps) {
  const queryClient = useQueryClient()
  const { loading, timestamp, setTimestamp } = useTimestamp()
  const [dynamicCountdown, setDynamicCountdown] = useState<number>(
    handleRemainingTime()
  )
  const { removeOfferList } = useOffer()

  async function fetchTimestamp() {
    return await queryClient.fetchQuery({
      queryKey: ['getTimeStamp'],
      queryFn: () => makeRemoteLoadTimeStampFactory().load()
    })
  }

  async function changeCountdown() {
    const dataTimestamp = await fetchTimestamp()
    setTimestamp(dataTimestamp)
    setDynamicCountdown(handleRemainingTime(dataTimestamp))

    if (dataTimestamp >= offerInfo?.endDate && offerCode) {
      setTimeout(() => {
        removeOfferList(offerCode)
      }, 5000)
    }
  }

  function handleRemainingTime(newTimestamp?: number) {
    if (timestamp) {
      const timestampUpdated = newTimestamp || timestamp
      const offerStarted = isTimeAfter(timestampUpdated, offerInfo?.startDate)

      if (offerStarted) {
        return getRemainingTime(Number(offerInfo?.endDate), timestamp)
      } else {
        return getRemainingTime(Number(offerInfo?.startDate), timestamp)
      }
    } else {
      return null
    }
  }

  useEffect(() => {
    if (dynamicCountdown <= 0 && !!timestamp) {
      changeCountdown()
      return
    }

    const interval = setInterval(() => {
      setDynamicCountdown(dynamicCountdown - 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [dynamicCountdown])

  useEffect(() => {
    setDynamicCountdown(handleRemainingTime())
  }, [timestamp])

  if (!timestamp || loading) {
    return (
      <S.LoaderContainer>
        <LineLoader size={16} thickness={2} />
      </S.LoaderContainer>
    )
  }

  return (
    <S.Container config={config} size={24}>
      {showIcon && (
        <IconTimer fill={config?.text?.textColor} height={24} aria-hidden />
      )}

      <Countdown countdown={dynamicCountdown} />
    </S.Container>
  )
}
