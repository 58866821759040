import { CountdownProps } from './Countdown.types'

function Countdown({
  countdown,
  className = 'countdownOffer'
}: CountdownProps) {
  const DAY_SECONDS = 86400
  const HOUR_SECONDS = 3600
  const MINUTE_SECONDS = 60

  const days = Math.floor(countdown / DAY_SECONDS)
  const daysMod = countdown % DAY_SECONDS
  const hours = Math.floor(daysMod / HOUR_SECONDS)
  const hoursMod = daysMod % HOUR_SECONDS
  const minutes = Math.floor(hoursMod / MINUTE_SECONDS)
  const seconds = Math.floor(hoursMod % MINUTE_SECONDS)

  function zeroFill(x: number) {
    if (x < 10) {
      return `0${x}`
    }

    return `${x}`
  }

  function getDayLabel() {
    return `${zeroFill(days) !== '00' ? zeroFill(days) + 'D' : ''}`
  }

  function renderTimer() {
    return `
      ${getDayLabel()}
      ${zeroFill(hours)} :
      ${zeroFill(minutes)} :
      ${zeroFill(seconds)}
    `
  }

  if (countdown <= 0) {
    return <span>00:00:00</span>
  }

  return <span className={className}>{renderTimer()}</span>
}

export default Countdown
