import { setCookie, getCookie } from 'cookies-next'
import { FeatureFlag } from 'utils/featureFlag/featureFlag'

export async function setSearchVariation(session: string) {
  const init = await FeatureFlag.getInstance()
  const searchVariation = await init.testeAb('buscaSmarthintv2', session)
  setCookie('searchVariation', searchVariation, {
    maxAge: 600
  })
}

export function getSearchVariation() {
  return getCookie('searchVariation') as string
}

export function hasSmartHintSearchRequestError(apiResponseVariant: string) {
  return (
    getSearchVariation() === 'smarthint' && apiResponseVariant !== 'smarthint'
  )
}

export function getDataLayerSearchVariant(apiResponseVariant: string) {
  if (
    hasSmartHintSearchRequestError(apiResponseVariant) ||
    !getSearchVariation()
  ) {
    return 'catalog'
  }

  return getSearchVariation()
}
