import { CartServiceDTO } from 'domain/dto/cartServiceDTO.types'

export class CartService {
  code: string
  name: string
  option: CartServiceDTO.Option[]
  hasServiceSelected: boolean

  constructor({ code, name, option }: CartServiceDTO.Props) {
    this.code = code
    this.name = name
    this.option = option
    this.hasServiceSelected = Boolean(
      option.find((serviceOption) => serviceOption.selected)
    )
  }

  removeService() {
    this.option.forEach((option) => (option.selected = false))
    this.hasServiceSelected = false
  }

  setSelectedOption(optionCode: number) {
    const currentSelectedOption = this.getSelectedOption()

    if (currentSelectedOption) {
      this.removeService()
    }

    this.option.forEach((serviceOption) => {
      if (serviceOption.code === optionCode) {
        serviceOption.selected = true
        this.hasServiceSelected = true
      }
    })
  }

  getSelectedOption(): CartServiceDTO.Option {
    return this.option.find((serviceOption) => serviceOption.selected)
  }

  stringifySelectedOption(
    sellerId: string,
    originId: string,
    productCode: number
  ): string {
    const currentOption = this.getSelectedOption()

    if (!currentOption) return ''

    return `${sellerId}|${originId}|${productCode}|${this.code}|${currentOption.code}`
  }
}
