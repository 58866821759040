import { discount } from './DiscountPercentage.styles'
import { DiscountPercentageProps } from './DiscountPercentage.types'

export function DiscountPercentage({
  offerDiscount,
  isPrime,
  isClosedOffer,
  isBlackLegendary
}: DiscountPercentageProps) {
  return (
    <div
      className={discount({
        isPrime: isPrime || isBlackLegendary,
        isClosedOffer
      })}
    >
      -{offerDiscount}%
    </div>
  )
}
