import { makeAxiosHttpClient } from '@k_frontend/core'
import { RemoteLoadTimeStamp } from 'data/usecases'
import { KABUM_COMPLETE_URL } from 'settings/kernel'

export function makeRemoteLoadTimeStampFactory(): RemoteLoadTimeStamp {
  return new RemoteLoadTimeStamp(
    `${KABUM_COMPLETE_URL}/time.stamp`,
    makeAxiosHttpClient()
  )
}
