import { HttpClient, UnexpectedError } from '@k_frontend/core'
import { LoadTimeStamp } from 'domain/usecases'

export class RemoteLoadTimeStamp implements LoadTimeStamp {
  private readonly url: string
  private readonly httpClient: HttpClient<number>

  constructor(url: string, httpClient: HttpClient<number>) {
    this.httpClient = httpClient
    this.url = url
  }

  async load(): Promise<number> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get'
    })

    switch (httpResponse.statusCode) {
      case HttpClient.StatusCode.ok:
        return httpResponse.body
      default:
        throw new UnexpectedError()
    }
  }
}
