import { Storage } from '@k_frontend/core'
import { LocalStoreCodeGatewayProtocol } from 'data/protocols/localStoreCodeGatewayProtocol'
import { DESKTOP_STORE, KABUM_COOKIE_URL } from 'settings/kernel'

export class LocalStoreCodeGateway implements LocalStoreCodeGatewayProtocol {
  private readonly storageClient: Storage

  constructor(storageClient: Storage) {
    this.storageClient = storageClient
  }

  load() {
    return (
      this.storageClient.get({
        name: 'storeCode',
        options: {
          domain: KABUM_COOKIE_URL
        }
      }) || DESKTOP_STORE
    )
  }
}
