import { makeNextCookiesClient } from '@k_frontend/core'
import { LocalSellerGateway } from 'infra/gateway/seller/localSeller'

import { makeLocalServicesGateway } from './makeLocalServicesGateway'

export function makeLocalSellerGateway() {
  return new LocalSellerGateway(
    makeNextCookiesClient(),
    makeLocalServicesGateway()
  )
}
