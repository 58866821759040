import styled, { css } from 'styled-components'

type ButtonStyleProps = {
  isPrime: boolean
  $loading: boolean
  isBlackLegendary?: boolean
}

export const Button = styled.button<ButtonStyleProps>`
  ${(props) => props.theme.font(12, 18, 700)};

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  color: #fff;
  cursor: ${(props) => (props.$loading ? 'wait' : 'pointer')};
  background: ${(props) =>
    props.isPrime ? props.theme.colors.black() : props.theme.colors.secondary()};

  > svg,
  > div {
    margin-right: ${(props) => props.theme.spacing.xxs};
  }

  > svg {
    > path {
      fill: ${(props) =>
        props.isPrime ? props.theme.colors.secondary() : '#fff'};
    }
  }

  ${({ isBlackLegendary }) => {
    if (isBlackLegendary) {
      return css`
         background: ${({ theme }) => theme.colors.black(900)};
         background: linear-gradient(46.73deg, #101010 -22.68%, #1D1D1D 68.08%, #A1A1A1 122.53%);
      `
    }
  }}

  &:disabled {
    cursor: ${(props) => (props.$loading ? 'wait' : 'not-allowed')};
    background: ${({ theme }) => theme.colors.black(550)};
  }
`
