import { useQuery } from 'react-query'

import {
  NewtailBannerData,
  getNewTailBannerAds,
  useBannerSize,
  useUser
} from '@k_frontend/core'
import kernel from 'settings/kernel'

import { formatCategoryName } from 'utils/formatCategoryName'
import {
  MonetizaDepartmentsProps,
  MonetizaPdpProps
} from './useBannerMonetiza.types'

export function useBannerMonetizaDepartments({
  departmentName,
  sectionName,
  placementType,
  categoryName,
  subcategoryName,
  menuId
}: MonetizaDepartmentsProps) {
  const { session } = useUser()

  const bannerSizes = {
    departamentos: useBannerSize({
      mobile: 'department_mobile',
      tablet: 'department_tablet',
      desktop: 'department_default',
      desktopLarge: 'department_default'
    }),
    listagem: useBannerSize({
      mobile: 'listagem_mobile',
      tablet: 'listagem_tablet',
      desktop: 'listagem_desktop',
      desktopLarge: 'listagem_desktop'
    })
  }

  const bannerSize = bannerSizes[placementType]

  return useQuery<NewtailBannerData>(
    ['getDeparmentsBanner', bannerSize, menuId],
    () =>
      getNewTailBannerAds(kernel, {
        session_id: session,
        menu_id: menuId,
        origin:
          bannerSize === 'department_mobile' || bannerSize === 'listagem_mobile'
            ? 'mobile'
            : 'desktop',
        context: 'category',
        category_name: formatCategoryName(
          departmentName,
          sectionName,
          categoryName,
          subcategoryName
        ),
        placements: {
          [placementType]: {
            quantity: 1,
            size: bannerSize,
            types: ['banner']
          }
        }
      }),
    {
      enabled: !!session,
      staleTime: 1000 * 60 * 10
    }
  )
}

export function useBannerMonetizaSplashP() {
  const { session } = useUser()

  const bannerSize = useBannerSize({
    mobile: 'splash_p_mobile',
    tablet: 'splash_p_tablet',
    desktop: 'splash_p_desktop',
    desktopLarge: 'splash_p_desktop'
  })

  return useQuery<NewtailBannerData>(
    ['getBannerSplashP', bannerSize],
    () =>
      getNewTailBannerAds(kernel, {
        session_id: session,
        origin: bannerSize === 'splash_p_mobile' ? 'mobile' : 'desktop',
        context: 'category',
        category_name: 'SPLASH_P',
        placements: {
          splash_p: {
            quantity: 2,
            size: bannerSize,
            types: ['banner']
          }
        }
      }),
    {
      enabled: !!session,
      staleTime: 1000 * 60 * 10
    }
  )
}

export function useBannerMonetizaSplashG() {
  const { session } = useUser()

  const bannerSize = useBannerSize({
    mobile: 'splash_g_mobile',
    tablet: 'splash_g_tablet',
    desktop: 'splash_g_desktop',
    desktopLarge: 'splash_g_desktop'
  })

  return useQuery<NewtailBannerData>(
    ['getBannerSplashG', bannerSize],
    () =>
      getNewTailBannerAds(kernel, {
        session_id: session,
        origin: bannerSize === 'splash_g_mobile' ? 'mobile' : 'desktop',
        context: 'category',
        category_name: 'SPLASH_G',
        placements: {
          splash_g: {
            quantity: 1,
            size: bannerSize,
            types: ['banner']
          }
        }
      }),
    {
      enabled: !!session,
      staleTime: 1000 * 60 * 10
    }
  )
}

export function useBannerMonetizaPdp({
  productCode,
  departmentName,
  sectionName,
  categoryName,
  subCategoryName,
  brandName
}: MonetizaPdpProps) {
  const { session } = useUser()

  const bannerSize = useBannerSize({
    mobile: 'pdp_mobile',
    tablet: 'pdp_tablet',
    desktop: 'pdp_desktop',
    desktopLarge: 'pdp_desktop_large'
  })

  return useQuery<NewtailBannerData>(
    ['getBannerPdp', bannerSize],
    () =>
      getNewTailBannerAds(kernel, {
        session_id: session,
        origin: bannerSize === 'pdp_mobile' ? 'mobile' : 'desktop',
        context: 'product_page',
        product_attributes: {
          category_name: formatCategoryName(
            departmentName,
            sectionName,
            categoryName,
            subCategoryName
          ),
          brand: brandName
        },
        product_sku: productCode,
        placements: {
          pdp: {
            quantity: 1,
            size: bannerSize,
            types: ['banner']
          }
        }
      }),
    {
      enabled: !!session,
      staleTime: 1000 * 60 * 10
    }
  )
}
