import axios from 'axios'
import CookiesInterface from 'interfaces/cookies'

export async function Logout() {
  const ClassCookiesInterface = new CookiesInterface()
  const signOutData = {
    id_cliente: ClassCookiesInterface.getClientId(),
    session: ClassCookiesInterface.getSession()
  }

  await axios.post('/api/core/logout', signOutData)
}
