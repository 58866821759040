import {
  AnimatePresence,
  LazyMotion,
  Variants,
  domAnimation,
  m
} from 'framer-motion'
import { JSX, useEffect } from 'react'

import { IconCloseModal } from '@k_frontend/icons'
import * as Dialog from '@radix-ui/react-dialog'
import KLoader from 'components/Loaders/KLoader'
import { useMiniCart } from 'hooks/useMiniCart/useMiniCart'
import { useShoppingCart } from 'hooks/useShoppingCart/useShoppingCart'
import { tailwindTheme } from 'theme/tailwindTheme'

import { Button } from '@kbm/button'
import EmptyCart from './EmptyCart/EmptyCart'
import { contentStyle } from './MiniCart.styles'
import Seller from './Selller/Seller'
import Total from './Total/Total'

const animationVariants: Variants = {
  open: {
    width: '460px',
    height: '100vh',
    top: '0',
    padding: '16px',
    left: 'calc(100% - 460px)',
    transition: { type: 'spring', stiffness: 80, damping: 15 }
  },
  close: (triggerPosition: DOMRect) => ({
    width: 0,
    height: 0,
    padding: 0,
    top: triggerPosition?.top + triggerPosition.height / 2 || 0,
    left: triggerPosition?.left + triggerPosition.width / 2 || '100%',
    transition: { type: 'tween', duration: 0.3 }
  })
}

function MiniCart() {
  const { isOpen, toggleIsOpen, triggerPosition } = useMiniCart()
  const { loadCart, cart, isLoading } = useShoppingCart()

  const { loading, content, products, total } = contentStyle({ isLoading })

  useEffect(() => {
    if (isOpen) loadCart()
  }, [isOpen])

  let contentJSX: JSX.Element = null

  if (cart)
    contentJSX = (
      <div className={content({ isLoading: false })}>
        <div className={products()}>
          {cart.sellers.map((seller) => (
            <Seller key={seller.id} seller={seller} />
          ))}
        </div>
        <div className={total()}>
          <Total totals={cart?.totals} />
        </div>
        <div className='mt-auto flex flex-col gap-8'>
          <a
            href='/carrinho'
            className='w-full rounded-4 bg-secondary hover:bg-secondary-600 px-16 h-40 flex items-center justify-center text-sm font-bold uppercase text-white'
          >
            Ir para o carrinho
          </a>
          <Dialog.Close asChild>
            <Button variant={{ size: '40', type: 'outlined', full: true }}>
              Continuar comprando
            </Button>
          </Dialog.Close>
        </div>
      </div>
    )
  else if ((!cart && !isLoading) || cart?.sellers.length === 0) {
    contentJSX = <EmptyCart />
  }

  return (
    <LazyMotion features={domAnimation}>
      <Dialog.Root open={isOpen} onOpenChange={toggleIsOpen}>
        <Dialog.Overlay className='fixed inset-0 z-[9999] bg-black-950/60' />
        <AnimatePresence>
          {isOpen && (
            <Dialog.Content forceMount asChild>
              <m.div
                key='minicart'
                variants={animationVariants}
                custom={triggerPosition}
                initial='close'
                animate='open'
                exit='close'
                className='fixed overflow-hidden inset-y-0 right-0 z-[9999] grid w-[460px] grid-rows-[auto_1fr] rounded-2 bg-white '
              >
                <Dialog.Title className='flex items-center gap-8'>
                  <Dialog.Close className='size-24'>
                    <IconCloseModal
                      color={tailwindTheme.colors.black['800']}
                      width={16}
                      height={16}
                      aria-hidden
                    />
                  </Dialog.Close>
                  <span className='text-xl whitespace-nowrap overflow-hidden font-bold uppercase text-secondary'>
                    Meu carrinho
                  </span>
                </Dialog.Title>
                <div className={loading()}>
                  <KLoader />
                </div>
                {contentJSX}
              </m.div>
            </Dialog.Content>
          )}
        </AnimatePresence>
      </Dialog.Root>
    </LazyMotion>
  )
}

export default MiniCart
