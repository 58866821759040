import styled, { StyledProps, css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white(500)};
  left: 0;
  top: 0;
  position: absolute;
  z-index: 999;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  border-top-left-radius: 0;
`

export const Column = styled.div<StyledProps<{ bannerColumn?: boolean }>>`
  width: ${({ theme }) => theme.rem(262)};
  height: ${({ theme }) => theme.rem(515)};

  ${(props) =>
    props.bannerColumn &&
    css`
      width: ${({ theme }) => theme.rem(362)};
    `}
`

export const Redirect = styled.a`
  display: flex;
  cursor: pointer;
`

export const BannerImage = styled.img`
  width: ${({ theme }) => theme.rem(362)};
  height: ${({ theme }) => theme.rem(515)};
  padding: ${({ theme }) => theme.rem(16)};
  object-fit: cover;
  cursor: pointer;
`

export const BannerSkeleton = styled.div`
  width: ${({ theme }) => theme.rem(362)};
  padding: ${({ theme }) => theme.rem(16)};
`

export const RedirectLink = styled.a`
  ${({ theme }) => theme.font(14, 18, 600)}
  display: flex;
  color: ${({ theme }) => theme.colors.secondary(500)};
  text-decoration: underline;
  padding: ${({ theme }) => theme.spacing.xxs}
    ${({ theme }) => theme.spacing.xs};
`
