const self = {}

self.SERVICE_NAME = 'prd-ecommerce-frontend-desktop'

self.ENVIRONMENT = 'prd'

self.ENDPOINT_PRI = 'https://services.prod.api.aws.grupokabum.com.br/'
self.ENDPOINT_PUB = 'https://servicespub.prod.api.aws.grupokabum.com.br/'

let endpoint = self.ENDPOINT_PUB

if (process.env.HOME) {
  endpoint = self.ENDPOINT_PRI
}

self.endpoint = endpoint
self.DESKTOP_STORE = '001'
self.MOBILE_STORE = '005'
self.STORE_CODE = '001'
self.STORE_CODE_SHIPPING = 1
self.STORE_CODE_SHIPPING_MOBILE = 5

self.MUST_SHOW_CANONICAL = 1

self.ID_NUPAY_METHOD = 128

self.GTM_ID = 'GTM-W3L5TR8'

self.SH_KEY = 'SH-271446'

self.VWO_ACCOUNT_ID = '833870'

self.VWO_SDK_ENVIRONMENT = '611a6bde9d33e2d6562daad2ecc8b27c'

self.SEARCH_VARIANT = 'catalog'

self.KABUM_URL = 'https://www.kabum.com.br'

self.M_KABUM_URL = 'https://www.m.kabum.com.br'

self.KABUM_COMPLETE_URL = 'https://www.kabum.com.br'

self.KABUM_CGI_URL = 'https://www.kabum.com.br/cgi-local'

self.KABUM_COOKIE_URL = '.kabum.com.br'

self.STATIC_KABUM = 'https://static.kabum.com.br'

self.STATIC_FOOTER_JSON = `${self.STATIC_KABUM}/conteudo/footer/footer.json`

self.STATIC_HEADER_JSON = `${self.STATIC_KABUM}/conteudo/header/header.json`

self.STATIC_KABUM_IMAGES_K5 = `${self.STATIC_KABUM}/conteudo/temas/001/imagens/k5/images`

self.STATIC_KABUM_IMAGES_SUPER_MAQUINA = `${self.STATIC_KABUM}/conteudo/temas/001/imagens/k5/images/hotsite/super_maquina`

self.FONT_POPPINS = `${self.STATIC_KABUM}/conteudo/temas/001/font/Poppins-Regular.woff`

self.IMAGE_BLUR_URL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII='

self.DOMAIN_STATIC = `static.kabum.com.br`

self.DOMAIN_THEME = `themes.kabum.com.br`

self.DOMAIN_PRODUCTS = [
  's3.amazonaws.com',
  'gk-prod-images-001.s3.amazonaws.com',
  'gk-prod-images-001-original.s3.amazonaws.com',
  'gk-prod-openbox.s3.amazonaws.com',
  'images0.qa.kabumdev.com.br',
  'images1.qa.kabumdev.com.br',
  'images2.qa.kabumdev.com.br',
  'images3.qa.kabumdev.com.br',
  'images4.qa.kabumdev.com.br',
  'images5.qa.kabumdev.com.br',
  'images6.qa.kabumdev.com.br',
  'images7.qa.kabumdev.com.br',
  'images8.qa.kabumdev.com.br',
  'images9.qa.kabumdev.com.br',
  'images10.qa.kabumdev.com.br',
  'images.kabum.com.br',
  'images0.kabum.com.br',
  'images1.kabum.com.br',
  'images2.kabum.com.br',
  'images3.kabum.com.br',
  'images4.kabum.com.br',
  'images5.kabum.com.br',
  'images6.kabum.com.br',
  'images7.kabum.com.br',
  'images8.kabum.com.br',
  'images9.kabum.com.br',
  'images10.kabum.com.br'
]

self.URL_PREFIX_PROTOCOL_IMAGE = 'https://images6.kabum.com.br/'

// SERVER

self.SERVER_ENVIRONMENT = 'PRODUCTION'

// SITE EBIT

self.EBIT_KABUM = 'https://www.ebit.com.br/KaBuM'

// HOTSITE SEJA PRIME

self.HOTSITE_PRIME = 'https://www.kabum.com.br/hotsite/prime/'

self.HOTSITE_CARTAO_KABUM = 'https://www.kabum.com.br/hotsite/cartao/'

self.HOTSITE_APP_KABUM = 'https://www.kabum.com.br/hotsite/app/'

// ENDPOINTS BLACKFRIDAY

self.BACKEND_BLACKFRIDAY = '/blackflag'

// BACKEND
self.BACKEND_PAGAMENTO = '/order/v6/pagamento'

self.BACKEND_CONFIRMACAO = '/order/v6/confirmacao'

self.BACKEND_CONCLUIR = '/order/v6/concluir'

self.BACKEND_CHANGE_PAYMENT = `${endpoint}order/v6/change-payment`

self.BACKEND_CHANGE_CONFIRMATION = `${endpoint}order/v6/change-confirmation`

// ENDPOINTS MS CATEGORIA

self.BACKEND_CATEGORIAS = `/categoria/v1/categoria`

// ENDPOINTS MS LISTAGEM

self.BACKEND_LISTAGEM_OFERTA_URL = `https://b2lq2jmc06.execute-api.us-east-1.amazonaws.com/PROD/ofertas?`

self.BACKEND_BANNER_OFERTA_URL = `${endpoint}banner/v2/banner`

// ENDPOINTS MS CLIENTE

self.BACKEND_CLIENTE = '/cliente/v2/cliente'

self.BACKEND_CLIENTE_ALTERAR_DADOS = '/cliente/v3/cliente'

// ENDPOINTS MS FRETE
self.BACKEND_SHIPPING_OPTIONS = `${endpoint}shipping/v3/quotation`
self.BACKEND_SHIPPING_ZIPCODE = '/shipping/v1/zip-query'

// ENDPOINTS TMS
self.BACKEND_TMS_SHIPPING_OPTIONS = '/tms-ux/v1/quote'

// ENDPOINTS MS HOME

self.BACKEND_OFERTA_CAMPANHA = '/home/v1/campanhaofertas/produto'

self.BACKEND_JUST_ARRIVED_PRODUCTS = '/home/v1/acabaramchegar/produto'

self.BACKEND_MOST_WANTED_PRODUCTS = '/home/v1/maisprocurados/produto'

self.BACKEND_CADASTRO_NEWSLETTER = `${endpoint}home/v1/newsletter`

// ENDPOINTS MS LOGIN

self.BACKEND_SESSION = '/login/v3/usuario/session'

self.BACKEND_LOGIN = `${endpoint}login/v4/usuario/login`

self.BACKEND_USUARIO_VERIFICAR = `${endpoint}login/v3/usuario/verificar`

self.BACKEND_RECUPERAR_LOGIN = '/login/v3/recuperar-login'

self.BACKEND_RECUPERAR_SENHA = '/login/v3/recuperar-senha'

self.BACKEND_NOVA_SENHA = `${endpoint}login/v3/nova-senha`

self.BACKEND_LOGOUT = `${endpoint}login/v3/usuario/logout`

self.BACKEND_USER_AUTH = `${endpoint}login/v3/usuario/auth`

self.BACKEND_LOGIN_MFA = `${endpoint}login/v4/usuario/enviar-codigo-ativacao`

self.BACKEND_ATIVAR_CONTA = `${endpoint}login/v4/usuario/ativar-usuario`

// ENDPOINTS MS BANNER

self.BACKEND_BANNERS = '/banner/v3/bannerhome'

self.BACKEND_BANNERS_MINHA_CONTA = `/banner/v3/banner-minhaconta`

self.BACKEND_BANNER = '/banner/v3/banner'

// ENDPOINT BANNER V5

self.BACKEND_BANNER_V5 = `${endpoint}banner/v5`

// ENDPOINT MS MINHA CONTA

self.BACKEND_MINHA_CONTA = 'minhaconta/v4/usuario'

self.BACKEND_MINHA_CONTA_DADOS = `${endpoint}minhaconta/v4/usuario`

self.BACKEND_MINHA_CONTA_EMAIL = `${endpoint}minhaconta/v4/alterar-email`

self.BACKEND_MINHA_CONTA_SENHA = `${endpoint}minhaconta/v4/alterar-senha`

self.BACKEND_MINHA_CONTA_MFA = `${endpoint}minhaconta/v4/send-code`

self.BACKEND_MINHA_CONTA_AVALIACOES = `${endpoint}minhaconta/v4/avaliacoes-produtos`

self.BACKEND_MINHA_CONTA_SORTEIOS = `${endpoint}minhaconta/v4/sorteios`

self.BACKEND_MINHA_CONTA_PRECADASTRO = `${endpoint}minhaconta/v4/pre-cadastro`

self.BACKEND_MINHA_CONTA_ONDE_CONHECEU_KABUM = `${endpoint}minhaconta/v4/origem`

self.BACKEND_MEUS_PEDIDOS = 'minhaconta/v5/pedidos'

self.BACKEND_MEUS_PEDIDOS_DETALHES = 'minhaconta/v6/pedido-detalhes'

self.BACKEND_MINHA_CONTA_ONDE_CONHECEU_KABUM = `${endpoint}minhaconta/v4/origem`

self.BACKEND_ULTIMO_PEDIDO = 'minhaconta/v4/ultimo-pedido'

self.BACKEND_PROTOCOLOS = `${endpoint}rma/v1/protocolos`

self.BACKEND_PROFILE_PICTURE = `${endpoint}upload/v1/upload/profile`

self.BACKEND_MINHA_CONTA_ATENDIMENTO = `${endpoint}minhaconta/v4/flagatendimento`

self.BACKEND_MINHA_CONTA_CADASTRO_USUARIOS = `${endpoint}minhaconta/v5/usuario`

self.BACKEND_MINHA_CONTA_ATIVAR_USUARIOS = `${endpoint}minhaconta/v5/ativar-usuario`

self.BACKEND_PEDIDO_NOTA_FISCAL = `${endpoint}minhaconta/v5/pedido-notafiscal`

// ENDPOINT MS RMA

self.BACKEND_TROCA_E_ARREPENDIMENTO = `${endpoint}rma/v1/trocas-arrependimentos`

self.BACKEND_UPLOAD_IMAGEM_RMA = `${endpoint}upload/v1/upload/rma`

self.BACKEND_CAMINHO_IMAGEM_RMA = 'https://s3.amazonaws.com/rma.kabum.com.br/'

// ENDPOINTS MS DESCRICAO

self.BACKEND_DESCRICAO_PRODUTO = '/descricao/v2/descricao/produto'

self.BACKEND_DESCRICAO_OPENBOX = '/descricao/v1/openbox'

self.BACKEND_AVISEME_URL = `${endpoint}aviseme/`

self.BACKEND_FORUM_URL = `${endpoint}forum/`

self.BACKEND_FAMILIA_PRODUTO = `${endpoint}descricao/v1/descricao/familia`
// MS OPINIÕES

self.BACKEND_AVALIACOES_PRODUTO = `${endpoint}opinioes/v2/opinioes`

// ENDPOINTS MS ORDER

self.BACKEND_ORDER = `${self.ENDPOINT_PUB}order`

self.BACKEND_ADICIONAR_CARRINHO = '/order/v6/adicionar'

self.BACKEND_ORDER_CARRINHO = 'order/v6/carrinho'

self.BACKEND_WARRANTY_SERVICES = `${endpoint}warranty/v1/products`

// ENDPOINTS DATALAYER

self.BACKEND_DL_VISITOR = `${self.ENDPOINT_PUB}customer/v1/dl-visitor`

// ENDPOINTS CAPTCHA

self.BACKEND_VALIDATE_CAPTCHA =
  'https://www.google.com/recaptcha/api/siteverify'

// ENDPOINTS COBRANDED

self.BACKEND_COBRANDED = `${endpoint}cobranded/v1`

self.BACKEND_COBRANDED_V2 = `${endpoint}cobranded/v2`

// ENDPOINTS CATALOG
self.BACKEND_CATALOG_PRODUCTS_URL = `${endpoint}catalog/v2/products`

self.BACKEND_CATALOG_PRODUCTS_BY_CATEGORY_URL = `${endpoint}catalog/v2/products-by-category`

self.BACKEND_CATALOG_PROMOTION = `${endpoint}catalog/v2/promotion`

self.BACKEND_CATALOG_RELEASES = `${endpoint}catalog/v2/releases`

self.BACKEND_CATALOG_HIGHLIGHTS = `${endpoint}catalog/v2/highlights`

self.BACKEND_CATALOG_ORIGINS = `${endpoint}catalog/v2/origins`

self.BACKEND_CATALOG_CROSS_CART = `${endpoint}catalog/v2/cross_carts`

self.BACKEND_CATALOG_CROSS_SELLING = `${endpoint}catalog/v2/cross_sellings`

self.BACKEND_CATALOG_SHOWCASE = `${endpoint}catalog/v2/showcase`

self.BACKEND_CATALOG_BRANDSHOWCASE = `${endpoint}catalog/v2/brandshowcase`

self.BACKEND_CATALOG_SUGGESTION = `${endpoint}catalog-flask/v1/suggestion`

self.BACKEND_CATALOG_SEARCH = `${endpoint}catalog/v2/search`

self.BACKEND_CATALOG_SEO_SEARCHES = `${endpoint}catalog/v2/seo_searches`

// BRAND PAGE

self.BACKEND_BRANDS = `${endpoint}descricao/v1/marcas`

self.BACKEND_BRAND_DETAIL = `${self.DOMAIN_THEME}/brandpage`

self.BACKEND_CATALOG_RECOMMENDATIONS = `${endpoint}catalog/v2/smarthint`

// WORD CLOUD

self.BACKEND_WORD_CLOUD = `${self.DOMAIN_THEME}/palavrasmaisbuscadas/index.json`

// JSON

self.BACKEND_OFERTAS = 'http://proxy.elb.prd.kabum.com.br/ofertas_home.json'

// CLIENT CODES

self.CODIGO_COMPRE_CONFIE = '103388'

// KEYS

self.CAPTCHA_LOGIN_SITE = '6LfMTXIUAAAAAE-0W8PmIwyDIR9EJ61zvEHXI9Q3'

self.CAPTCHA_LOGIN_SECRET = '6LfMTXIUAAAAAFx63m1ANP0nXHm50VeOQ6e2ZE0E'

// SOCIAL IDS
self.FACEBOOKAPPID = '1719850761605142'
self.GOOGLE_CLIENT_ID =
  '607959893956-8chfpa5babel7k7h90hs4tjdfqfm8ag4.apps.googleusercontent.com'

// ENDPOINT SOCIAL NETWORK LOGIN

self.LOGIN_FACEBOOK = `${endpoint}login/v3/oauth/facebook`
self.LOGIN_GOOGLE = `${endpoint}login/v3/oauth/google`

// ENDPOINT LISTA DE PRODUTOS FAVORITADOS
self.BACKEND_LISTA_DESEJOS = `${endpoint}minhaconta/v4/lista-desejo`

// ENDPOINT MINHA CONTA
self.BACKEND_MINHACONTA_URL = `${endpoint}minhaconta/`
self.BACKEND_RASTREIO = `${endpoint}shipping/v2/tracking`

// INSTITUCIONAL
self.STATIC_KABUM_POLICIES_COOKIES_K5 = `${self.STATIC_KABUM}/conteudo/politicas_cookies/data.json`
self.STATIC_KABUM_POLICIES_K5 = `${self.STATIC_KABUM}/conteudo/politicas/data.json`
self.STATIC_KABUM_REPORT = `${self.STATIC_KABUM}/relatorio/relatorios.json`
self.STATIC_KABUM_PRIVACY_K5 = `${self.STATIC_KABUM}/conteudo/privacidade/data.json`
self.STATIC_KABUM_FAQ_K5 = `${self.STATIC_KABUM}/conteudo/faq/data.json`
self.STATIC_KABUM_CCE_K5 = `${self.STATIC_KABUM}/conteudo/codigo_conduta/data.json`
self.BACKEND_TIME_STAMP = '/time.stamp'
self.BACKEND_DATA_UPDATE = '/data_update.json'

// INSURANCE TERMS
self.BACKEND_WARRANTY_TERMS = `${endpoint}warranty/v1/tacps`
self.STATIC_KABUM_THEFT_K5 = `${self.STATIC_KABUM}/conteudo/termos_de_uso/roubo_furto/data.json`
self.STATIC_KABUM_WARRANTY_K5 = `${self.STATIC_KABUM}/conteudo/termos_de_uso/garantia/data.json`
self.STATIC_KABUM_JOINT_PAYMENT_K5 = `${self.STATIC_KABUM}/conteudo/termos_de_uso/pagamento_conjunto/data.json`

// PAYMENT CODES
self.BANKSLIP_CODE = 1
self.PAYPAL_CODE = 67
self.PIX_CODE = 98
self.COBRANDED_CODE = 'cobranded'
self.COBRANDED_BIN = '407830'

self.BILLET_URL = `${self.KABUM_CGI_URL}/site/caixa/pagamentos/boleto_bancario/boleto.cgi`

self.AWS_BUCKET_LINK = 'https://gkt-prod-upload.s3.amazonaws.com'

self.BACKEND_BANNERS_LISTING = `${endpoint}banner/v3/banner-listagem?menu=`

self.BACKEND_BANNERS_BUSCA = `${endpoint}banner/v3/banner-listagem?tag=`

self.AWIN_TRACKING_SALE_TEST = 0

self.CYBERSOURCE_ID = 'k8vif92e'

// STATUS PEDIDO
self.ORDER_CANCELLED = 34

// ENDPOINT VALIDA CLIENTE MAGALU
self.BACKEND_VALIDA_CLIENTE_MAGALU = `${endpoint}minhaconta/v4/magalu/valida`

// ENDPOINT VALIDA PEDIDO MAGALU
self.BACKEND_VALIDA_PEDIDO_MAGALU = `${endpoint}minhaconta/v4/magalu/pedido`

// ENDPOINT HOTSITE
self.BACKEND_MINHA_CONTA_INDICACAO_LINK = `${endpoint}minhaconta/v4/indicacao/link`

self.BACKEND_MINHA_CONTA_CADASTRO_INDICACAO_LINK = `${endpoint}minhaconta/v4/indicacao/cadastro`

self.BACKEND_MINHA_CONTA_CUPONS_SORTEIO = `${endpoint}minhaconta/v4/indicacao/cupons`

self.BACKEND_MINHA_CONTA_CADASTRO_CLIENTE_MAGALU = `${endpoint}minhaconta/v4/magalu/cliente`

// CUSTOMER
self.BACKEND_CUSTOMER_REVIEW_PRODUCT = `${endpoint}customer/v1/review`

// ONE TRUST
self.ONETRUST_SCRIPT_DATA_DOMAIN = 'f2620729-44b9-4680-b58d-990c0d86bc10'

self.PRODUCTION = true

self.DD_APPLICATION_ID = 'e9a20af5-70b5-4e42-8c44-65e66204b523'
self.DD_CLIENT_TOKEN = 'pub8cc4ab83a8e335183e43606cdbbbc49a'
self.DD_SERVICE_NAME = 'ecommerce-frontend-core'

// DEPLOYMENT ID CHAT GENESYS
self.DEPLOYMENT_ID_GENESYS = '9922acbd-3230-4c17-bf78-dc8db5ce131d'

// CUSTOMER
self.BACKEND_CUSTOMER_WISHLIST = `${endpoint}customer/v1/wishlist`

// API KEY SOLUCX CSAT
self.API_KEY_SOLUCX_CSAT = 'a110da1518f600096f9578f052074b5f'

// CSAT
self.BACKEND_AUTOATENDIMENTO_SOLUX_CSAT = `${endpoint}autoatendimento/v1/atendimento/genesys`

// MS-PRODUCT
self.BACKEND_MS_PRODUCT = `${endpoint}product/v1/product`

// FEATURED BRANDS
self.BACKEND_FEATURED_BRANDS = `https://${self.DOMAIN_THEME}/brandpage/destaques/index.json`

// BGS
self.STATIC_KABUM_FAQ_BGS = `${self.STATIC_KABUM}/conteudo/bgs/faq.json`

// ENDPOINT HEALTHCHECK
self.BACKEND_CUSTOMER_ENDPOINTS_HEALTHCHECK = 'customer/healthcheck/'

self.RIVE_WASM_URL = '/static/wasm/rive.wasm'

// SELLER SCORE
self.BACKEND_SELLER_SCORE = `${endpoint}seller-score/v1/scores`

// INSIDER
self.INSIDER_PARTNER_NAME = 'kabum'

self.INSIDER_PARTNER_ID = '10010958'

module.exports = self
