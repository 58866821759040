import {
  IconAttention,
  IconCalendar,
  IconCart,
  IconCelebration,
  IconCheckFill,
  IconCloseFill,
  IconDelivery,
  IconNfe,
  IconTruck,
  IconYoutube
} from '@k_frontend/icons'
import { ComponentType } from 'react'

export const MILISECOND = 1000
export const MINUTE = 60
export const THIRTY_MINUTES_TO_MILISECOND = 30 * MINUTE * MILISECOND

export const NOTIFICATION_ICON: {
  [key: number]: { Icon: ComponentType<{ className?: string }> }
} = {
  1: { Icon: IconCart },
  2: { Icon: IconCheckFill },
  3: { Icon: IconDelivery },
  4: { Icon: IconNfe },
  5: { Icon: IconTruck },
  6: { Icon: IconCelebration },
  7: { Icon: IconDelivery },
  8: { Icon: IconAttention },
  9: { Icon: IconCheckFill },
  10: { Icon: IconCalendar },
  11: { Icon: IconCloseFill }
}

export const NOTIFICATION_BANNER: {
  [key: number]: { Icon: ComponentType<{ className?: string }> }
} = {
  1: null,
  2: { Icon: IconYoutube }
}
