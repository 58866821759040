import {
  IconAdresses,
  IconArrowDown,
  IconEye,
  IconEyeClose
} from '@k_frontend/icons'

import { useHeaderContext } from '../../Header.context'
import ModalSelectAddress from '../ModalSelectAddress'
import { useSelectAddress } from '../SelectAddress.hook'
import * as S from '../SelectAddress.styles'
import { PropsResizeAddress } from '../SelectAddress.type'
import * as D from './SelectAddressDesktop.styles'

function SelectAddressDesktop({
  handleCloseModal,
  handleOpenModal,
  isOpenModal,
  addressSelect
}: PropsResizeAddress) {
  const { isLoggedIn, addresses } = useHeaderContext()
  const { firstAddressWord, remainderAddress, useSelectAddressStore } =
    useSelectAddress({ addressSelect })
  const { setShowAddress, showAddress } = useSelectAddressStore()

  return (
    <D.Container>
      {isLoggedIn && addresses?.length > 0 && (
        <S.ContainerModal>
          <div
            onClick={() => setShowAddress(!showAddress)}
            onKeyUp={() => setShowAddress(!showAddress)}
            className='cursor-pointer mr-4'
          >
            {showAddress ? (
              <IconEye color='#ffffff' />
            ) : (
              <IconEyeClose color='#ffffff' />
            )}
          </div>
          <D.ModalSelect onClick={handleOpenModal}>
            <S.WrapperAddress>
              <IconAdresses color='#ffffff' />
              <S.AddressSelect>
                {firstAddressWord}
                <span
                  className={`text-xs ml-4 ${showAddress ? 'blur-0' : 'blur-sm'}`}
                >
                  {remainderAddress}
                </span>
              </S.AddressSelect>
              <IconArrowDown color='#ffffff' />
            </S.WrapperAddress>
          </D.ModalSelect>
          <ModalSelectAddress
            isOpen={isOpenModal}
            handleClose={handleCloseModal}
          />
        </S.ContainerModal>
      )}
    </D.Container>
  )
}

export default SelectAddressDesktop
