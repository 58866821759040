import { IconNotification } from '@k_frontend/icons'
import { AlertBanner } from 'components/AlertBanner'

export function WithoutNotifications() {
  return (
    <AlertBanner
      Icon={IconNotification}
      description='Você ainda não tem notificações.'
      className='my-12'
      fullWidth
    />
  )
}
