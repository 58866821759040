import {
  PricesState,
  ResizeHOC,
  TypeUserState,
  usePrices,
  useUser,
  useWishlist
} from '@k_frontend/core'
import { BACKEND_CUSTOMER_WISHLIST } from 'settings/kernel'
import theme from 'theme'
import { shallow } from 'zustand/shallow'

import { ProductProps } from '../Product.types'
import ProductGridDesktop from './Desktop/ProductGridDesktop'
import ProductGridMobile from './Mobile/ProductGridMobile'

const useUserSelector = (state: TypeUserState) => ({
  clientId: state.clientId,
  session: state.session
})

const selectorUsePrices = (state: PricesState, productId: number) => ({
  prices: state.selectorPricesDetails({ state, productId })?.prices,
  offer: state.selectorPricesDetails({ state, productId })?.offer
})

export default function ProductGrid({
  isPageList,
  product,
  cookieIsMobile,
  ...rest
}: ProductProps) {
  const { clientId, session } = useUser(useUserSelector, shallow)
  const toggleWishList = useWishlist((state) => state.toggleWishList)
  const { prices } = usePrices(
    (state) => selectorUsePrices(state, product.code),
    shallow
  )

  function handleClickWishList() {
    toggleWishList({
      code: Number(product.code),
      BACKEND_CUSTOMER_WISHLIST,
      clientId,
      price:
        product.priceWithDiscount ||
        product?.offer?.priceWithDiscount ||
        prices?.priceWithDiscount,
      session
    })
  }

  return (
    <ResizeHOC
      minDesktopWidth={isPageList && parseInt(theme.breakpoints.minTablet)}
      MobileComponent={ProductGridMobile}
      DesktopComponent={ProductGridDesktop}
      cookieIsMobile={cookieIsMobile ? 'true' : ''}
      product={product}
      isPageList={isPageList}
      handleClickWishList={handleClickWishList}
      {...rest}
    />
  )
}
