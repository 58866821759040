import { getValueOrDefault } from '@k_frontend/core'
import { RemoteLoadSearchModel } from 'data/usecases'
import { SearchModel } from 'domain/models'
import { formatStampsType } from 'utils/formatStampsType'
import { formatProductFriendlyName } from 'utils/friendlyName'

function getOldPrice(attributes: RemoteLoadSearchModel.Attributes) {
  const { is_prime, offer, price } = attributes

  return is_prime || !!offer ? price : attributes.old_price
}

function mapProduct(product: RemoteLoadSearchModel.Data) {
  const { attributes, relationships } = product

  return {
    code: product.id,
    productSpecie: attributes.species,
    name: attributes.title,
    description: attributes.description,
    tagDescription: getValueOrDefault<string>(
      attributes.tag_description,
      'Encontre as melhores ofertas aqui no KaBuM! Confira nossa linha de Computadores, Eletrônicos, Celulares, Notebooks, Tablets, Câmeras Digitais e mais. Compre Já!'
    ),
    weight: attributes.weight,
    friendlyName: formatProductFriendlyName(attributes.title),
    sellerName: attributes.marketplace?.seller_name || 'KaBuM!',
    sellerId: attributes.marketplace?.seller_id || 0,
    offerIdMarketplace: attributes.marketplace?.product_id || 0,
    category: attributes.menu,
    externalUrl: attributes.external_url,
    manufacturer: attributes.manufacturer || {},
    iframeUrl: '',
    image: attributes.images?.[0],
    images: attributes.images,
    price: attributes.price,
    primePrice: attributes.prime?.price || 0,
    primePriceWithDiscount: attributes.prime?.price_with_discount || 0,
    discountPercentagePrime: attributes.prime?.discount_percentage || 0,
    oldPrice: getOldPrice(attributes),
    oldPrimePrice: getOldPrice(attributes),
    maxInstallment: attributes?.max_installment || '',
    priceWithDiscount:
      attributes?.offer?.new_price_with_discount ||
      attributes.price_with_discount ||
      0,
    priceMarketplace: attributes?.marketplace?.price || 0,
    discountPercentage: attributes.discount_percentage || 0,
    offerDiscount: attributes.offer?.pay_cash_discount || 0,
    quantity: attributes.offer?.quantity_available || 0,
    rating: attributes.score_of_ratings || 0,
    ratingCount: attributes.number_of_ratings,
    available: attributes.available,
    preOrderDate: attributes.date_pre_order,
    warranty: attributes.warranty,
    dateProductArrived: attributes.date_product_arrived || null,
    html: attributes.html || null,
    ufsFlash: attributes.ufs_flash,
    stamps: attributes.stamps && {
      id: attributes.stamps.id,
      title: attributes.stamps.title,
      name: attributes.stamps.name,
      backgroundColor: attributes.stamps.background_color,
      fontColor: attributes.stamps.font_color,
      type: formatStampsType(attributes.stamps.type)
    },
    flags: {
      isMarketplace: !!attributes.is_marketplace,
      isOpenbox: !!attributes.is_openbox,
      isFreeShipping: !!attributes.has_free_shipping,
      isFreeShippingPrime: !!attributes.has_free_shipping_for_prime_user,
      isPixShipping: !!attributes.has_pix_shipping,
      isPreOrder: !!attributes.is_pre_order,
      isFlash: attributes?.ufs_flash?.length > 0,
      isPrime: !!attributes.is_prime,
      isOffer: !!attributes.offer,
      hasGift: !!relationships?.gift?.data?.id,
      isHighlight: !!attributes?.featured_product
    },
    origin: {
      id: attributes.origin?.id || null,
      name: attributes.origin?.name || null
    },
    prime: attributes.prime && {
      price: attributes.prime.price,
      priceWithDiscount: attributes.prime.price_with_discount,
      discountPercentage: attributes.prime.discount_percentage,
      save: attributes.prime.save,
      isLoggedUserExclusive: attributes.prime.is_logged_user_exclusive,
      maxInstallmentPrime: attributes.max_installment_prime
    },
    offer: attributes.offer && {
      id: attributes.offer.id,
      name: attributes.offer.name,
      referenceBanner: attributes.offer.reference_banner,
      endsAt: attributes.offer.ends_at,
      startsAt: attributes.offer?.starts_at || 0,
      isPrimeExclusive: attributes.offer?.is_prime_exclusive || null,
      price: attributes.offer.price,
      priceWithDiscount: attributes.offer.price_with_discount,
      discountPercentage: attributes.offer.discount_percentage,
      quantityAvailable: attributes.offer.quantity_available,
      isLoggedUserExclusive: attributes.offer.is_logged_user_exclusive,
      hashCode: attributes.offer.hash_code
    },
    colors: {
      main: attributes.offer?.color || '',
      text: attributes.offer?.text || '#fff'
    },
    paymentMethodsDefault:
      attributes.payment_methods_default?.map((method) => ({
        category: method?.category,
        method: method?.method,
        bestInstallment: method?.advantageous_installment && {
          installment: method?.advantageous_installment?.installment,
          discountPercentage: method?.advantageous_installment?.discount,
          amount: method?.advantageous_installment?.value,
          hasFee: method?.advantageous_installment?.fees
        },
        installments: method?.installments?.map((installment) => ({
          id: installment?.id,
          paymentTerms: installment?.payment_terms,
          installment: installment?.installment,
          amount: installment?.amount,
          total: installment?.total,
          hasFee: installment?.fees
        }))
      })) || null,
    paymentMethodsPrime:
      attributes.payment_methods_prime?.map((method) => ({
        category: method?.category,
        method: method?.method,
        bestInstallment: method?.advantageous_installment && {
          installment: method?.advantageous_installment?.installment,
          discountPercentage: method?.advantageous_installment?.discount,
          amount: method?.advantageous_installment?.value,
          hasFee: method?.advantageous_installment?.fees
        },
        installments: method?.installments?.map((installment) => ({
          id: installment?.id,
          paymentTerms: installment?.payment_terms,
          installment: installment?.installment,
          amount: installment?.amount,
          total: installment?.total,
          hasFee: installment?.fees
        }))
      })) || null,
    tagCode: attributes.tag_code || 0,
    photos: attributes.photos,
    thumbnail: attributes.photos?.m?.[0] || attributes.images?.[0],
    promotionBanner:
      (attributes?.banner_tag_promotion && {
        type: attributes?.banner_tag_promotion?.type,
        media: attributes?.banner_tag_promotion?.media,
        hyperlink: attributes?.banner_tag_promotion?.hyperlink
      }) ||
      null,
    campaignBanners:
      attributes?.banner_campaign?.map((banner) => ({
        type: banner?.type,
        media: banner?.media,
        hyperlink: banner?.hyperlink
      })) || null,
    crossCart: attributes.cross_cart || [],
    crossSelling:
      (!!attributes?.cross_selling?.length &&
        attributes?.cross_selling?.map((item) => item)) ||
      []
  }
}

export function remoteLoadSearch(
  response: RemoteLoadSearchModel.Response
): SearchModel.Model {
  return {
    meta: {
      breadcrumb: response?.meta?.breadcrumb,
      seo: {
        title: response?.meta?.seo?.title || '',
        description: response?.meta?.seo?.description || '',
        titleHeading: response?.meta?.seo?.title_h1 || ''
      },
      promotion: {
        title: response?.meta?.promotion?.title || '',
        description: response?.meta?.promotion?.description || ''
      },
      totalItemsCount: response?.meta?.total_items_count,
      totalPagesCount: response?.meta?.total_pages_count,
      page: {
        cursor: response?.meta?.page?.cursor,
        number: response?.meta?.page?.number,
        size: response?.meta?.page?.size,
        isCurrentPage: response?.meta?.page?.is_current_page
      },
      filters: [
        ...((response?.meta?.filters?.bool_filters ||
          response?.filters?.bool_filters) ??
          []),
        ...((response?.meta?.filters?.string_filters ||
          response?.filters?.string_filters) ??
          []),
        ...((response?.meta?.filters?.number_filters ||
          response?.filters?.number_filters) ??
          []),
        ...((response?.meta?.filters?.highlighted_filters ||
          response?.filters?.highlighted_filters) ??
          [])
      ],
      searchVariant: response?.meta?.variant || ''
    },
    pagination: {
      prev: response?.meta?.page?.number - 1,
      current: response?.meta?.page?.number,
      next: response?.meta?.page?.number + 1,
      total: response?.meta?.total_pages_count
    },
    data: response?.data?.map((product) => mapProduct(product)),
    redirect: response?.links?.redirect
  }
}
