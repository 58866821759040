import { StorePoint } from 'hooks/useTakeAwayShipping/useTakeAwayShipping.types'
import { TakeAwayShippingModel } from './takeAwayShippingModel.types'

export default function takeAwayShippingModel(
  takeAwayPoint: StorePoint,
  takeBySomeoneCPF: string,
  takeBySomeoneName: string
): TakeAwayShippingModel {
  function handleRecipient() {
    if (takeBySomeoneCPF && takeBySomeoneName) {
      return {
        identification: takeBySomeoneCPF,
        name: takeBySomeoneName
      }
    }

    return null
  }

  return {
    address: {
      city: takeAwayPoint.address.city,
      district: takeAwayPoint.address.district,
      number: takeAwayPoint.address.number,
      state: takeAwayPoint.address.state,
      street: takeAwayPoint.address.street,
      zip_code: takeAwayPoint.address.zipCode
    },
    recipient: handleRecipient(),
    store_id: takeAwayPoint.id
  }
}
