import styled from 'styled-components'

import { StyleProps } from './Menu.types'

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary(500)};
`

export const Nav = styled.nav`
  display: flex;
  justify-content: start;
  align-items: center;
  height: ${({ theme }) => theme.spacing.lg};

  &:after {
    content: '';
    background-color: #ff6500;
    position: absolute;
    height: 100%;
    left: 100%;
    z-index: 1;
  }
`

export const DropdownButton = styled.div<StyleProps>`
  width: ${({ theme }) => theme.rem(184)};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.secondary(600)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding: 0 ${({ theme }) => theme.spacing.sm};
  cursor: pointer;

  span {
    ${({ theme }) => theme.font(14, 18, 700)}
    color: ${({ theme }) => theme.colors.white(500)};
  }

  svg {
    transition: all 0.4s ease;
    transform: ${({ active }) => (active ? 'rotate(0deg)' : 'rotate(180deg)')};
  }
`

export const Dropdown = styled.div<StyleProps>`
  display: flex;
  z-index: 99;
  position: relative;
`

export const DropdownBackground = styled.div<StyleProps>`
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.black(700)};
  opacity: 0.3;
  width: 100%;
  height: 100vh;
`

export const Stick = styled.div`
  width: ${({ theme }) => theme.rem(1)};
  height: ${({ theme }) => theme.rem(28)};
  background-color: ${({ theme }) => theme.colors.secondary(400)};

  :last-of-type {
    display: none;
  }
`

export const Link = styled.a`
  display: flex;
  flex: 1;
  flex-basis: auto;
  height: 100%;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.font(12, 18, 700, 'uppercase')}
  color: ${({ theme }) => theme.colors.white(500)};
  text-decoration: none;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.secondary(600)};
  }

  //Using a custom breakpoint to avoid line break when there are many menu itens
  @media (min-width: 1200px) {
    ${({ theme }) => theme.font(14, 18, 700, 'uppercase')}
  }
`
