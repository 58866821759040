import Product from '../Product/Product'
import { SellerProps } from './Seller.types'

function Seller({ seller }: SellerProps) {
  return (
    <div className='flex flex-col'>
      <p className='w-fit bg-black-300 px-4 text-xs text-black-700'>
        Vendido e entregue por:{' '}
        <span className='text-xs font-bold'>{seller.name}</span>
      </p>
      {seller.origins.map((origin) =>
        origin.products.map((product) => (
          <Product
            key={product.code}
            product={product}
            sellerId={seller.id}
            originId={origin.id}
          />
        ))
      )}
    </div>
  )
}

export default Seller
