import { useMemo } from 'react'
import * as S from './AlertBanner.styles'
import { AlertBannerProps } from './AlertBanner.types'

export function AlertBanner({
  description,
  Icon,
  className,
  fullWidth
}: AlertBannerProps) {
  const renderedIcon = useMemo(() => {
    if (!Icon) return <></>
    return <Icon className={S.svg()} />
  }, [Icon])

  return (
    <section className={`${S.container({ fullWidth })} ${className}`}>
      {renderedIcon}
      <span className={S.description()}>{description}</span>
    </section>
  )
}
