import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { UseMiniPreCart } from './useMiniPreCart.types'

const useMiniPreCart = create<UseMiniPreCart.Store>()(
  devtools(
    (set, get) => ({
      isOpen: false,
      toggleIsOpen: () => set({ isOpen: !get().isOpen })
    }),
    {
      name: 'useMiniPreCart'
    }
  )
)

export { useMiniPreCart }
