import {
  IconBasket,
  IconCreditCardFill,
  IconCustomerService,
  IconGift,
  IconHome,
  IconThumbsUpFilled,
  IconUser
} from '@k_frontend/icons'

export const MY_ACCOUNT_SHORTCUTS = [
  {
    id: 1,
    icon: IconHome,
    label: 'Minha Conta',
    slug: '/minha-conta',
    textInfo: 'Veja históricos e acompanhe suas compras.'
  },
  {
    id: 2,
    icon: IconBasket,
    label: 'Meus pedidos',
    slug: '/minha-conta/meus-pedidos',
    textInfo: 'Veja históricos e acompanhe suas compras.'
  },
  {
    id: 3,
    icon: IconUser,
    label: 'Meus dados',
    slug: '/minha-conta/meus-dados',
    textInfo:
      'Altere seus dados cadastrados, endereços ou cadastre um novo endereço.'
  },
  {
    id: 4,
    icon: IconCreditCardFill,
    label: 'Carteira',
    slug: '/minha-conta/carteira',
    textInfo: 'Gerencie seus cartões, créditos e resgate gift card.'
  },
  {
    id: 5,
    icon: IconCustomerService,
    label: 'Protocolos',
    slug: '/minha-conta/protocolos',
    textInfo: 'Aqui você encontra seus protocolos de atendimento.'
  },
  {
    id: 6,
    icon: IconThumbsUpFilled,
    label: 'Avaliações',
    slug: '/minha-conta/avaliacoes',
    textInfo: 'Avalie suas compras e visualize suas avaliações e comentários.'
  },
  {
    id: 7,
    icon: IconGift,
    label: 'Sorteios',
    slug: '/minha-conta/sorteio',
    textInfo: 'Consulte seus cupons de sorteios.'
  }
]
