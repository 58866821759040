import { IconCart } from '@k_frontend/icons'
import { Button } from '@kbm/button'
import { useMiniCart } from 'hooks/useMiniCart/useMiniCart'
import { tailwindTheme } from 'theme/tailwindTheme'

function EmptyCart() {
  const toggleIsOpen = useMiniCart((state) => state.toggleIsOpen)

  return (
    <div className='flex mx-auto flex-col justify-center items-center w-fit gap-24'>
      <p className='text-xl text-black-800 font-bold whitespace-nowrap overflow-hidden'>
        O seu carrinho está vazio.
      </p>
      <Button
        type='button'
        icon={{
          icon: (
            <IconCart
              width={13}
              height={13}
              color={tailwindTheme.colors.white[500]}
            />
          ),
          position: 'left'
        }}
        onClick={toggleIsOpen}
      >
        Continuar comprando
      </Button>
    </div>
  )
}

export default EmptyCart
