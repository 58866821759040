import * as RadioGroup from '@radix-ui/react-radio-group'
import { useShoppingCart } from 'hooks/useShoppingCart/useShoppingCart'

import { Option } from './Option/Option'
import { ServiceProps } from './Service.types'

function Service({ service }: ServiceProps) {
  const { isLoading, updateServices } = useShoppingCart((state) => ({
    isLoading: state.isLoading,
    updateServices: state.updateServices
  }))
  const currentOption = service.getSelectedOption()?.code

  function handleServiceChange(serviceCode: string) {
    service.setSelectedOption(Number(serviceCode))
    updateServices()
  }

  function serviceName() {
    switch (service.code) {
      case 'seguro':
        return `${service.name} - Seguro de roubo e furto`
      case 'garantia':
        return 'Garantia Estendida Original Ampliada'
      default:
        return service.name
    }
  }

  function serviceDescription() {
    switch (service.code) {
      case 'seguro':
        return 'o Seguro de Roubo e Furto + Quebra Acidental'
      case 'garantia':
        return 'Garantia Estendida Original Ampliada'
      default:
        return service.name
    }
  }

  return (
    <div className='flex flex-col mt-16 gap-8'>
      <h4 className='uppercase text-xs text-black-800 font-bold'>
        {serviceName()}
      </h4>
      <RadioGroup.Root
        value={String(currentOption)}
        onValueChange={handleServiceChange}
        disabled={isLoading}
        className='flex flex-col gap-4'
      >
        <Option
          option={{
            code: 0,
            description: `Sem ${service.code}`,
            name: '',
            parceledPrice: 0,
            parcels: 0,
            selected: !currentOption
          }}
        />
        {service.option.map((option) => (
          <Option key={option.code} option={option} />
        ))}
      </RadioGroup.Root>

      <p className='text-[10px]/[16px] text-black-800'>
        Ao adicionar{' '}
        <span className='text-[10px]/[16px] font-semibold'>
          {serviceDescription()}
        </span>
        , declaro que tive acesso, li e aceito as{' '}
        <span className='text-[10px]/[16px] text-secondary'>
          Condições gerais
        </span>
        .
      </p>
    </div>
  )
}

export default Service
