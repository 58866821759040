import styled, { css } from 'styled-components'

type TagStyledTypes = {
  small: boolean
  color?: string
  weight?: string
}

export const Tags = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.xxs};
  width: 100%;
`

export const Tag = styled.div<TagStyledTypes>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${(props) => props.theme.spacing.xxxs};
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.black(700)};

  svg {
    height: ${({ theme }) => theme.rem(12)};

    * {
    height: ${({ theme }) => theme.rem(12)};
    }
  }

  ${(props) => {
    if (props.small) {
      return css`
        ${props.theme.font(10, 16)};
      `
    } else {
      return css`
        ${props.theme.font(12, 18)};
      `
    }
  }}

  ${(props) => {
    if (props.weight) {
      return css`
        font-weight: ${props.weight};
      `
    }
  }}

  ${(props) => {
    if (props.color) {
      return css`
        svg {
          path {
            fill: ${props.theme.colors[props.color]()};
          }
        }
      `
    }
  }}
`

export const GiftTooltipText = styled.span`
  ${({ theme }) => theme.font(12, 18, 400)};
  color: ${({ theme }) => theme.colors.white(500)};
`
