const {
  BANKSLIP_CODE,
  PAYPAL_CODE,
  PIX_CODE
} = require('../../settings/kernel')

const self = {}

self.currency = (value) => {
  if (!isNaN(parseInt(value))) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(value)
  }

  return null
}

self.generateShippingStars = (rating) => {
  const starsRating = parseFloat(rating / 2)
  const intRating = starsRating.toString().split('.')[0]
  const decimalRating = starsRating - intRating

  let stars = starsRating

  if (parseFloat(decimalRating) > 0 && parseFloat(decimalRating) < 0.5) {
    stars = parseFloat(intRating) + 0.5
  } else if (parseFloat(decimalRating) > 0.5) {
    stars = parseInt(intRating) + 1
  }

  return stars
}

self.isPaymentOptionCreditCard = (paymentOption) => {
  return (
    paymentOption != BANKSLIP_CODE &&
    paymentOption != PAYPAL_CODE &&
    paymentOption != PIX_CODE
  )
}

self.getOptionString = (option) => {
  const { label, value } = option

  return `${label} - ${self.currency(value)}`
}

self.getOptionCodeByString = (string, options) => {
  const selectedOption = options.find(
    (option) => self.getOptionString(option) === string
  )

  const { code } = selectedOption

  return code
}

self.lastFourChars = (string) => {
  return string.substr(string.length - 4)
}

module.exports = self
