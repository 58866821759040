import { takeEvery, all } from 'redux-saga/effects'

import CartSaga from './cart'

const CartSagas = [takeEvery('ADD_PRODUCT_TO_CART', CartSaga.addProductToCart)]

function* sagas() {
  yield all([...CartSagas])
}

export default sagas
