import { NotificationCard } from '../NotificationCard'
import * as S from './NotificationList.styles'
import type { NotificationListProps } from './NotificationList.types'

export function NotificationList({ notices }: NotificationListProps) {
  return (
    <div className={S.notificationsWrapper()}>
      {notices?.map((notice, index) => (
        <NotificationCard key={index.toString()} notification={notice} />
      ))}
    </div>
  )
}
