import styled, { css } from 'styled-components'

import { ModalAddressContainerProps } from './ModalSelectAddress.types'

export const Container = styled.div`
  max-height: 60vh;

  .icon {
    margin-right: ${({ theme }) => theme.spacing.xxs};
  }

  @media (min-width: 2000px) {
    .ModalSelectAddress > div {
      top: 20%;
    }
  }
`
export const SubtTitle = styled.div`
  text-align: center;
  ${({ theme }) => theme.font(14, 16, 400)};
  color: ${({ theme }) => theme.colors.black(800)};
  margin: ${({ theme }) => theme.spacing.sm};
`

export const ContainerAddress = styled.div<ModalAddressContainerProps>`
  border: 1px solid ${(props) => props.theme.colors.black(600)};
  border-radius: ${(props) => props.theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.sm};
  margin: ${({ theme }) => theme.spacing.xxs} 0;
  cursor: pointer;

  ${(props) => {
    if (props.selected) {
      return css`
        background-color: ${props.theme.colors.black(400)};
        opacity: 0.5;
        cursor: default;
      `
    }
  }}
`

export const ContainerStreet = styled.div``

export const identification = styled.div`
  ${({ theme }) => theme.font(14, 16, 600)};
  color: ${({ theme }) => theme.colors.black(800)};
`
export const Street = styled.span`
  width: ${({ theme }) => theme.rem(260)};
  ${({ theme }) => theme.font(14, 16, 400)};
  color: ${({ theme }) => theme.colors.black(600)};
  margin: ${({ theme }) => theme.spacing.xxxs} 0;
`

export const ContainerCode = styled.div`
  ${({ theme }) => theme.font(14, 16, 400)};
  color: ${({ theme }) => theme.colors.black(600)};
`
export const ContainerItens = styled.div``

export const Next = styled.button`
  background: ${({ theme }) => theme.colors.white(500)};
`

export const Prev = styled.button`
  background: ${({ theme }) => theme.colors.white(500)};
`

export const ContainerButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.sm}
  background: transparent;
`
