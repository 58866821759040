import { KABUM_COOKIE_URL } from 'settings/kernel'
import Cookie from 'universal-cookie'
import { sanitizeCookies } from 'utils/functions'


export default class Cookies {
  constructor(ClassCookie) {
    if (!ClassCookie) {
      ClassCookie = new Cookie()
    }

    this.classCookie = ClassCookie
    this.sellers = sanitizeCookies(ClassCookie.get('sellers'))
    this.clientName = sanitizeCookies(ClassCookie.get('nome'))
    this.profileImage = sanitizeCookies(ClassCookie.get('profile_image'))
    this.clientId = sanitizeCookies(ClassCookie.get('id_cliente'))
    this.session = sanitizeCookies(ClassCookie.get('session'))
    this.visitorCampaign = sanitizeCookies(ClassCookie.get('visitor_campaign'))
    this.visitorMedium = sanitizeCookies(ClassCookie.get('visitor_medium'))
    this.visitorSource = sanitizeCookies(ClassCookie.get('visitor_source'))
    this.product = sanitizeCookies(ClassCookie.get('productId'))
    this.mediaOrigin = sanitizeCookies(ClassCookie.get('origem'))
    this.services = sanitizeCookies(ClassCookie.get('services'))
    this.showpopup = sanitizeCookies(ClassCookie.get('showpopup'))
    this.hasCard = sanitizeCookies(ClassCookie.get('has_card'))
    this.isMobileDevice = sanitizeCookies(ClassCookie.get('isMobileDevice'))
    this.isMobileDeviceType = this._getDeviceType(ClassCookie.get('isMobileDevice'))
    this.customerUpdatePJ = sanitizeCookies(
      ClassCookie.get('customer_update_pj')
    )
    this.showPopupLancamento = sanitizeCookies(
      ClassCookie.get('showPopupLancamento')
    )
  }
  getIsMobileDevice() {
    return this.isMobileDevice
  }
  getIsMobileDeviceType() {
    return this.isMobileDeviceType
  }
  getSellers() {
    return this.sellers
  }

  getClientName() {
    return this.clientName
  }

  getClientId() {
    return this.clientId || ''
  }

  getSession() {
    return this.session || ''
  }

  getProfileImage() {
    return this.profileImage
  }

  getProductCookie() {
    return this.product
  }

  getCartProductsQuantity() {
    if (this.sellers) {
      const decodedString = decodeURI(this.sellers)
      const productsArray = decodedString.split('#')

      return productsArray.length
    }

    return 0
  }

  getVisitorCampaign() {
    return this.visitorCampaign
  }

  getVisitorMedium() {
    return this.visitorMedium
  }

  getVisitorSource() {
    return this.visitorSource
  }

  getCoupon() {
    return this.coupon
  }

  getServices() {
    return this.services
  }

  getMediaOrigin() {
    return this.mediaOrigin
  }

  setServices(services) {
    this._setCookie('services', services)
  }

  setCoupon(coupon) {
    this._setCookie('cupom', coupon)
  }

  setClientName(nome) {
    this._setCookie('nome', nome)
  }

  setSellers(sellers) {
    this._setCookie('sellers', sellers)
  }

  setHasCard(hasCard) {
    this._setCookie('has_card', hasCard)
  }

  getHasCard() {
    return this.hasCard
  }

  setShowPopup(showPopup, maxAge = undefined) {
    this._setCookie('showpopup', showPopup, maxAge)
  }

  getShowPopup() {
    return this.showpopup
  }

  setProfileImage(profileImage) {
    this._setCookie('profile_image', profileImage)
  }

  getShowPopupLancamento() {
    return this.showPopupLancamento
  }

  setDisableShowPopupLancamento() {
    const maximumValue = 2147483647
    this._setCookie('showPopupLancamento', '0', maximumValue)
  }

  _getDeviceType(isMobileDevice) {
    if (isMobileDevice === 'false') {
      return 'desktop'
    }
    return 'mobile'
  }

  _setCookie(key, value, maxAge = undefined) {
    this.classCookie.set(key, value, {
      domain: KABUM_COOKIE_URL,
      path: '/',
      maxAge
    })
  }
}
