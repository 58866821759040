import * as S from './HiddenPrice.styles'
import { HiddenPriceProps } from './HiddenPrice.types'

export default function HiddenPrice({ color, price }: HiddenPriceProps) {
  function formatPriceWithoutPrefix() {
    if (price) return price.split('R$')[1]
  }

  return (
    <S.Price color={color}>
      R$ <span>{formatPriceWithoutPrefix() || '88,88'}</span>
    </S.Price>
  )
}
