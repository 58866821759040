import { getValueOrDefault } from '@k_frontend/core'
import {
  CatalogProductResponse,
  Manufacturer,
  PaymentMethod
} from 'services/catalog/products'
import { formatProductFriendlyName } from 'utils/friendlyName'

import { formatStampsType } from 'utils/formatStampsType'
import {
  BannerModel,
  BaseProductData,
  CatalogProductModel,
  PaymentMethodModel
} from './products.types'

function getTagDescription(tagDescription: string) {
  const defaultDescription =
    'Encontre as melhores ofertas aqui no KaBuM! Confira nossa linha de Computadores, Eletrônicos, Celulares, Notebooks, Tablets, Câmeras Digitais e mais. Compre Já!'

  if (tagDescription !== '') {
    return tagDescription
  }

  return defaultDescription
}

function mapPaymentMethod(paymentMethod: PaymentMethod) {
  return {
    category: paymentMethod?.category,
    method: paymentMethod?.method,
    bestInstallment: paymentMethod?.advantageous_installment && {
      installment: paymentMethod?.advantageous_installment?.installment,
      discountPercentage: paymentMethod?.advantageous_installment?.discount,
      amount: paymentMethod?.advantageous_installment?.value,
      hasFee: paymentMethod?.advantageous_installment?.fees
    },
    installments: paymentMethod?.installments?.map((installment) => ({
      id: installment?.id,
      paymentTerms: installment?.payment_terms,
      installment: installment?.installment,
      amount: installment?.amount,
      total: installment?.total,
      hasFee: installment?.fees,
      discount: installment?.discount
    }))
  }
}

function getOldPrice(attributes: CatalogProductResponse['attributes']) {
  const { is_prime, offer, price } = attributes

  if (is_prime || !!offer) {
    return price
  }

  return attributes.old_price
}

export function catalogProductModel(
  data: CatalogProductResponse
): CatalogProductModel {
  const { attributes, relationships } = data
  const digitalProductSpecieCodes = [1, 3, 4]
  return {
    code: data.id,
    productSpecie: attributes.species,
    isDigital: digitalProductSpecieCodes.includes(attributes.species),
    name: attributes.title,
    description: attributes.description,
    tagDescription: getTagDescription(attributes.tag_description),
    weight: attributes.weight,
    friendlyName: formatProductFriendlyName(attributes.title),
    sellerName: getValueOrDefault<string>(
      attributes.marketplace?.seller_name,
      'KaBuM!'
    ),
    sellerId: getValueOrDefault<number>(attributes.marketplace?.seller_id, 0),
    offerIdMarketplace: getValueOrDefault<number>(
      attributes.marketplace?.product_id,
      0
    ),
    sellerCompany: getValueOrDefault<string>(
      attributes.marketplace?.company,
      ''
    ),
    sellerCnpj: getValueOrDefault<string>(attributes.marketplace?.cnpj, ''),
    category: attributes.menu,
    externalUrl: attributes.external_url,
    manufacturer: getValueOrDefault<Manufacturer, Record<string, never>>(
      attributes.manufacturer,
      {}
    ),
    iframeUrl: '',
    image: getValueOrDefault(attributes.images?.[0][0], ''),
    images: getValueOrDefault(attributes.images, []),
    price: attributes.price,
    primePrice: getValueOrDefault<number>(attributes.prime?.price, 0),
    primePriceWithDiscount: getValueOrDefault<number>(
      attributes.prime?.price_with_discount,
      0
    ),
    discountPercentagePrime: getValueOrDefault<number>(
      attributes.prime?.discount_percentage,
      0
    ),
    oldPrice: getOldPrice(attributes),
    oldPrimePrice: getOldPrice(attributes),
    maxInstallment: getValueOrDefault(attributes.max_installment, ''),
    priceWithDiscount: getValueOrDefault<number>(
      attributes.price_with_discount,
      0
    ),
    priceMarketplace: getValueOrDefault<number>(
      attributes?.marketplace?.price,
      0
    ),
    discountPercentage: getValueOrDefault<number>(
      attributes.discount_percentage,
      0
    ),
    quantity: getValueOrDefault<number>(
      attributes.offer?.quantity_available,
      0
    ),
    rating: getValueOrDefault<number>(attributes.score_of_ratings, 0),
    ratingCount: attributes.number_of_ratings,
    available: attributes.available,
    preOrderDate: attributes.date_pre_order,
    warranty: attributes.warranty,
    dateProductArrived: getValueOrDefault<number>(
      attributes.date_product_arrived
    ),
    html: getValueOrDefault<string>(attributes.html),
    ufsFlash: attributes.ufs_flash,
    stamps: attributes.stamps && {
      id: attributes.stamps.id,
      title: attributes.stamps.title,
      name: attributes.stamps.name,
      description: getValueOrDefault<string>(attributes.stamps.description, ''),
      linkRule: getValueOrDefault<string>(attributes.stamps.link_rule, ''),
      backgroundColor: attributes.stamps.background_color,
      fontColor: attributes.stamps.font_color,
      type: formatStampsType(attributes.stamps.type)
    },
    flags: {
      isMarketplace: !!attributes.is_marketplace,
      isOpenbox: !!attributes.is_openbox,
      isFreeShipping: !!attributes.has_free_shipping,
      isFreeShippingPrime: !!attributes.has_free_shipping_for_prime_user,
      isPixShipping: !!attributes.has_pix_shipping,
      isPreOrder: !!attributes.is_pre_order,
      isFlash: !!attributes?.ufs_flash?.length,
      isPrime: !!attributes.is_prime,
      isOffer: !!attributes.offer,
      hasGift: !!relationships?.gift?.data?.id,
      isHighlight: !!attributes?.featured_product
    },
    origin: {
      id: getValueOrDefault<number>(attributes.origin?.id),
      name: getValueOrDefault<string>(attributes.origin?.name)
    },
    prime: attributes.prime && {
      price: attributes.prime.price,
      priceWithDiscount: attributes.prime.price_with_discount,
      discountPercentage: attributes.prime.discount_percentage,
      save: attributes.prime.save,
      maxInstallmentPrime: attributes.max_installment_prime
    },
    offer: attributes.offer && {
      id: attributes.offer.id,
      name: attributes.offer.name,
      referenceBanner: attributes.offer.reference_banner,
      endsAt: attributes.offer.ends_at,
      startsAt: getValueOrDefault<number>(attributes.offer?.starts_at, 0),
      price: attributes.offer.price,
      priceWithDiscount: attributes.offer.price_with_discount,
      discountPercentage: attributes.offer.discount_percentage,
      quantityAvailable: attributes.offer.quantity_available,
      hashCode: attributes.offer.hash_code
    },
    paymentMethodsDefault: getValueOrDefault<PaymentMethodModel[]>(
      attributes.payment_methods_default?.map(mapPaymentMethod)
    ),
    paymentMethodsPrime: getValueOrDefault<PaymentMethodModel[]>(
      attributes.payment_methods_prime?.map(mapPaymentMethod)
    ),
    photos: attributes.photos,
    thumbnail: getValueOrDefault<string>(
      attributes.photos?.m?.[0],
      attributes.images?.[0]
    ),
    promotionBanner:
      (attributes.banner_tag_promotion && {
        type: attributes.banner_tag_promotion.type,
        media: attributes.banner_tag_promotion.media,
        hyperlink: attributes.banner_tag_promotion.hyperlink
      }) ||
      null,
    campaignBanners: getValueOrDefault<BannerModel[]>(
      attributes?.banner_campaign
        ? attributes?.banner_campaign.map((banner) => ({
            type: banner?.type,
            media: banner?.media,
            hyperlink: banner?.hyperlink
          }))
        : null
    ),
    crossCart: getValueOrDefault<number[]>(attributes.cross_cart, []),
    crossSelling: getValueOrDefault<number[]>(attributes.cross_selling, []),
    isProductInactive: data?.links
      ? Object.keys(data?.links).includes('redirect_to_inactive_product')
      : null,
    redirectToInactiveProduct: getValueOrDefault(
      data?.links?.redirect_to_inactive_product,
      ''
    ),
    marketplace: {
      codeProductKabum1P: getValueOrDefault(
        attributes?.marketplace?.code_product_kabum_1P,
        0
      ),
      state: getValueOrDefault(attributes?.marketplace?.state, ''),
      price: getValueOrDefault(attributes?.marketplace?.price, 0)
    }
  }
}

export function formatBaseProductData(product: BaseProductData) {
  return {
    brand: product.manufacturer.name,
    category: product.category,
    crossCart: getValueOrDefault(product.crossCart, []),
    image: getValueOrDefault(product.images ? product.images[0] : '', ''),
    name: product.name,
    originId: 1,
    price: getValueOrDefault(product.priceWithDiscount, 0),
    productCode: product.code,
    quantity: 1,
    sellerId: product.sellerId,
    sellerName: product.sellerName
  }
}
