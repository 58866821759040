import { DebounceInput } from 'react-debounce-input'

import { IconClose, IconSearch } from '@k_frontend/icons'
import { SrOnly } from '@k_frontend/ui'

import { useHeaderContainer } from 'containers/HeaderContainer/HeaderContainer.hook'
import * as S from '../Searchbar.styles'
import { PropsSearchbarHOC } from '../Searchbar.types'
import * as M from './SearchbarMobile.styles'

function SearchbarMobile({
  searchText,
  searchResult,
  handleChange,
  handleFormOnSubmit,
  cleanSearch,
  inputId
}: PropsSearchbarHOC) {
  const { isViewFloatingMenu } = useHeaderContainer()

  return (
    <M.Container
      isSearchResult={searchResult.length > 0}
      className='containerSearchBarMobile'
    >
      <M.Form onSubmit={handleFormOnSubmit} action='/busca' method='GET'>
        <SrOnly as='label' htmlFor={inputId}>
          Buscar Produtos
        </SrOnly>
        <DebounceInput
          id={inputId}
          autoComplete='off'
          minLength={3}
          debounceTimeout={700}
          onChange={(event) => handleChange(event.target.value)}
          value={searchText}
          placeholder='Aperte o K e busque aqui'
          type='text'
          name='query'
          className='id_search_input'
        />

        {!searchText.length ? (
          <M.Button type='submit' aria-label='Buscar'>
            <IconSearch aria-hidden />
          </M.Button>
        ) : (
          <M.Button
            type='reset'
            onClick={() => cleanSearch()}
            aria-label='Limpar busca'
          >
            <IconClose color='#7f858d' width={14} height={14} aria-hidden />
          </M.Button>
        )}
      </M.Form>
      <M.DropdownSearch
        className='DropdownSearchbar'
        isViewFloatingMenu={isViewFloatingMenu}
      >
        {searchResult.map((item) => (
          <S.Link key={item.codigo} href={item.url_descricao}>
            <S.ItemDropdown>
              <S.ContentItem>
                <M.TitleItem>
                  <IconSearch /> {item.nome}
                </M.TitleItem>
              </S.ContentItem>
            </S.ItemDropdown>
          </S.Link>
        ))}
      </M.DropdownSearch>
    </M.Container>
  )
}

export default SearchbarMobile
