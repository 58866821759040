import { useEffect, useState } from 'react'

import useEventListener from 'hooks/useEventListener/useEventListener'

export default function useMediaQuery(mediaQuery: string) {
  const [isMatch, setIsMatch] = useState(false)
  const [mediaQueryList, setMediaQueryList] = useState(null)

  useEffect(() => {
    const list = window.matchMedia(mediaQuery)
    setMediaQueryList(list)
    setIsMatch(list.matches)
  }, [mediaQuery])

  useEventListener({
    eventType: 'change',
    callback: (e) => setIsMatch(e.matches),
    element: mediaQueryList
  })

  return isMatch
}
