import { tailwindTheme } from 'theme/tailwindTheme'
import { StampProps } from './Stamp.types'

export default function Stamp({ stamps }: StampProps) {
  const isBlackLegendary = stamps?.type === 'blackLegendary'
  if (!stamps) return null

  return (
    <div
      className='px-12 py-4 font-semibold rounded-full flex items-center gap-2 shadow-md w-full h-24 desktop:px-6'
      style={{
        backgroundColor: isBlackLegendary
          ? tailwindTheme.colors.black[900]
          : stamps.backgroundColor,
        color: stamps.fontColor
      }}
    >
      <span className='text-xxs uppercase overflow-hidden text-ellipsis whitespace-nowrap'>
        {stamps.title}
      </span>
    </div>
  )
}
