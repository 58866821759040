import styled, { css } from 'styled-components'

import * as S from '../Searchbar.styles'

export const Container = styled(S.Container)`
  &:focus-within {
    ${(props) => {
      if (props.isSearchResult) {
        return css`
          &:after {
            top: 0;
            z-index: 1;
          }
        `
      }
    }}
  }
`

export const Form = styled(S.Form)`
  width: calc(100% - ${({ theme }) => theme.rem(48)});
  z-index: 10;
`

export const Button = styled(S.Button)`
  width: ${({ theme }) => theme.rem(96)};
  height: ${({ theme }) => theme.rem(44)};
  top: -6px;
  right: -80px;
  z-index: 99999;
`

export const DropdownSearch = styled(S.DropdownSearch)`
  top: ${(props) => props.theme.rem(40)};
  width: ${(props) => `calc(100% - ${props.theme.rem(16)})`};
  z-index: 99999;
`

export const TitleItem = styled(S.TitleItem)`
  width: calc(100% - ${({ theme }) => theme.rem(45)});
`
