import { notify } from 'react-notify-toast'

import { useUser } from '@k_frontend/core'
import { makeLoadFeatureFlagUseCase } from 'factories/useCases/makeLoadFeatureFlagUseCase'
import { makeLoadIsMobileUseCase } from 'factories/useCases/makeLoadIsMobileUseCase'
import { useCart } from 'hooks/useCart'
import { useMiniPreCart } from 'hooks/useMiniPreCart/useMiniPreCart'
import CookiesInterface from 'interfaces/cookies'
import { call, put } from 'redux-saga/effects'
import { addProductCart } from 'services/cart'
import { Creators as CartCreators } from 'state/ducks/cart'
import { addProductToCookies } from 'utils/cart'

async function handleOnSuccess(redirectURL) {
  const isMobile = makeLoadIsMobileUseCase().load()

  if (isMobile) {
    const session = useUser.getState().session
    const isMiniPreCart = await (await makeLoadFeatureFlagUseCase()).load(
      'campaign_feature_precarrinho',
      session
    )

    if (isMiniPreCart) {
      useMiniPreCart.getState().toggleIsOpen()
      return
    }
  }

  window.location.href = redirectURL
}

function* addProductToCart(action) {
  const {
    addType,
    isWithoutRedirect,
    setLoadingState = () => null,
    handleAddToCartWithoutRedirect,
    parentComponent
  } = action
  const setProductsInCart = useCart.getState().setProductsInCart
  let { products } = action

  if (!Array.isArray(products)) products = [products]

  const ClassCookiesInterface = new CookiesInterface()
  const clientId = ClassCookiesInterface.getClientId()
  const session = ClassCookiesInterface.getSession()

  const loadingProducts = products.map((product) => ({
    code: product.code,
    tagCode: product.tagCode,
    offerIdMarketplace: product.offerIdMarketplace
  }))

  yield put(CartCreators.setBtnAddCartLoading(loadingProducts, addType))

  const payload = {
    produtos: products.map((product) => ({
      codigo: product.code,
      quantidade: 1,
      di: product?.di || product?.offer?.hashCode,
      oferta: !!product.offer && Object.keys(product.offer).length > 0,
      mercadoria_codigo: parseInt(product.tagCode, 0),
      id_marketplace: product.offerIdMarketplace
    })),
    session,
    id_cliente: clientId
  }
  yield call(setLoadingState, true)
  const data = yield call(addProductCart, payload)

  if (isWithoutRedirect) {
    handleAddToCartWithoutRedirect({ data, products, parentComponent })
    yield call(setLoadingState, false)
    return
  }

  if (data.success) {
    const { cart } = data
    cart.forEach((item) => {
      addProductToCookies(item)
    })

    const [product] = cart

    const produto = {
      code: data.cart[0]?.codigo.toString(),
      id: data.cart[0]?.id.toString(),
      seller_id: data.cart[0]?.id_vendedor?.toString() || '0',
      quantity: data.cart[0]?.quantidade.toString(),
      offer_id: data.cart[0]?.id_marketplace?.toString(),
      tag_code: data.cart[0]?.mercadoria_codigo.toString(),
      services: []
    }

    const redirectBaseUrl = `/precarrinho/${product.codigo}/${product.mercadoria_codigo}`
    const sellerOfferUrlParam = product.id_marketplace
      ? `?seller_offer_id=${product.id_marketplace}`
      : ''
    const redirectUrl = `${redirectBaseUrl}${sellerOfferUrlParam}`

    yield call(setProductsInCart, produto)
    yield call(handleOnSuccess, redirectUrl)
  } else {
    if (
      data.errorType === 'NOT_LOGGED' ||
      data.errorType === 'SESSION_EXPIRED'
    ) {
      const currentUrl = window.location.href
      window.location.href = `/login?redirect_uri=${currentUrl}`
    } else if (data.errorType === 'BLOCK_ACQUIRE') {
      window.location.href = `/limite-excedido?type=oferta`
    } else if (data.errorType === 'UNAVAILABLE_PRIME') {
      window.location.href = `/limite-excedido?type=prime`
    } else if (
      (data.errorType === 'OUT_OF_STOCK' && payload.produtos[0].oferta) ||
      data.errorType === 'UNAVAILABLE'
    ) {
      window.location.href = `/oferta-esgotada`
    } else {
      notify.show('Ocorreu um erro ao adicionar o produto no carrinho', 'error')
    }
  }

  yield call(setLoadingState, false)
  yield put(CartCreators.setBtnAddCartLoading(loadingProducts))
}

export default {
  addProductToCart
}
