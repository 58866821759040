import { parse } from 'node-html-parser'

export const getTextFromHtml = (html = '') => {
  const string = html?.replace(/<\/?[^>]+(>|$)/g, '')
  const documentFromString = parse(string)
  const textFromDocument =
    documentFromString?.textContent?.replace(/\n/g, ' ') || ''

  return textFromDocument
}
