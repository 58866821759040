import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { TimestampState } from './types'

const useTimestamp = create<TimestampState>()(
  devtools((set) => ({
    loading: true,
    timestamp: 0,
    setLoading: (loading: boolean) => set((state) => ({ ...state, loading })),
    setTimestamp: (timestamp: number) =>
      set((state) => ({ ...state, timestamp }))
  }),
  {
    name: 'useTimestamp'
  })
)

export { useTimestamp }
