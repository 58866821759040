import styled from 'styled-components'

import { AvatarStyleProps } from './ProfileAvatar.types'

export const Container = styled.figure<AvatarStyleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  height: 36px;
  width: 36px;
  border: 3px solid;
  border-color: ${(props) =>
    props.isPrime ? props.theme.colors.black(800) : '#347bbe'};

  img {
    width: 100%;
  }
`

export const Avatar = styled.img`
  border-radius: ${({ theme }) => theme.borderRadius.circle};
  height: ${({ theme }) => theme.spacing.lg};
  width: ${({ theme }) => theme.spacing.lg};
  object-fit: cover;
`
