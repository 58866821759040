import { SyntheticEvent } from 'react'

import { IMAGE_BLUR_URL } from 'settings/kernel'

import { ImageFallback } from './onNextImageError.types'

export function onNextImageError(
  event: SyntheticEvent<HTMLImageElement, Event>,
  staticURL: string,
  imageFallback: keyof typeof ImageFallback
) {
  const currentSrc = event.currentTarget?.src
  let path = `${staticURL}/${ImageFallback[imageFallback]}`
  const isSameSource = path === currentSrc

  if (isSameSource) {
    const blankPixel = IMAGE_BLUR_URL
    path = blankPixel
    event.currentTarget.onerror = null
  }

  return path
}
