import Product from '../Product'
import { AssemblyProps } from './Assembly.types'

function Assembly({ parentProduct }: AssemblyProps) {
  if (parentProduct.pcBuildAssemblyProducts.length === 0) return null

  return (
    <div className="ml-16">
      {parentProduct.pcBuildAssemblyProducts.map((product) => (
        <Product
          product={product}
          key={`${parentProduct.code}-${product.code}`}
          isAssemblyProduct
        />
      ))}
    </div>
  )
}

export default Assembly
