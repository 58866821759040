export function formatCategoryName(
  departmentName: string,
  sectionName?: string,
  categoryName?: string,
  subCategory?: string
) {
  if (sectionName && categoryName && subCategory) {
    return `${departmentName} > ${sectionName} > ${categoryName} > ${subCategory}`
  }

  if (sectionName && categoryName) {
    return `${departmentName} > ${sectionName} > ${categoryName}`
  }

  if (sectionName) {
    return `${departmentName} > ${sectionName}`
  }

  return departmentName
}
