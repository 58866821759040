import styled, { keyframes } from 'styled-components'

import { LineLoaderProps as LineLoaderStyledProps } from './LineLoader.types'

const spin = keyframes`
0% {
  transform: rotate(0deg)
}

100% {
  transform: rotate(360deg)
}
`

export const LineLoader = styled.div<LineLoaderStyledProps>`
  width: ${({ theme, size }) => theme.rem(size)};
  height: ${({ theme, size }) => theme.rem(size)};

  background: transparent;
  border: ${({ theme, thickness }) => theme.rem(thickness)} solid
    ${({ color }) => color};
  border-top-color: ${({ color }) => color.slice(0, -2)};
  border-radius: 50%;

  animation: ${spin} 0.6s linear infinite;
`
