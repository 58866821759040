import { IconHeaderCart } from '@k_frontend/icons'
import { Badge } from '@k_frontend/ui'
import { useMiniCart } from 'hooks/useMiniCart/useMiniCart'
import { isMiniCartOn } from 'utils/miniCartFeatureFlag'

import { makeLoadIsMobileUseCase } from 'factories/useCases/makeLoadIsMobileUseCase'
import { useHeaderContext } from '../Header.context'

const isMobile = makeLoadIsMobileUseCase().load()

function CartButton() {
  const { cartProductsQuantity } = useHeaderContext()
  const { toggleIsOpen, setTriggerPosition } = useMiniCart()

  const badge = (
    <Badge content={cartProductsQuantity} position='topRight'>
      <IconHeaderCart size={20} aria-hidden />
    </Badge>
  )

  function handleOnClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setTriggerPosition(e.currentTarget.getBoundingClientRect())
    toggleIsOpen()
  }

  if (!isMobile && isMiniCartOn()) {
    return (
      <button
        className='flex w-[36px] items-center justify-center bg-transparent'
        onClick={handleOnClick}
        aria-label='Abrir o carrinho'
        type='button'
      >
        {badge}
      </button>
    )
  }

  return (
    <a
      className='flex size-[36px] cursor-pointer items-center justify-center'
      id='linkCarrinhoHeader'
      title='Carrinho'
      href={`/carrinho`}
    >
      {badge}
    </a>
  )
}

export default CartButton
