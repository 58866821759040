import { useState } from 'react'

export function useAccountMenuDrawer() {
  const [isHover, setIsHover] = useState(false)

  return {
    isHover,
    setIsHover
  }
}
