import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black(400)};
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.sm};
  gap: ${({ theme }) => theme.spacing.sm};
  padding: 0 ${({ theme }) => theme.spacing.sm};

  svg {
    transform: rotate(-90deg);
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.font(16, 28, 700, 'uppercase')}
  color: ${({ theme }) => theme.colors.black(800)};
  flex: 1;
`

export const MenuLink = styled.a`
  ${({ theme }) => theme.font(12, 12, 600, 'uppercase')}
  color: ${({ theme }) => theme.colors.secondary(500)};
  text-decoration: none;
`

export const Content = styled.div`
  display: flex;
  position: relative;
  overflow-y: auto;
  padding: 0 ${({ theme }) => theme.spacing.sm};
`

export const List = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.rem(1)};
  position: relative;
  animation-name: slide;
  animation-duration: 0.4s;

  @keyframes slide {
    from {
      left: 50%;
    }
    to {
      left: 0;
    }
  }
`

export const ItemList = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.font(14, 24, 400)}
  border: 0;
  background-color: ${({ theme }) => theme.colors.white(500)};
  color: ${({ theme }) => theme.colors.black(700)};
  padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  text-align: left;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  scroll-snap-stop: normal;
  scroll-snap-align: end;
  border-radius: ${({ theme }) => theme.borderRadius.md};

  svg {
    transform: rotate(90deg);
  }
`

export const BannerSkeleton = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.rem(112)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    width: 100%;
    height: ${({ theme }) => theme.rem(131)};
  }
`

export const Redirect = styled.a`
  display: flex;
  width: 100%;
  object-fit: cover;
  cursor: pointer;

  img {
    width: 100%;
    height: ${({ theme }) => theme.rem(112)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    width: 100%;

    img {
      width: 100%;
      height: ${({ theme }) => theme.rem(131)};
    }
  }
`
