import { tv } from 'tailwind-variants'

export const contentStyle = tv({
  slots: {
    content: 'grid grid-rows-[1fr_auto_auto] overflow-auto',
    loading:
      'absolute inset-0 hidden items-center justify-center bg-white-500/30',
    products:
      'flex flex-col overflow-auto pr-16 scrollbar-w-4 scrollbar-bg-black-400 scrollbar-color-secondary-500',
    total: 'grid overflow-hidden grid-cols-[1fr_auto] gap-16 py-16'
  },
  variants: {
    isLoading: {
      true: {
        loading: 'flex',
        content: 'hidden',
        products: 'hidden',
        total: 'hidden'
      }
    }
  }
})
