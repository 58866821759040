import styled, { css } from 'styled-components'

import { ImageProps, PropsStyledContainerMobile } from './Searchbar.types'

export const Container = styled.div<PropsStyledContainerMobile>`
  display: flex;

  &:focus-within {
    .DropdownSearchbar {
      display: flex;
    }

    ${(props) => {
      if (props.isSearchResult) {
        return css`
          &:after {
            content: '';
            width: 100%;
            height: 100vh;
            position: absolute;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
          }
        `
      }
    }}
  }
`
export const Form = styled.form`
  display: flex;
  position: relative;
  z-index: 1;

  > input {
    ${({ theme }) => theme.font(14)};
    position: relative;
    width: 100%;
    height: ${({ theme }) => theme.rem(36)};
    padding: ${({ theme }) => theme.spacing.xs}
      ${({ theme }) => theme.spacing.sm};

    background: ${({ theme }) => theme.colors.white(500)};
    color: ${({ theme }) => theme.colors.black(800)};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    z-index: 1;

    &::placeholder {
      ${({ theme }) => theme.font(14, 24, 400)};
      color: ${({ theme }) => theme.colors.black(600)};
    }
  }
`

export const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    left: ${({ theme }) => theme.spacing.sm};
    top: ${({ theme }) => theme.rem(6)};
    height: ${({ theme }) => theme.spacing.md};
    width: ${({ theme }) => theme.rem(10)};
    background: ${({ theme }) => theme.colors.white(500)};
  }

  > svg {
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 0;
    height: auto;
  }
`

export const DropdownSearch = styled.div`
  display: none;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white(500)};
  position: absolute;
  left: 0;
  z-index: 9999;

  &:hover {
    display: flex;
  }
`

export const ItemDropdown = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.rem(3)};
  border-bottom: solid 1px ${({ theme }) => theme.colors.black(400)};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black(300)};
  }
`

export const ContentItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

export const ImageItem = styled.div<ImageProps>`
  width: ${({ theme }) => theme.rem(45)};
  height: ${({ theme }) => theme.rem(30)};
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
`

export const TitleItem = styled.div`
  ${({ theme }) => theme.font(14, 24, 400)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.black(700)};
  padding: 0 ${({ theme }) => theme.spacing.xxs};
`

export const Link = styled.a`
  text-decoration: none;
`
