import { LocalServicesGatewayProtocol } from 'data/protocols/localServicesGatewayProtocol'
import { UpdateServicesUseCaseDomain } from 'domain/usecases/updateServices'

export class UpdateServicesUseCase implements UpdateServicesUseCaseDomain {
  private readonly localServicesGateway: LocalServicesGatewayProtocol

  constructor(localServicesGateway: LocalServicesGatewayProtocol) {
    this.localServicesGateway = localServicesGateway
  }

  update(stringfiedServices: string): void {
    this.localServicesGateway.update(stringfiedServices)
  }
}
