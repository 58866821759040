import { useEffect } from 'react'
import Skeleton from 'react-loading-skeleton'

import { IconBackward, IconForward } from '@k_frontend/icons'
import { SrOnly } from '@k_frontend/ui'
import axios from 'axios'
import { HeaderCarousel, TitleCarousel } from 'components/HeaderCarousel'
import LinkHome from 'components/LinkHome'
import Product from 'components/Product/ProductGrid'
import { useTimestamp } from 'hooks/useTimestamp'
import { BACKEND_TIME_STAMP } from 'settings/kernel'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { shallow } from 'zustand/shallow'

import 'swiper/css'
import 'swiper/css/navigation'

import * as S from './styles'

const useTimestampSelector = (state) => ({
  timestamp: state.timestamp,
  setTimestamp: state.setTimestamp,
  setLoading: state.setLoading
})

export function CatalogCarousel({
  aboveProducts = null,
  id,
  isLoading = false,
  showSkeletonTitle = true,
  showSkeletonFilters = true,
  list,
  onProductClick,
  header,
  showAll,
  isOverflow = false,
  smartHintFeature = '',
  cookieIsMobile,
  overlappedArrow = false
}) {
  const { setTimestamp, setLoading, timestamp } = useTimestamp(
    useTimestampSelector,
    shallow
  )

  const settings = {
    slidesPerView: 'auto',
    spaceBetween: 8,
    loop: true,
    navigation: {
      prevEl: `.${id}.swiper-back`,
      nextEl: `.${id}.swiper-forward`
    },
    a11y: {
      containerMessage: 'Carrossel de produtos em oferta',
      nextSlideMessage: 'Próximo produto',
      prevSlideMessage: 'Produto anterior',
      lastSlideMessage: 'Esse é o último produto',
      firstSlideMessage: 'Esse é o primeiro produto'
    },
    modules: [Navigation, A11y]
  }

  function fetchTimestamp() {
    setLoading(true)
    axios
      .get(BACKEND_TIME_STAMP)
      .then(({ data }) => {
        setTimestamp(data)
      })
      .catch((e) => console.error(JSON.stringify(e)))
      .finally(() => setLoading(false))
  }

  const onFocus = () => {
    fetchTimestamp()
  }

  useEffect(() => {
    if (timestamp === 0) {
      fetchTimestamp()
    }
    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  }, [])

  if (isLoading) {
    return (
      <div>
        <SrOnly>Carregando</SrOnly>
        {showSkeletonTitle && <Skeleton width={200} height={24} count={1} />}
        {showSkeletonFilters && (
          <S.SkeletonFilters>
            <Skeleton count={6} />
          </S.SkeletonFilters>
        )}
        <S.SkeletonCarousel>
          <Skeleton count={6} />
        </S.SkeletonCarousel>
      </div>
    )
  }

  const Link = showAll?.link && (
    <LinkHome
      id={showAll?.id}
      text={showAll?.text || 'VER TODOS'}
      link={showAll?.link}
      className='linkFooterCarousel'
    />
  )

  const headerComponent = header && (
    <HeaderCarousel>
      <div className='headerCarousel-icon'>{header.icon}</div>
      <TitleCarousel>{header.title}</TitleCarousel>
      {Link}
    </HeaderCarousel>
  )

  const linkFooter = !header && <div className='linkFooter'>{Link}</div>

  return (
    <S.Container id={id} className='carrosselProdutos'>
      {headerComponent}
      {aboveProducts}
      <S.CarouselContainer
        overlappedArrow={overlappedArrow}
        isOverflow={isOverflow}
      >
        <button type='button' className={`${id} swiper-back`}>
          <IconBackward width={32} height={32} />
        </button>
        <Swiper {...settings}>
          {list.map((product, index) => {
            product.position = index + 1
            return (
              <SwiperSlide key={product.code}>
                <Product
                  product={product}
                  fnOnProductClick={onProductClick}
                  smartHintFeature={smartHintFeature}
                  cookieIsMobile={cookieIsMobile}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <button type='button' className={`${id} swiper-forward`}>
          <IconForward width={32} height={32} />
        </button>
      </S.CarouselContainer>

      {linkFooter}
    </S.Container>
  )
}
