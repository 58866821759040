import { IconArrowDown } from '@k_frontend/icons'
import * as Accordion from '@radix-ui/react-accordion'
import IconBuildCircle from 'components/Icons/IconBuildCircle'
import IconComputer from 'components/Icons/IconComputer'
import { useCart } from 'hooks/useCart'
import { useShoppingCart } from 'hooks/useShoppingCart/useShoppingCart'
import { optionStyle } from './AssemblySelection.styles'
import { AssemblySelectionProps } from './AssemblySelection.types'

export function AssemblySelection({
  sellerId,
  originId,
  productCode,
  isConfirmation
}: AssemblySelectionProps) {
  const { removedAssemblies, updateAssemblySelection } = useShoppingCart()

  const assemblyRemoved = removedAssemblies.find(
    (assembly) =>
      assembly.sellerId === sellerId &&
      assembly.originId === originId &&
      assembly.productCode.toString() === productCode.toString()
  )

  function renderOption(label: string, value: boolean) {
    return (
      <label className='flex gap-8 text-xs text-black-800'>
        <input
          type='radio'
          className={optionStyle({ checked: !assemblyRemoved === value })}
          onClick={() =>
            updateAssemblySelection(
              {
                sellerId,
                productCode,
                originId
              },
              value
            )
          }
          checked={!assemblyRemoved === value}
        />
        {label}
      </label>
    )
  }

  return (
    <Accordion.Root
      type='multiple'
      defaultValue={['assembly']}
      disabled={isConfirmation}
      className='bg-black-300 py-16 px-24'
    >
      <Accordion.Item
        value='assembly'
        className='group flex flex-col data-[state="open"]:gap-16'
      >
        <Accordion.Header>
          <Accordion.Trigger
            disabled={isConfirmation}
            className='text-sm w-full flex items-center justify-between font-bold text-black-800 uppercase'
          >
            <span className='flex items-center gap-8'>
              <IconBuildCircle /> Montagem do PC
            </span>
            {!isConfirmation && (
              <IconArrowDown
                width={9}
                height={6}
                aria-hidden
                className='arrow-icon rotate-180 group-data-[state="open"]:rotate-0 transition-all duration-500'
              />
            )}
          </Accordion.Trigger>
        </Accordion.Header>
        {!isConfirmation && (
          <Accordion.Content className='flex flex-col gap-8'>
            <div className='flex gap-8 items-center'>
              <IconComputer />
              <p className='uppercase text-xs font-bold text-black-800'>
                Deseja receber seu PC montado?
              </p>
            </div>

            <div className='flex flex-col gap-8'>
              {renderOption('Receber meu PC montado', true)}
              {renderOption('Receber meu PC desmontado', false)}
            </div>

            <p className='text-xxs text-black-800'>
              Optando pelo PC desmontado, estou ciente de que as peças serão
              enviadas em suas caixas originais, separadamente.
            </p>
          </Accordion.Content>
        )}
      </Accordion.Item>
    </Accordion.Root>
  )
}
