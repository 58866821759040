import { JSX } from 'react'

import { Separator } from '@k_frontend/ui'
import theme from 'theme'
import { currency } from 'utils/paymentOptions'

import { ServicesSummaryProps } from './ServiceSummary.types'

function ServiceSummary({ services, subtotalServices }: ServicesSummaryProps) {
  function generateServiceList() {
    const serviceList: JSX.Element[] = []

    services.forEach((service, index) => {
      const selectedService = service.option.find((option) => option.selected)

      if (selectedService) {
        if (serviceList.length > 0) {
          serviceList.push(
            <Separator
              key={`separator-${index}`}
              orientation='vertical'
              size={theme.spacing.sm}
              background={theme.colors.secondary(500)}
            />
          )
        }

        serviceList.push(
          <div key={selectedService.code} className=' flex gap-4 leading-5'>
            <span className='text-xs text-black-700 font-semibold'>
              {selectedService.name}
            </span>
            -
            <span className='text-xs text-secondary-500'>
              {selectedService.description}
            </span>
          </div>
        )
      }
    })

    return serviceList
  }

  const serviceList = generateServiceList()

  const renderServiceList = serviceList.length > 0 && (
    <div className='flex items-center gap-8 mt-16'>{serviceList}</div>
  )

  return (
    <>
      {renderServiceList}
      <h4 className='leading-5 mt-8'>
        <b className='text-black-800 text-sm'>Subtotal de serviços: </b>
        <span className='text-black-800 text-sm'>
          {currency(subtotalServices)}
        </span>
      </h4>
    </>
  )
}

export default ServiceSummary
