import { LocalFeatureFlagGatewayProtocol } from 'data/protocols/localFeatureFlagGatewayProtocol'
import { RemoteFeatureFlagGatewayProtocol } from 'data/protocols/remoteFeatureFlagGatewayProtocol'
import { LoadFeatureFlagUseCaseDomain } from 'domain/usecases/loadFeatureFlag'

export class LoadFeatureFlagUseCase implements LoadFeatureFlagUseCaseDomain {
  private readonly localFeatureFlagGateway: LocalFeatureFlagGatewayProtocol
  private readonly remoteFeatureFlagGateway: RemoteFeatureFlagGatewayProtocol

  constructor(
    localFeatureFlagGateway: LocalFeatureFlagGatewayProtocol,
    remoteFeatureFlagGateway: RemoteFeatureFlagGatewayProtocol
  ) {
    this.localFeatureFlagGateway = localFeatureFlagGateway
    this.remoteFeatureFlagGateway = remoteFeatureFlagGateway
  }

  async load(campaignKey: string, session?: string) {
    let flagValue: boolean | string =
      this.localFeatureFlagGateway.load(campaignKey)

    if (flagValue === undefined) {
      flagValue = await this.remoteFeatureFlagGateway.load(campaignKey, session)
      this.localFeatureFlagGateway.save(campaignKey, flagValue)
    }

    return Boolean(flagValue)
  }
}
