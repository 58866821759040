import { HttpClient, UnexpectedError } from '@k_frontend/core'
import { remoteLoadSearch } from 'data/models'
import { SearchModel } from 'domain/models'
import { LoadSearch } from 'domain/usecases'

import { RemoteLoadSearchModel } from './remoteLoadSearch.types'

export class RemoteLoadSearch implements LoadSearch {
  private readonly url: string
  private readonly httpClient: HttpClient<RemoteLoadSearchModel.Response>

  constructor(
    url: string,
    httpClient: HttpClient<RemoteLoadSearchModel.Response>
  ) {
    this.httpClient = httpClient
    this.url = url
  }

  async load(
    headers: RemoteLoadSearchModel.Headers,
    params: RemoteLoadSearchModel.Params
  ): Promise<SearchModel.Model> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
      headers,
      params: { ...params, include: 'gift' }
    })

    switch (httpResponse.statusCode) {
      case HttpClient.StatusCode.ok:
        return remoteLoadSearch(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
