const CustomException = require('../')
const { redirectToLogout, redirectToHome } = require('../../exception/redirect')
const { ERROR } = require('../../utils/logger/level')
const { SERVICE_NAME } = require('../../utils/logger/service')
const {
  AUTH_EXCEPTION_ERROR,
  AXIOS_EXCEPTION_ERROR,
  REQUEST_ERROR,
  SUCCESS_FALSE_ERROR
} = require('../../utils/logger/types')

const self = {}

const dd = {
  service: SERVICE_NAME
}

self.axiosException = (e, serviceName) => {
  if (!(e instanceof Error)) {
    return new CustomException({
      msg: SUCCESS_FALSE_ERROR,
      content: e,
      errorType: SUCCESS_FALSE_ERROR
    }) // TODO melhorar msg
  }

  try {
    let url = e?.response?.config?.url
    const method = e?.response?.config?.method
    const baseURL = e?.response?.config?.baseURL
    const data = e?.response?.config?.data
    let payload
    try {
      payload = JSON.parse(data)
      // TODO: pensar numa forma melhor de fazer isso ou começar a
      // capturar e logar esse catch
      // eslint-disable-next-line no-empty
    } catch (error) {}

    const request = { url, method, baseURL, payload }

    const status = e?.response?.status
    const body = e?.response?.data
    const response = { status, body }

    const name = e?.name
    const message = `${
      serviceName ? e?.message + ' IN ' + serviceName : e?.message
    }`
    const lineNumber = e?.lineNumber
    const columnNumber = e?.columnNumber
    const stack = e?.stack

    const content = {
      request,
      response,
      e: { name, message, lineNumber, columnNumber, stack }
    }

    try {
      url = url.substring(1)
      // TODO: pensar numa forma melhor de fazer isso ou começar a
      // capturar e logar esse catch
      // eslint-disable-next-line no-empty
    } catch (error) {}

    const http = {
      status_code: status,
      url: `${baseURL}${url}`,
      useragent: 'axios',
      method
    }

    if (status === 401) {
      return redirectToLogout(
        new CustomException({
          level: ERROR,
          dd,
          msg: message,
          content,
          errorType: AUTH_EXCEPTION_ERROR,
          http
        })
      )
    }

    if (Object.keys(e).length === 0) {
      return redirectToHome(
        new CustomException({
          msg: message,
          dd,
          content,
          errorType: REQUEST_ERROR,
          http
        })
      )
    }

    return new CustomException({
      msg: message,
      dd,
      content,
      errorType: REQUEST_ERROR,
      http
    })
  } catch (error) {
    return new CustomException({
      msg: AXIOS_EXCEPTION_ERROR,
      dd,
      errorType: AXIOS_EXCEPTION_ERROR
    }) // TODO melhorar msg
  }
}

module.exports = self
