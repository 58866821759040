import { IconFlashBadgeLeftTip } from '@k_frontend/icons'
import { OfferInfoHeaderTagStyledProps } from 'components/Product/Product.types'
import { Ellipsis } from 'components/Typograph'
import styled, { css } from 'styled-components'
import { tv } from 'tailwind-variants'
import { getCardColorByState } from 'utils/getCardColorByState'

export const cardWrapper = tv({
  base: 'p-[2px] !pb-3 bg-white',
  variants: {
    type: {
      normal: '',
      legendary: 'card-wrapper card-wrapper-legendary after:h-80',
      blackLegendary: 'card-wrapper card-wrapper-legendary-black after:h-80'
    }
  }
})

export const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
`

export const ActionsHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`

export const Flash = styled(IconFlashBadgeLeftTip)`
  border-radius: ${({ theme }) => theme.borderRadius.md};
  left: ${({ theme }) => `${theme.spacing.xxs}`};
  bottom: ${({ theme }) => theme.rem(10)};
  width: ${({ theme }) => theme.rem(47)};
  height: ${({ theme }) => theme.rem(14)};
`

export const Product = styled.article`
  content-visibility: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: fit-content;
  background: ${({ theme }) => theme.colors.white(500)};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  transition: box-shadow 200ms ease;
  padding: ${({ theme }) => theme.spacing.xxxs} 0;
  
  img {
    object-fit: contain;
  }
`

export const ProductHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.rem(4)} ${({ theme }) => `${theme.spacing.xxs}`};
  margin-bottom: ${({ theme }) => theme.rem(2)};
  height: ${({ theme }) => theme.rem(32)};
`

export const WishlistButton = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
`

export const ProductBody = styled.a<{ hasNoImage: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;

  ${({ hasNoImage }) => {
    if (hasNoImage)
      return css`
        img {
          display: none;
        }
      `
  }}

  img {
    height: ${({ theme }) => theme.rem(92)};
    width: ${({ theme }) => theme.rem(92)};
  }
`

export const ProductInfo = styled.div`
  padding: 0 ${({ theme }) => `${theme.spacing.xxs}`};
  display: flex;
  flex-direction: row;
`

export const BodyInfo = styled.div`
  flex: 1;
  padding-left: ${({ theme }) => `${theme.spacing.xxs} `};
  padding-right: ${({ theme }) => `${theme.spacing.xxxs} `};
  overflow: hidden;
  .availablePricesCard,
  .unavailablePricesCard {
    padding: ${({ theme }) => `${theme.spacing.xxxs}`} 0;
  }
  .oldPriceCard {
    font-size: ${({ theme }) => theme.rem(10)};
    height: ${({ theme }) => theme.rem(10)};
  }
  .priceCard {
    font-size: ${({ theme }) => theme.rem(14)};
    height: ${({ theme }) => theme.rem(24)};
  }
  .priceTextCard {
    display: flex;
    align-items: flex-start;
    font-size: ${({ theme }) => theme.rem(10)};
    height: ${({ theme }) => theme.rem(32)};
  }
  .freeShippingTagCard,
  .openboxTagCard {
    svg {
      display: none;
    }
  }
`

export const ProductImgTags = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxs}
`

export const ProductTags = styled.div`
  display: flex;
`

export const IconGiftContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacing.xxxs};
  height: ${({ theme }) => theme.rem(12)};
  svg path {
    fill: ${({ theme }) => theme.colors.primary(300)};
  }
`

export const ContentProductName = styled.div`
  width: 100%;
`

export const ProductName = styled(Ellipsis)`
  ${({ theme }) => theme.font(12, 16, 400)};
  color: ${({ theme }) => theme.colors.black(800)};
  height: ${({ theme }) => theme.rem(48)};
`

export const TagsContainer = styled.div`
  height: ${({ theme }) => theme.rem(18)};
`

export const ProductFooter = styled.div`
  width: 100%;
  margin-top: auto;
  padding: 0 ${({ theme }) => theme.spacing.xxs};
`

export const WrapperRatingStars = styled.div`
  display: flex;
  align-items: center;

  .ratingCard {
    width: ${({ theme }) => theme.rem(107)};
    justify-content: flex-start;
    position: relative;
  }
  .labelTotalAvaliacoes {
    font-size: ${({ theme }) => theme.rem(12)};
    margin-top: 0px;
  }
`

export const SponsoredText = styled.span`
  ${({ theme }) => theme.font(12)};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black(600)};
  padding-left: ${({ theme }) => theme.spacing.xxs};
`

export const RatingLabel = styled.span`
  ${({ theme }) => theme.font(12)}
  color: ${({ theme }) => theme.colors.black(600)};
  padding-left: ${({ theme }) => theme.spacing.xxxs};
`
