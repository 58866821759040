import { IconLogoPrimeHorizontal } from '@k_frontend/icons'
import { IconSetup } from '@k_frontend/icons'
import { Ellipsis } from 'components/Typograph'
import { tailwindTheme } from 'theme/tailwindTheme'
import { currency } from 'utils/paymentOptions'
import Assembly from './Assembly/Assembly'
import { AssemblySelection } from './AssemblySelection'
import { FREE_PRICE_THRESHOLD } from './Product.constants'
import { productStyle } from './Product.styles'
import { ProductProps } from './Product.types'
import Services from './Services/Services'

function Product({
  sellerId,
  originId,
  product,
  isAssemblyProduct
}: ProductProps) {
  const { wrapper, imageWrapper, manufacturer } = productStyle({
    assembly: isAssemblyProduct,
    hasAssembly: product.hasAssembly
  })

  const showPrime = !product.isPrime && product.primePrice > 0

  const productPrice =
    product.unitCashPrice > FREE_PRICE_THRESHOLD
      ? currency(product.unitCashPrice)
      : 'Grátis'

  const shouldShowService = !product.pcBuildId
  const shouldShowAssemblySelection = product.hasAssembly

  const assemblyStamp = product.pcBuildAssemblyProducts.length > 0 && (
    <div className='flex gap-4 items-center'>
      <IconSetup />
      <span className='text-xxs uppercase'>Com montagem</span>
    </div>
  )

  return (
    <>
      <div className={wrapper()}>
        <div className={imageWrapper()}>
          <img src={product.image} alt='' className='max-w-full' />
        </div>
        <div className='flex flex-1 flex-col gap-4'>
          <span className={manufacturer()}>{product.manufacture}</span>
          <Ellipsis lines={2} className='text-xs font-semibold text-black-800'>
            {product.name}
          </Ellipsis>
          {assemblyStamp}
        </div>
        <div className='flex flex-col flex-nowrap'>
          <span className='text-nowrap text-right text-xs font-normal text-black-700'>
            À vista no PIX por:
          </span>
          <p className='text-right text-sm font-bold text-secondary'>
            {productPrice}
          </p>
          {showPrime && (
            <div className='flex items-center gap-4'>
              <span className='flex bg-black px-6 py-4 rounded-8'>
                <IconLogoPrimeHorizontal
                  width={70}
                  height={12}
                  color={tailwindTheme.colors.black[300]}
                />
              </span>
              <span className='text-sm font-bold text-black-900'>
                {currency(product.primePrice)}
              </span>
            </div>
          )}
        </div>
      </div>
      {shouldShowService && (
        <Services
          services={product.services}
          subtotalServices={product.totalPriceWarrantyPartial}
        />
      )}
      {shouldShowAssemblySelection && (
        <AssemblySelection
          sellerId={sellerId}
          originId={originId}
          productCode={product.code}
        />
      )}
      <Assembly parentProduct={product} />
    </>
  )
}

export default Product
