import { ComponentProps } from 'react'

import { IconPopulars } from '@k_frontend/icons'
import { Ellipsis } from 'components/Typograph'
import { A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { currency } from 'utils/paymentOptions'

import { MiniCrossCartProps } from './MiniCrossCart.types'

function MiniCrossCart({
  products,
  onProductClick,
  isLoading
}: MiniCrossCartProps) {
  const settings: ComponentProps<typeof Swiper> = {
    slidesPerView: 'auto',
    spaceBetween: 8,
    loop: false,
    a11y: {
      containerMessage: 'Produtos relacionados ao que ',
      nextSlideMessage: 'Próximo produto',
      prevSlideMessage: 'Produto anterior',
      lastSlideMessage: 'Esse é o último produto',
      firstSlideMessage: 'Esse é o primeiro produto'
    },
    modules: [A11y]
  }

  if (isLoading) {
    return (
      <>
        <div className='flex gap-8 mb-8 items-center'>
          <span className='animate-pulse bg-black-500 w-12 h-12' />
          <span className='animate-pulse bg-black-500 h-12 w-2/3 my-2' />
        </div>
        <div className='flex gap-8 mb-8 overflow-x-hidden'>
          <div className='flex-shrink-0 animate-pulse bg-black-500 w-[130px] h-[202px] border rounded-4 ' />
          <div className='flex-shrink-0 animate-pulse bg-black-500 w-[130px] h-[202px] border rounded-4 ' />
          <div className='flex-shrink-0 animate-pulse bg-black-500 w-[130px] h-[202px] border rounded-4 ' />
        </div>
      </>
    )
  }

  return (
    <div className='mb-8 gap-8'>
      <div className='flex items-center gap-8 mb-8'>
        <IconPopulars width={12} height={12} />
        <h2 className='text-xs text-black-800 font-bold uppercase'>
          Quem comprou, comprou também
        </h2>
      </div>
      <Swiper {...settings}>
        {products.map((product) => (
          <SwiperSlide
            className='!w-[130px] !h-[202px] border border-solid rounded-4 border-black-400 p-8'
            key={product.code}
          >
            <a
              className='h-full block'
              href={`/produto/${product.code}/${product.friendlyName}`}
              onClick={() => onProductClick(product)}
              aria-label={product.name}
            >
              <div className='flex flex-col h-full gap-2'>
                <div className='w-[70px] h-[70px] self-center'>
                  <img src={product.image} alt='' className='w-full' />
                </div>
                <p className='text-xs text-black-600'>
                  {product.manufacturer.name}
                </p>
                <p>
                  <Ellipsis
                    lines={3}
                    lineHeight={16}
                    className='text-xs text-black-800 font-semibold'
                  >
                    {product.name}
                  </Ellipsis>
                </p>
                <span className='text-base text-secondary-500 font-bold mt-auto'>
                  {currency(product.priceWithDiscount)}
                </span>
              </div>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}

export default MiniCrossCart
