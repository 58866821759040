import styled, { css } from 'styled-components'

import * as S from '../Header.styles'

export const ContainerWrapper = styled(S.ContainerWrapper)`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing.xxs}
    ${({ theme }) => theme.spacing.sm};
`

export const SearchbarSection = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing.xxs};
`

export const ContentSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xxs};
`

const ItemContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: ${({ theme }) => theme.spacing.xxxs};
`

export const LeftItemContent = styled(ItemContent)`
  flex: 1;
  justify-content: start;
`

export const CenterItemContent = styled(ItemContent)`
  flex: 0;
  width: 105px;
  height: 36px;
`

export const RightItemContent = styled(ItemContent)`
  flex: 1;
  justify-content: end;
`

export const CleanSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ButtonBack = styled.button`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white(500)};
  background: transparent;

  .IconArrow {
    margin-right: ${({ theme }) => theme.spacing.xxs};
    width: ${({ theme }) => theme.rem(14)};
    height: ${({ theme }) => theme.rem(14)};
  }
`
