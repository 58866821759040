import { useUser } from '@k_frontend/core'
import { useCallback, useMemo } from 'react'
import { useMutation } from 'react-query'
import { Notice } from 'services/notice'
import type { UseNotificationCardProps } from './NotificationCard.types'

export function useNotificationCard({
  notification
}: UseNotificationCardProps) {
  const { clientId: customerId, session: customerSession } = useUser()

  const requestParams = useMemo(
    () => ({
      customerId,
      customerSession
    }),
    [customerId, customerSession]
  )

  const description = useMemo(() => {
    const regex = /(.*?)(pedido\s*\d+\.?)(.*)/
    const match = regex.exec(notification?.description)

    if (match) {
      return {
        __html: `${match[1].trim()} <b>${match[2].trim()}</b> ${match[3].trim()}`
      }
    } else {
      return { __html: notification?.description }
    }
  }, [notification])

  const redirectToNoticeUrl = useCallback(() => {
    if (notification?.notificationType === 1) {
      window.location.href =
        notification?.notificationAdditionalData?.redirectUrl
      return
    }
    return window.open(
      notification?.notificationAdditionalData?.redirectUrl,
      '_blank'
    )
  }, [notification])

  const { mutateAsync: markNoticeAsRead } = useMutation({
    mutationFn: async (noticeId: string) => {
      const serviceNotice = new Notice()
      return await serviceNotice.MakeNoticeAsRead({
        noticeId,
        ...requestParams
      })
    },
    onSuccess() {
      redirectToNoticeUrl()
    },
    onError() {
      redirectToNoticeUrl()
    }
  })

  const onClickNotificationCard = useCallback(() => {
    if (notification?.isRead) return redirectToNoticeUrl()
    markNoticeAsRead(notification?.notificationId)
  }, [notification])

  return {
    onClickNotificationCard,
    description
  }
}
