import axios from 'axios'
import { axiosException } from 'exception/axios'
import crossCartModel from 'models/crossCart'
import { CrossCartProductModel } from 'models/crossCart/crossCart.types'
import { BACKEND_CATALOG_CROSS_CART } from 'settings/kernel'

async function getCrossCart(
  productsId: number[],
  isPrime: boolean
): Promise<CrossCartProductModel[]> {
  try {
    const { data } = await axios.get(BACKEND_CATALOG_CROSS_CART, {
      params: {
        ids: productsId.join(','),
        is_prime: isPrime
      }
    })
    if (data.data) {
      return crossCartModel(data.data)
    }
    return []
  } catch (error) {
    throw axiosException(error, 'GET CROSS CART - CATALOGO')
  }
}

export default getCrossCart
