import { RemoteFeatureFlagGatewayProtocol } from 'data/protocols/remoteFeatureFlagGatewayProtocol'
import { FeatureFlag } from 'utils/featureFlag/featureFlag'

export class RemoteFeatureFlagGateway
  implements RemoteFeatureFlagGatewayProtocol
{
  private readonly featureFlagInstance: FeatureFlag

  constructor(featureFlagInstance: FeatureFlag) {
    this.featureFlagInstance = featureFlagInstance
  }

  async load(campaignKey: string, session?: string): Promise<boolean> {
    return await this.featureFlagInstance.isFeatureEnabled(campaignKey, session)
  }
}
