const { axiosException } = require('../exception/axios')
const exception = require('../exception/axios')
const { addToCartResponse, addToCartErrorResponse } = require('../models/cart')
const { ConcludeRequest, ConcludeResponse } = require('../models/conclude')
const {
  confirmationRequest,
  creditCardBody
} = require('../models/confirmationRequest')
const {
  PaymentOptionsRequest,
  PaymentOptionsResponse
} = require('../models/paymentOptions')
const { axios } = require('../services/axios')
const {
  BACKEND_ADICIONAR_CARRINHO,
  BACKEND_ORDER_CARRINHO,
  BACKEND_PAGAMENTO,
  BACKEND_CONFIRMACAO,
  BACKEND_CONCLUIR
} = require('../settings/kernel')

const self = {}

self.ErrorType = {
  UNAVAILABLE_ERROR: 'PRODUCTS_UNAVAILABLE'
}

self.addProductCart = async (payload) => {
  try {
    const { data } = await axios.post(`${BACKEND_ADICIONAR_CARRINHO}`, payload)
    return addToCartResponse(data)
  } catch (error) {
    addToCartErrorResponse()
  }
}

self.postCart = async (payload) => {
  try {
    const { data } = await axios.post(BACKEND_ORDER_CARRINHO, payload)
    return data
  } catch (error) {
    throw exception.axiosException(error, 'POST CARRINHO')
  }
}

self.getPaymentOptions = async (payload) => {
  try {
    const { data } = await axios.post(
      BACKEND_PAGAMENTO,
      PaymentOptionsRequest(payload)
    )

    if (!data.success) {
      const errorObject = {
        checkoutError: true,
        errorType: data.error_type,
        errorMsg: data.msg
      }

      if (data.error_type === self.ErrorType.UNAVAILABLE_ERROR) {
        errorObject.unavailableProducts = data.produtos
      }

      return errorObject
    }

    return PaymentOptionsResponse(data, payload.coupon)
  } catch (error) {
    throw axiosException(error, 'GET PAYMENT OPTIONS')
  }
}

self.confirmationPayment = async (payload) => {
  let body = payload.body

  const { paymentOptionName } = body

  if (paymentOptionName === 'cartao-credito') {
    body = creditCardBody(body)
  }

  const formatedData = confirmationRequest({
    sellers: payload.sellers,
    session: payload.session,
    clientId: payload.clientId,
    ...body,
    cupom: payload.coupon,
    origin: payload.origin
  })

  const format = (data) => {
    const {
      sellers,
      totals,
      customer,
      address,
      donation,
      payment,
      sitekey
    } = data

    const paymentOption = {
      code: payment.code,
      price: totals.cash_price,
      label: payment.method,
      hash: payment.hash,
      parcels: payment.parcels,
      parcel_value: payment.parcel_value,
      category: payment.category,
      interest_rates_value: payment.interest_rates_value
    }

    const siteKeyCaptcha = sitekey || ''

    return {
      sellers,
      totals,
      customer,
      address,
      donation,
      paymentOption,
      siteKeyCaptcha
    }
  }

  try {
    const { data } = await axios.post(BACKEND_CONFIRMACAO, formatedData)

    return format(data)
  } catch (e) {
    throw axiosException(e)
  }
}

self.concludePayment = async (payload) => {
  try {
    const { data } = await axios.post(
      BACKEND_CONCLUIR,
      ConcludeRequest(payload),
      {
        headers: {
          'mfa-code': parseInt(payload.req.body.mfaCode, 10) || ''
        }
      }
    )

    if (!data.success) {
      const errorObject = {
        checkoutError: true,
        errorType: data.error_type,
        errorMsg: data.msg
      }
      if (data.error_type === self.ErrorType.UNAVAILABLE_ERROR) {
        errorObject.unavailableProducts = data.produtos
      }
      return errorObject
    }

    return ConcludeResponse(data)
  } catch (e) {
    throw axiosException(e)
  }
}

module.exports = self
