const axios = require('axios')

const { endpoint } = require('../settings/kernel')

module.exports = {
  axios: axios.create({
    baseURL: endpoint,
    timeout: 30000,
    header: {}
  })
}
