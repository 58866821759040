import { RemoteCartDTO } from 'data/dto/remoteCartDTO.types'
import { LocalSellerGatewayProtocol } from 'data/protocols/localSellerGatewayProtocol'
import { RemoteCartGatewayProtocol } from 'data/protocols/remoteCartGatewayProtocol'
import { LoadCartUseCaseDomain } from 'domain/usecases/loadCart'

export class LoadCartUseCase implements LoadCartUseCaseDomain {
  private readonly localSellerGateway: LocalSellerGatewayProtocol
  private readonly remoteCartGateway: RemoteCartGatewayProtocol

  constructor(
    localSellerGateway: LocalSellerGatewayProtocol,
    remoteCartGateway: RemoteCartGatewayProtocol
  ) {
    this.localSellerGateway = localSellerGateway
    this.remoteCartGateway = remoteCartGateway
  }

  async load(cartPayload: RemoteCartDTO.Payload) {
    if (cartPayload.sellers.length === 0) return
    const cart = await this.remoteCartGateway.load(cartPayload)

    if (cart) {
      this.localSellerGateway.updateSeller(cart)
    }

    return cart
  }
}
