import { cartShippingDTO } from 'domain/dto/cartShippingDTO.types'

export class CartShipping {
  shippingType: string
  isFlash: boolean
  maxDeadline: number
  basketId: string
  deadlineParameters: any
  serviceProvider: string
  discountPrime: number
  minDeadline: number
  id: number
  name: string
  price: number
  rate: number
  fromDate: string
  toDate: string
  selected: boolean
  shippingDate: string
  shippingPeriod: string
  deliveriesUUID: string[]

  constructor(shipping: cartShippingDTO.Props) {
    this.shippingType = shipping.shippingType
    this.isFlash = shipping.isFlash
    this.maxDeadline = shipping.maxDeadline
    this.basketId = shipping.basketId
    this.deadlineParameters = shipping.deadlineParameters
    this.serviceProvider = shipping.serviceProvider
    this.discountPrime = shipping.discountPrime
    this.minDeadline = shipping.minDeadline
    this.id = shipping.id
    this.name = shipping.name
    this.price = shipping.price
    this.rate = shipping.rate
    this.fromDate = shipping.fromDate
    this.toDate = shipping.toDate
    this.selected = shipping.selected
    this.shippingDate = shipping.shippingDate
    this.shippingPeriod = shipping.shippingPeriod
    this.deliveriesUUID = shipping.deliveriesUUID
  }
}
