const SIZE = {
  mobile: 'top_home_mobile',
  tablet: 'top_home_tablet',
  desktop: 'top_home_desktop',
  desktopLarge: 'top_home_desktop_large'
}

const TEN_MINUTES_IN_MILLISECONDS = 600000

const DATALAYER_TITLE = 'fechar-banner-topo-home'

const STORAGE_NAME = 'topBannerClosed'

const PLATAFORM = {
  mobile: 'mobile',
  desktop: 'desktop'
}

const CONTEXT = 'category'
const CATEGORY_NAME = 'HOME'

export {
  CATEGORY_NAME,
  CONTEXT,
  PLATAFORM,
  STORAGE_NAME,
  DATALAYER_TITLE,
  TEN_MINUTES_IN_MILLISECONDS,
  SIZE
}
