import { makeNextCookiesClient, useAddresses, useUser } from '@k_frontend/core'
import { makeLoadCartUseCase } from 'factories/useCases/makeLoadCartUseCase'
import { makeLoadSellerUseCase } from 'factories/useCases/makeLoadSellerUseCase'
import { makeLoadStoreCodeUseCase } from 'factories/useCases/makeLoadStoreCodeUseCase'
import { makeUpdateServiceUseCase } from 'factories/useCases/makeUpdateServicesUseCase'
import { KABUM_COOKIE_URL } from 'settings/kernel'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { getCookie, setCookie } from 'cookies-next'
import useTakeAwayShipping from 'hooks/useTakeAwayShipping'
import takeAwayShippingModel from 'models/takeAwayShippingModel'
import { notify } from 'react-notify-toast'
import {
  parseStringfiedRemovedAssemblies,
  pushToStringifiedContent,
  removeFromStringifiedContent,
  stringifyProductIdentifier
} from 'utils/cart'
import { ShoppingCart } from './useShoppingCart.types'

function updateServices(get: ShoppingCart.GetStore) {
  const { cart, loadCart } = get()
  makeUpdateServiceUseCase().update(cart.stringifyServices())
  loadCart()
}

function updateAssemblySelection(
  set: ShoppingCart.SetStore,
  get: ShoppingCart.GetStore,
  product: ShoppingCart.ProductIdentifier,
  selection: boolean
) {
  const { loadCart } = get()
  const cookieName = 'removedAssemblies'
  const removedAssembliesCookie = getCookie(cookieName, {
    domain: KABUM_COOKIE_URL
  }) as string
  const stringfiedProduct = stringifyProductIdentifier(product)

  const functionToHandle = selection
    ? removeFromStringifiedContent
    : pushToStringifiedContent

  const updatedRemovedAssemblies = functionToHandle(
    removedAssembliesCookie,
    stringfiedProduct
  )
  setCookie(cookieName, updatedRemovedAssemblies, {
    domain: KABUM_COOKIE_URL
  })
  set({ removedAssemblies: parseStringfiedRemovedAssemblies() })
  loadCart()
}

async function loadCart(set: ShoppingCart.SetStore) {
  set({ isLoading: true })
  const { clientId, session } = useUser.getState()
  const { currentAddress } = useAddresses.getState()
  const cookieClient = makeNextCookiesClient()

  const { pointToPickUp, takeBySomeoneCPF, takeBySomeoneName } =
    useTakeAwayShipping.getState()

  try {
    const cart = await makeLoadCartUseCase().load({
      client_id: clientId,
      session,
      request_origin: 'mini_carrinho',
      address: {
        code: currentAddress?.addressCode,
        zip_code: currentAddress?.zipCode
      },
      coupon: [],
      media_origin: cookieClient.get({
        name: 'origem',
        options: {
          domain: KABUM_COOKIE_URL
        }
      }),
      sellers: makeLoadSellerUseCase().load(),
      store: makeLoadStoreCodeUseCase().load(),
      take_away: pointToPickUp
        ? takeAwayShippingModel(
            pointToPickUp,
            takeBySomeoneCPF,
            takeBySomeoneName
          )
        : null
    })
    set({ cart, isLoading: false })
  } catch {
    set({ isLoading: false })
    notify.show('Ocorreu um erro ao obter seu carrinho', 'error', 5000)
  }
}

const useShoppingCart = create<ShoppingCart.Store>()(
  devtools(
    (set: ShoppingCart.SetStore, get: ShoppingCart.GetStore) => ({
      cart: null,
      isLoading: false,
      updateServices: () => updateServices(get),
      removedAssemblies: parseStringfiedRemovedAssemblies(),
      updateAssemblySelection: (
        product: ShoppingCart.ProductIdentifier,
        selection: boolean
      ) => updateAssemblySelection(set, get, product, selection),
      loadCart: () => loadCart(set)
    }),
    {
      name: 'useShoppingCart'
    }
  )
)

export { useShoppingCart }
