import styled from 'styled-components'

export const Price = styled.span<{ color: string }>`
  color: ${({ color, theme }) => color || theme.colors.black(550)};
  ${({ theme }) => theme.font(20, 30, 700)};
  line-height: 1;

  span {
    text-shadow: 0 0 4px
      ${({ color, theme }) => color || theme.colors.black(550)};
    color: transparent;
    filter: blur(4px);
  }
`
