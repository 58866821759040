import styled from 'styled-components'

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.font(12, 16, 400)};
  margin: ${({ theme }) => theme.spacing.xxs} 0;

  svg {
    align-items: center;
    fill: ${({ theme }) => theme.colors.white(500)};
    width: ${({ theme }) => theme.rem(14)};
    height: ${({ theme }) => theme.rem(14)};
  }
`
export const AddressSelect = styled.div`
  ${({ theme }) => theme.font(12, 16, 400)};
  margin: 0px ${({ theme }) => theme.spacing.xxxs};
`
export const WrapperAddress = styled.div`
  display: flex;
  align-items: center;
`
