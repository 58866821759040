import { createActions, createReducer } from 'reduxsauce'

export const { Types, Creators } = createActions({
  setBtnAddCartLoading: ['products', 'addType']
})

const INITIAL_STATE = {
  loadingProducts: [],
  addType: ''
}

const setBtnAddCartLoading = (state = INITIAL_STATE, action) => {
  const { products, addType } = action

  const arrProducts = products.map((product) => {
    if (
      state.product?.code === product.code &&
      state.product?.tagCode === product.tagCode &&
      state.product?.offerIdMarketplace === product.offerIdMarketplace
    ) {
      return {}
    }

    return product
  })

  return { ...state, loadingProducts: arrProducts, addType }
}

export default createReducer(INITIAL_STATE, {
  [Types.SET_BTN_ADD_CART_LOADING]: setBtnAddCartLoading
})
