import { HttpClient } from 'adapters/httpClient'
import { ExpectedError } from 'errors'
import { HttpRequestError } from 'errors/HttpRequestError'
import { SERVICES_PUB } from 'infra/environmentVariants'
import { NOTICE } from 'settings/endpoints'
import type {
  CustomResponse,
  GetAllCustomerNoticeAPIResponse,
  GetAllCustomerNoticeResponse,
  GetAllCustomerNoticesProps,
  NoticeWithIdProps
} from './notice.types'

export class Notice {
  constructor(public readonly httpClient = new HttpClient()) {}

  async GetAllCustomerNotices({
    customerId,
    customerSession,
    isRead = '',
    page,
    itemsPerPage = null
  }: GetAllCustomerNoticesProps): Promise<GetAllCustomerNoticeResponse> {
    try {
      const urlWithParams = `${SERVICES_PUB}/${NOTICE.NOTIFICATIONS}?customer_id=${customerId}&customer_session=${customerSession}&page=${page}${itemsPerPage ? `&items_per_page=${itemsPerPage}` : ''}${isRead !== '' ? `&isread=${isRead}` : ''}`
      const { body } =
        await this.httpClient.request<GetAllCustomerNoticeAPIResponse>({
          method: 'get',
          url: urlWithParams
        })

      return {
        currentPage: body.current_page,
        nextPage: body.next_page,
        previousPage: body.previous_page,
        notifications: body?.notifications?.map((item) => ({
          notificationId: item.notification_id,
          title: item.title,
          description: item.description,
          imageUrl: item.image_url,
          createdAt: item.created_at,
          isRead: item.is_read,
          notificationType: item.notification_type,
          notificationAdditionalData: {
            imageTitleId: item.notification_additional_data?.image_title_id,
            packageDescription:
              item.notification_additional_data?.package_description,
            redirectUrl: item.notification_additional_data?.redirect_url
          }
        }))
      }
    } catch (error) {
      if (error instanceof HttpRequestError) {
        throw new ExpectedError(error.body.message, error.statusCode)
      }
    }
  }

  async MakeNoticeAsRead({
    customerId,
    customerSession,
    noticeId
  }: NoticeWithIdProps): Promise<{ message: string }> {
    const url = `${NOTICE.NOTIFICATION}/${noticeId}?customer_id=${customerId}&customer_session=${customerSession}`

    try {
      const { body } = await this.httpClient.request<CustomResponse>({
        method: 'put',
        url: url,
        params: {
          customer_id: customerId,
          customer_session: customerSession
        }
      })

      return {
        message: body.message
      }
    } catch (error) {
      if (error instanceof HttpRequestError) {
        throw new ExpectedError(error.body.message, error.statusCode)
      }
    }
  }
}
