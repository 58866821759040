import CookiesInterface from 'interfaces/cookies'
import {
  getCartRequestData,
  getProductsInCookies,
  getServicesForCartRequestData,
  sellersArrayToString,
  servicesArrayToString
} from 'utils/cart'
import { SetState, create } from 'zustand'
import { devtools } from 'zustand/middleware'

import {
  CartResponse,
  CartState,
  ProductCookie,
  ProductQuantityParamsType,
  RemoveProductParamsType,
  RemoveProductsInCartParamsType,
  RemoveServiceParamsType,
  SelectedServiceParamsType,
  SelectedShippingType,
  Seller
} from './types'

const compareService = (
  service: SelectedServiceParamsType,
  compare: SelectedServiceParamsType
) => {
  return (
    service.name === compare.name &&
    service.productCode === compare.productCode &&
    service.sellerId === compare.sellerId &&
    service.originId === compare.originId
  )
}

const getSellersWithoutProduct = (
  removeProductParams: RemoveProductParamsType,
  sellers: Seller[]
) => {
  let newSellers = sellers

  newSellers?.forEach((seller) => {
    if (seller?.id === removeProductParams?.sellerId) {
      seller?.origins?.forEach((origin) => {
        if (origin?.id === removeProductParams?.originId) {
          origin.products = origin?.products?.filter((product) => {
            return !(
              product.code === removeProductParams?.code &&
              product.tag_code !== removeProductParams?.tagCode
            )
          })
        }
      })
    }
  })

  newSellers?.forEach((seller) => {
    if (seller?.id === removeProductParams?.sellerId) {
      seller?.origins?.forEach((origin) => {
        if (origin?.id === removeProductParams?.originId) {
          origin.products = origin?.products?.filter(
            (product) => product?.code !== removeProductParams?.code
          )
        }
      })
    }
  })

  newSellers?.forEach((seller) => {
    seller.origins = seller?.origins?.filter(
      (origin) => origin?.products?.length > 0
    )
  })

  newSellers = newSellers?.filter((seller) => seller?.origins?.length > 0)

  return newSellers
}

function setSelectedShipping(
  selectedShipping: SelectedShippingType,
  set: SetState<CartState>
): void {
  set((state) => ({
    ...state,

    primeShippingValue: state.cart.sellers
      .find((seller) => seller.id === selectedShipping.sellerId)
      .origins.find((origin) => origin.id === selectedShipping.originId)
      .shipping.find((shipping) => shipping.id === selectedShipping.code)
      .discount_prime,
    cartRequestData: {
      ...state.cartRequestData,

      sellers: state.cartRequestData.sellers.map((seller) => {
        if (seller.id === selectedShipping.sellerId) {
          // atribui o retorno do map pra chave
          seller.origins = seller.origins.map((origin) => {
            if (origin.id === selectedShipping.originId) {
              return {
                ...origin,
                shipping_code: selectedShipping.code.toString(),
                shipping_date: selectedShipping.date,
                shipping_period: selectedShipping.period
              }
            }

            return origin
          })
        }

        return seller
      })
    }
  }))
}

function setProductQuantity(
  productQuantityParams: ProductQuantityParamsType,
  set: SetState<CartState>
): void {
  set((state) => ({
    ...state,

    cartRequestData: {
      ...state.cartRequestData,

      sellers: state.cartRequestData.sellers.map((seller) => {
        if (seller.id === productQuantityParams.sellerId) {
          // atribui o retorno do map pra chave
          seller.origins = seller.origins.map((origin) => {
            if (origin.id === productQuantityParams.originId) {
              // atribui o retorno do map pra chave
              origin.products = origin.products.map((product) => {
                if (product.code === productQuantityParams.code) {
                  return {
                    ...product,
                    quantity: productQuantityParams.quantity.toString()
                  }
                }

                return product
              })
            }

            return origin
          })
        }

        return seller
      })
    }
  }))
}

function removeProduct(
  removeProductParams: RemoveProductParamsType,
  set: SetState<CartState>
): void {
  set((state) => {
    const { sellers } = getCartRequestData()

    const newSellers = getSellersWithoutProduct(removeProductParams, sellers)

    new CookiesInterface().setSellers(sellersArrayToString(newSellers))

    return {
      ...state,
      cartRequestData: {
        ...state.cartRequestData,
        sellers: newSellers
      }
    }
  })
}

function removeProducts(
  removeProductsList: Array<string>,
  set: SetState<CartState>
): void {
  set((state) => {
    const { sellers } = state.cartRequestData

    const getProductParamsByCode = (code) => {
      let params

      sellers?.forEach((seller) =>
        seller?.origins?.forEach((origin) =>
          origin?.products?.forEach((product) => {
            if (product?.code === code) {
              params = {
                code,
                sellerId: seller?.id,
                originId: origin?.id,
                offerId: product?.offer_id,
                tagCode: product?.tag_code
              }
            }
          })
        )
      )

      return params
    }

    let newSellers = [...sellers]

    removeProductsList?.forEach((product) => {
      const productParams = getProductParamsByCode(product)
      newSellers = getSellersWithoutProduct(productParams, newSellers)
    })

    new CookiesInterface().setSellers(sellersArrayToString(newSellers))

    return {
      ...state,
      cartRequestData: {
        ...state.cartRequestData,
        sellers: newSellers
      }
    }
  })
}

function removeAllProducts(set: SetState<CartState>) {
  set((state) => {
    new CookiesInterface().setSellers(sellersArrayToString([]))

    return {
      ...state,

      cartRequestData: {
        ...state.cartRequestData,
        sellers: []
      }
    }
  })
}

function setSelectedService(
  selectedServiceParams: SelectedServiceParamsType,
  set: SetState<CartState>
): void {
  set((state) => {
    let services = [...state.services]

    // verifica se esse serviço já foi selecionado
    if (
      services.find((service) => compareService(service, selectedServiceParams))
    ) {
      services = services.map((service) => {
        if (compareService(service, selectedServiceParams)) {
          return {
            ...service,
            code: selectedServiceParams.code
          }
        }

        return service
      })
      // se o serviço ainda não tem nenhuma seleção só da o push adicionando
    } else {
      services.push(selectedServiceParams)
    }

    new CookiesInterface().setServices(servicesArrayToString(services))

    const newState = {
      ...state,
      services,
      cartRequestData: {
        ...state.cartRequestData,
        sellers: state.cartRequestData.sellers.map((seller) => {
          if (seller.id === selectedServiceParams.sellerId) {
            // atribui o retorno do map pra chave
            seller.origins = seller.origins.map((origin) => {
              if (origin.id === selectedServiceParams.originId) {
                // atribui o retorno do map pra chave
                origin.products = origin.products.map((product) => {
                  if (product.code === selectedServiceParams.productCode) {
                    return {
                      ...product,
                      services: services.map((service) => service.code)
                    }
                  }
                  return product
                })
              }
              return origin
            })
          }
          return seller
        })
      }
    }

    return newState
  })
}

function removeService(
  removeServiceParams: RemoveServiceParamsType,
  set: SetState<CartState>
) {
  set((state) => {
    const services = [...state.services]
    const index = services.findIndex((service) =>
      compareService(service, removeServiceParams)
    )

    services.splice(index, 1)

    new CookiesInterface().setServices(servicesArrayToString(services))

    const newState = {
      ...state,
      services,
      cartRequestData: {
        ...state.cartRequestData,
        sellers: state.cartRequestData.sellers.map((seller) => {
          if (seller.id === removeServiceParams.sellerId) {
            // atribui o retorno do map pra chave
            seller.origins = seller.origins.map((origin) => {
              if (origin.id === removeServiceParams.originId) {
                // atribui o retorno do map pra chave
                origin.products = origin.products.map((product) => {
                  if (product.code === removeServiceParams.productCode) {
                    return {
                      ...product,
                      services: services.map((service) => service.code)
                    }
                  }
                  return product
                })
              }
              return origin
            })
          }
          return seller
        })
      }
    }

    return newState
  })
}

function reloadCart(set: SetState<CartState>) {
  set((state) => ({
    ...state,
    cartRequestData: { ...state.cartRequestData }
  }))
}

function setCoupon(coupon: string, set: SetState<CartState>) {
  set((state) => ({
    ...state,
    cartRequestData: {
      ...state.cartRequestData,
      coupon
    }
  }))
}

function getProductsCart() {
  const sellersData = useCart.getState().cartRequestData
  const dataProductsInCart = getProductsInCookies(
    sellersData
  ) as ProductCookie[]

  useCart.setState((state) => ({
    ...state,
    productsInCart: dataProductsInCart
  }))
}

function removeProductsInCart(
  removeProductsInCartParams: RemoveProductsInCartParamsType,
  set: SetState<CartState>
) {
  set((state) => {
    const products = state.productsInCart
    const filteredProducts = products.filter(
      (product) =>
        !(
          product.code === removeProductsInCartParams.code &&
          product.seller_id === removeProductsInCartParams.sellerId
        )
    )

    return {
      ...state,
      productsInCart: filteredProducts
    }
  })
}

const useCart = create<CartState>()(
  devtools(
    (set) => ({
      productsInCart: [],
      loading: true,
      cart: undefined,
      primeShippingValue: 0,
      services: getServicesForCartRequestData(),
      cartRequestData: getCartRequestData(),
      setProductsInCart: (newProduct) =>
        set((state) => ({
          ...state,
          productsInCart: [...state.productsInCart, newProduct]
        })),
      setCart: (cart: CartResponse) => set((state) => ({ ...state, cart })),
      setLoading: (loading) => set((state) => ({ ...state, loading })),
      setSelectedShipping: (selectedShipping: SelectedShippingType) =>
        setSelectedShipping(selectedShipping, set),
      setProductQuantity: (productQuantityParams: ProductQuantityParamsType) =>
        setProductQuantity(productQuantityParams, set),
      setSelectedService: (selectedServiceParams: SelectedServiceParamsType) =>
        setSelectedService(selectedServiceParams, set),
      setCoupon: (coupon: string) => setCoupon(coupon, set),
      removeService: (removeServiceParams: RemoveServiceParamsType) =>
        removeService(removeServiceParams, set),
      removeProduct: (removeProductParams: RemoveProductParamsType) =>
        removeProduct(removeProductParams, set),
      removeProducts: (removeProductsList: Array<string>) =>
        removeProducts(removeProductsList, set),
      removeAllProducts: () => removeAllProducts(set),
      reloadCart: () => reloadCart(set),
      getProductsCart: () => getProductsCart(),
      removeProductsInCart: (
        removeProductsInCartParams: RemoveProductsInCartParamsType
      ) => removeProductsInCart(removeProductsInCartParams, set)
    }),
    {
      name: 'useCart'
    }
  )
)

export { useCart }
