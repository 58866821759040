import styled from 'styled-components'

export const HeaderCarousel = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.rem(16)};
  .headerCarousel-icon {
    display: flex;
    > svg {
      width: ${({ theme }) => theme.rem(16)};
    }
  }
`

export const TitleCarousel = styled.h2`
  ${({ theme }) => theme.font(16, 14, 700)};
  text-transform: uppercase;
  flex: 1;
  color: ${({ theme }) => theme.colors.black(800)};
  padding-left: ${({ theme }) => theme.rem(5)};
  user-select: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    ${({ theme }) => theme.font(20, 14, 700)};
  }
`
