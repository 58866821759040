import { ReactElement } from 'react'

import { DepartmentsMenuBaseProps, ItemMenu } from '../DepartmentsMenu.types'

export interface DropdownColumnProps {
  list: ItemMenu[]
  showRedirectLink?: ReactElement
  handleOpenMenu?: DepartmentsMenuBaseProps['handleEnterSection']
  handleMouseLeave?: () => void
  onClick?: (item: ItemMenu) => void
  hrefPrefix: string
  hideArrow?: boolean
}

export enum DirectionEnum {
  UP = 'up',
  DOWN = 'down'
}
