import { Col, Container, Row, Separator } from '@k_frontend/ui'
import { Button } from '@kbm/button'
import { Root as AccordionRoot } from '@radix-ui/react-accordion'
import * as Dialog from '@radix-ui/react-dialog'
import ImportantInformations from 'components/PreCheckout/ImportantInformations'
import Service from 'components/PreCheckout/Services/Service'
import { ProductServiceState, useProductService } from 'hooks/useProductService'
import { useState } from 'react'
import { ServiceStepProps } from './ServiceStep.types'

const useProductServiceSelector = (state: ProductServiceState) => ({
  availableServices: state.availableServices,
  baseProductData: state.baseProductData
})

function ServiceStep({ services }: ServiceStepProps) {
  const { availableServices, baseProductData } = useProductService(
    useProductServiceSelector
  )
  const [readMore, setReadMore] = useState(false)

  return (
    <>
      <Dialog.Title className='flex gap-12 pb-8 items-center px-16'>
        <span className='text-black-800 text-sm font-semibold'>
          Adicionar serviços para esse produto?
        </span>
      </Dialog.Title>

      <div className='px-16 mb-8'>
        <Separator />
      </div>

      <AccordionRoot
        type='multiple'
        defaultValue={availableServices}
        className='mr-16 overflow-y-auto scrollbar-w-4 scrollbar-bg-black-400 scrollbar-color-secondary-500'
      >
        <Container
          sm={{ overflowLeft: true, overflowRight: true }}
          md={{ overflowLeft: true, overflowRight: true }}
        >
          <Row>
            <Col>
              {services.map((service) => (
                <Service
                  service={service}
                  key={service.code}
                  productCode={baseProductData.productCode}
                  shouldShowAdvantages={false}
                  shouldShowTerms={false}
                />
              ))}
            </Col>
          </Row>
        </Container>

        {readMore ? (
          <ImportantInformations />
        ) : (
          <section className='flex w-full flex-col justify-center px-16 desktop:p-0'>
            <h3
              className='mb-12 mt-12 text-xs font-bold text-black-700'
              id='important-info'
            >
              Informações Importantes
            </h3>
            <p className='text-[0.625rem] text-black-700 tablet:text-xs'>
              Seguros de Garantia Estendida Original Ampliada, Processo Susep:
              15414.900275/2014-64 e Proteção Roubo e Furto Qualificado com
              Quebra Acidental, Processo Susep: 15414.900738/2014-98, garantidos
              pela Cardif do Brasil Seguros e Garantias S.A. CNPJ:
              08.279.191/0001-84, com endereço na Avenida Presidente Juscelino
              Kubitscheck, Nº 1909, Torre Sul, 8º andar, São Paulo/SP, CEP:
              04543-907
            </p>
          </section>
        )}

        <Button
          variant={{
            type: 'ghost',
            color: 'secondary'
          }}
          className='ml-auto'
          onClick={() => setReadMore(!readMore)}
        >
          {readMore ? 'Ver menos' : 'Ver mais'}
        </Button>
      </AccordionRoot>
    </>
  )
}

export default ServiceStep
