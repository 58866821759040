import {
  IconCalendarPreOrder,
  IconGift,
  IconOfferFire,
  IconPrimeBlackHorizontal,
  IconPrimeShuriken
} from '@k_frontend/icons'

import * as S from './Tags.styles'
import { TagsProps } from './Tags.types'

export default function Tags({
  code,
  isOffer,
  isPrime,
  isPrimeLogo,
  isPreOrder,
  color = 'secondary',
  small = false,
  className,
  limit,
  hasGift
}: TagsProps) {
  const tags = []

  if (hasGift) {
    tags.push(
      <div
        className='flex items-center h-12 text-xxs/[9px] [&_path]:fill-primary-200 text-black-800 gap-2'
        key={`${code}-gift`}
        data-testid='giftContainer'
      >
        <IconGift width={10} height={10} />
        BRINDE
      </div>
    )
  }

  if (isPrimeLogo) {
    tags.push(
      <S.Tag
        small={small}
        className='primeLogoTagCard'
        key={`${code}-primeLogo`}
        data-testid='primeLogoContainer'
      >
        <IconPrimeBlackHorizontal width={80} height={14} className='w-auto' />
      </S.Tag>
    )
  }

  if (isPrime) {
    tags.push(
      <S.Tag
        small={small}
        className='primeTagCard h-12'
        key={`${code}-prime`}
        color={color}
        data-testid='primeContainer'
      >
        <IconPrimeShuriken width={12} />
        PRIME NINJA
      </S.Tag>
    )
  }

  if (isOffer) {
    tags.push(
      <S.Tag
        small={small}
        className='offerTagCard h-12'
        key={`${code}-offer`}
        color={color}
        data-testid='offerContainer'
      >
        <IconOfferFire width={12} />
        OFERTA NINJA
      </S.Tag>
    )
  }

  if (isPreOrder) {
    tags.push(
      <S.Tag
        small={small}
        className='offerTagCard leading-none [&>svg]:h-12 h-12 !font-normal !text-error-500'
        key={`${code}-preorder`}
        weight='600'
        data-testid='preOrderContainer'
      >
        <IconCalendarPreOrder width={12} />
        <span className='text-xxs'>Pré venda</span>
      </S.Tag>
    )
  }

  if (!tags.length) return null

  if (limit) tags.splice(limit)

  return (
    <S.Tags className={className} data-testid='tagsContainer'>
      {tags}
    </S.Tags>
  )
}
