import { HttpClient, NotFoundError, UnexpectedError } from '@k_frontend/core'
import { UpdateOfferModel } from 'domain/models'
import { LoadUpdateOffer } from 'domain/usecases'

import { LoadUpdateOfferModel } from './loadUpdateOffer.types'

export class RemoteLoadUpdateOffer implements LoadUpdateOffer {
  private readonly url: string
  private readonly httpClient: HttpClient<LoadUpdateOfferModel.Response>

  constructor(
    url: string,
    httpClient: HttpClient<LoadUpdateOfferModel.Response>
  ) {
    this.url = url
    this.httpClient = httpClient
  }

  async load(offerName: string): Promise<UpdateOfferModel.Model> {
    const httpResponse = await this.httpClient.request({
      method: 'get',
      url: this.url,
      params: {
        campanha: offerName
      }
    })

    switch (httpResponse.statusCode) {
      case HttpClient.StatusCode.ok:
        return httpResponse.body?.p
      case HttpClient.StatusCode.notFound:
        throw new NotFoundError()
      default:
        throw new UnexpectedError()
    }
  }
}
