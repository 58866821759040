import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'

import Image from 'next/image'
import { useRouter } from 'next/router'

import {
  PricesState,
  dataLayerEventAddToCart,
  usePrices
} from '@k_frontend/core'
import {
  IconCart,
  IconCloseFill,
  IconJustArrived,
  IconLogoPrimeHorizontal,
  IconOpenboxFlag,
  IconPrimeShuriken,
  IconTimerFilled
} from '@k_frontend/icons'
import OfferTimer from 'components/OfferTimer'
import Button from 'components/Product/Button'
import { useTimestamp } from 'hooks/useTimestamp'
import { STATIC_KABUM } from 'settings/kernel'
import theme from 'theme'
import { currency } from 'utils/paymentOptions'
import { getOfferLabel } from 'utils/timestamp'
import { shallow } from 'zustand/shallow'

import { useProductService } from 'hooks/useProductService'
import { formatBaseProductData } from 'models/catalog/products'
import * as S from './Footer.styles'
import { ProductFooterProps } from './Footer.types'

const selectorUsePrices = (state: PricesState, productId: number) => ({
  prices: state.selectorPricesDetails({ state, productId })?.prices,
  loading: state.selectorPricesDetails({ state, productId })?.loading,
  offer: state.selectorPricesDetails({ state, productId })?.offer,
  error: state.pricesDetail[productId]?.error
})

export default function ProductFooter({
  product,
  isFullPrime,
  outOfStock,
  isExpired,
  isBlackLegendary = false
}: ProductFooterProps) {
  const [loading, setLoading] = useState(false)
  const router = useRouter()
  const { timestamp } = useTimestamp()
  const {
    prices,
    offer,
    loading: loadingPrices,
    error
  } = usePrices((state) => selectorUsePrices(state, product.code), shallow)
  const { flags } = product
  const { isPrime, isOffer, isOpenbox } = flags

  const setBaseProductData = useProductService(
    (state) => state.setBaseProductData
  )

  const isAvailible = product?.available
  const offerStarted =
    timestamp >= (offer?.startsAt || product?.offer?.startsAt)

  const offerTimerConfig = {
    svg: {
      height: '12px',
      marginRight: '0'
    },
    text: {
      textColor: '#fff'
    }
  }
  const offerTimerInfo = {
    startDate: offer?.startsAt || product?.offer?.startsAt,
    endDate: offer?.endsAt || product?.offer?.endsAt
  }

  const dispatch = useDispatch()

  function handleOnClickBuyProduct() {
    const productFormatted = [
      {
        name: product.name,
        id: product.code.toString(),
        price: prices?.price || product?.price,
        brand: product?.manufacturer?.name,
        category: product.category,
        quantity: 1,
        dimension20: product.sellerId,
        dimension21: product.sellerName
      }
    ]

    setBaseProductData(formatBaseProductData(product))
    dataLayerEventAddToCart({ payload: productFormatted })
    dispatch({
      type: 'ADD_PRODUCT_TO_CART',
      products: { ...product, di: offer?.hashCode || product?.offer?.hashCode },
      setLoadingState: setLoading,
      addType: 'normal'
    })
  }

  if (loadingPrices) {
    return <Skeleton width={210} height={40} />
  }

  function handleClick(): void {
    router.push(`/produto/${product.code}/${product?.friendlyName}`)
  }

  function renderTimerFooter() {
    return (
      <S.OfferTimerContainerWrapper
        className='offerProductFooter'
        data-testid='offerProductFooter'
        isPrime={isFullPrime}
        onClick={handleClick}
      >
        <S.OfferIconContainer
          isPrime={isFullPrime}
          isBlackLegendary={isBlackLegendary}
        >
          {isFullPrime ? (
            <IconPrimeShuriken />
          ) : (
            <>
              {offerStarted ? (
                <IconJustArrived
                  color={theme.colors.white(500)}
                  width={24}
                  height={24}
                />
              ) : (
                <Image
                  src={`${STATIC_KABUM}/conteudo/icons/iconTimerFast.svg`}
                  width={24}
                  height={24}
                  alt=''
                />
              )}
            </>
          )}
        </S.OfferIconContainer>

        <S.OfferTimerContainer
          isPrime={isFullPrime}
          isBlackLegendary={isBlackLegendary}
        >
          {getOfferLabel(
            timestamp,
            offer?.startsAt || product?.offer?.startsAt
          )}
          <OfferTimer
            config={offerTimerConfig}
            offerInfo={offerTimerInfo}
            showIcon={false}
            offerCode={product.offer.code}
          />
        </S.OfferTimerContainer>
      </S.OfferTimerContainerWrapper>
    )
  }

  function renderHoverBuyButton() {
    if (isOffer && !offerStarted) {
      return (
        <S.ButtonOfferComing isPrime={isFullPrime || isBlackLegendary}>
          Oferta chegando
        </S.ButtonOfferComing>
      )
    }

    return (
      <Button
        isPrime={isFullPrime}
        text='COMPRAR'
        loading={!!loading}
        disabled={error}
        fnOnClick={handleOnClickBuyProduct}
        icon={<IconCart width={20} height={20} />}
        isBlackLegendary={isBlackLegendary}
      />
    )
  }

  function getFooterNoHover() {
    const showSaleFooter =
      (!!offer || !!product.offer) &&
      (!!offer?.startsAt || !!product.offer?.startsAt) &&
      (!!offer?.endsAt || !!product.offer?.endsAt)

    if (isPrime && !isFullPrime && !isOffer && !error) {
      return (
        <S.FooterPrime
          className='primeProductFooter'
          data-testid='primeProductFooter'
          onClick={handleClick}
        >
          <span>
            <b>
              {currency(
                prices?.primePrice || product?.prime?.priceWithDiscount
              )}{' '}
              com
            </b>
            <IconLogoPrimeHorizontal width={76} height={12} />
          </span>
        </S.FooterPrime>
      )
    }

    if (!showSaleFooter && isFullPrime && !error) {
      return (
        <S.FooterPrime
          className='primeProductAndUserFooter'
          data-testid='primeProductAndUserFooter'
          onClick={handleClick}
        >
          <IconLogoPrimeHorizontal width={100} height={18} />
        </S.FooterPrime>
      )
    }

    if (showSaleFooter && isOffer && !error) {
      return renderTimerFooter()
    }

    return (
      <Button
        isPrime={isFullPrime}
        text='COMPRAR'
        loading={!!loading}
        disabled={error}
        fnOnClick={handleOnClickBuyProduct}
      />
    )
  }

  if (isExpired) {
    return (
      <S.UnavailableFooter
        className='unavailableFooterCard'
        data-testid='unavailableFooterCard'
      >
        <span>ENCERRADA</span>
      </S.UnavailableFooter>
    )
  }

  if (outOfStock) {
    return (
      <S.OutOfStockFooter
        className='unavailableFooterCard'
        data-testid='unavailableFooterCard'
      >
        <span>ESGOTADA</span>
      </S.OutOfStockFooter>
    )
  }

  if (isAvailible) {
    return (
      <>
        <S.ContainerFooterHover className='availableFooterCardHover'>
          {renderHoverBuyButton()}
        </S.ContainerFooterHover>
        <S.ContainerFooterNoHover className='availableFooterCard'>
          {getFooterNoHover()}
        </S.ContainerFooterNoHover>
      </>
    )
  }

  return (
    <>
      {isOpenbox ? (
        <>
          <S.ContainerFooterHover className='availableFooterCardHover'>
            <Button
              isPrime={isFullPrime}
              text='COMPRAR OPENBOX'
              loading={!!loading}
              fnOnClick={handleClick}
              disabled={false}
            />
          </S.ContainerFooterHover>
          <S.ContainerFooterNoHover>
            <S.UnavailableFooterOpenBox
              href={`/produto/${product.code}/${product?.friendlyName}`}
              className='unavailableFooterCard'
              data-testid='unavailableOpenBoxFooterCard'
            >
              <IconOpenboxFlag width={20} height={20} />
              <span>APENAS OPENBOX</span>
            </S.UnavailableFooterOpenBox>
          </S.ContainerFooterNoHover>
        </>
      ) : (
        <S.UnavailableFooter
          className='unavailableFooterCard'
          data-testid='unavailableFooterCard'
        >
          <IconCloseFill width={20} color='#fff' />
          <span>INDISPONÍVEL</span>
        </S.UnavailableFooter>
      )}
    </>
  )
}
