import {
  dataLayerEventRemoveFromCart,
  hasSponsoredProductAtLocalStorage
} from '@k_frontend/core'
import { useCart } from 'hooks/useCart'

import { formatSellerId } from 'utils/formatSellerId'
import { handleRemoveCartDataLayerProps } from './handleRemoveCartDataLayer.types'

function handleRemoveCartDataLayer({
  productData
}: handleRemoveCartDataLayerProps) {
  const removeProductsInCart = useCart.getState().removeProductsInCart
  const code = productData.code.toString()
  const sellerId = formatSellerId(productData.sellerId)

  const productFormatted = [
    {
      name: productData.name,
      id: productData.code.toString(),
      price: productData.price,
      brand: productData.manufacturer.name,
      category: productData.category,
      quantity: 1,
      dimension01: hasSponsoredProductAtLocalStorage(productData.code),
      dimension20: Number(productData.sellerId),
      dimension21: productData.sellerName || 'KaBuM!'
    }
  ]

  dataLayerEventRemoveFromCart(productFormatted)
  removeProductsInCart({ code, sellerId })
}

export default handleRemoveCartDataLayer
