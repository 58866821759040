import styled, { keyframes } from 'styled-components'

import { KLoaderStyledProps } from './KLoader.types'

const loader = keyframes`
  0% {
    transform: translateX(0px);
    opacity: 1;
  }

  25% {
    transform: translateX(200px);
    opacity: 0;
  }

  30% {
    transform: translateX(-200px);
    opacity: 0;
  }

  55% {
    transform: translateX(0px);
    opacity: 1;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
`

const blink = keyframes`
0% {
  opacity: .3;
}

50% {
  opacity: 1;
}

100% {
  opacity: .3;
}
`

export const KLoader = styled.svg<KLoaderStyledProps>`
  width: ${({ theme, size }) => theme.rem(size)};

  #kloader1 {
    animation: ${loader} 1.5s ease-in-out infinite;
    animation-delay: 0.2s;
  }

  #kloader2 {
    animation: ${loader} 1.5s ease-in-out infinite;
  }
`

export const KLoaderText = styled.span`
  ${({ theme }) => theme.font(14, 20, 400)};

  animation: ${blink} 1.5s linear infinite;
`
