import { IconMedalGaranty, IconSecurity } from '@k_frontend/icons'

import { Services, WithoutServiceOption } from './Service.types'

export const servicesHeader = {
  garantia: {
    code: 'garantia',
    Icon: IconMedalGaranty,
    title: 'Garantia Estendida Original Ampliada',
    caption:
      'Aproveite nossos planos de <b class="text-sm">Garantia Estendida</b> e mantenha o seu produto protegido por mais tempo'
  },
  seguro: {
    code: 'seguro',
    Icon: IconSecurity,
    title: 'Roubo e Furto',
    caption:
      'Protegemos seu produto contra <b class="text-sm">Roubo e Furto Qualificado + Quebra Acidental</b> por mais 1 ano!'
  }
}

export const servicesAdvantage = {
  garantia: {
    disadvantageDescription: 'O que você perde sem a garantia estendida:',
    options: [
      {
        content: 'Conserto utilizando peças originais.',
        id: 1
      },
      {
        content: 'Mais tempo de garantia além do ofertado pelo fabricante.',
        id: 2
      },
      {
        content:
          'Cobertura técnica disponível em todo o <b class="text-sm">Brasil</b class="text-sm">.',
        id: 3
      },
      {
        content: 'O produto novo ou reparado, é entregue em casa.',
        id: 4
      }
    ],
    finePrint: 'a Garantia Estendida Original Ampliada',

    terms: {
      description:
        'Condições Contratuais do Seguro de Garantia Estendida Original',
      hasJointPayment: true
    }
  },
  seguro: {
    disadvantageDescription: 'O que você perde sem o seguro:',
    options: [
      {
        content: 'Proteção em caso de Quebra Acidental.',
        id: 1
      },

      {
        content: 'Proteção contra Roubo e Furto Qualificado.',
        id: 2
      },

      {
        content: 'Maior Segurança e Comodidade.',
        id: 3
      }
    ],
    finePrint: 'o Seguro de Roubo e Furto + Quebra Acidental',
    terms: {
      description: 'Condições Contratuais do Roubo e Furto',
      hasJointPayment: true
    }
  }
}

export const withoutServiceOptions: Record<Services, WithoutServiceOption> = {
  garantia: {
    code: 0,
    name: 'Sem garantia',
    description: 'Sem garantia',
    caption: '(Somente garantia do fabricante)',
    price: 0,
    parceledPrice: 0,
    warrantyCode: 0,
    warrantyName: ''
  },
  seguro: {
    code: 0,
    name: 'Sem proteção',
    description: 'Sem proteção',
    caption: '',
    price: 0,
    parceledPrice: 0,
    warrantyCode: 0,
    warrantyName: ''
  }
}
