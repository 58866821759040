import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { UseMiniCart } from './useMiniCart.types'

const useMiniCart = create<UseMiniCart.Store>()(
  devtools(
    (set, get) => ({
      isOpen: false,
      triggerPosition: null,
      toggleIsOpen: () => set({ isOpen: !get().isOpen }),
      setTriggerPosition: (triggerPosition: DOMRect) => set({ triggerPosition })
    }),
    {
      name: 'useMiniCart'
    }
  )
)

export { useMiniCart }
