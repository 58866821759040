// eslint-disable-next-line no-use-before-define
import React, { useEffect, useMemo, useState } from 'react'

import { ResizeHOC, getBuscaAutoComplete, useUser } from '@k_frontend/core'

import { useHeaderContainer } from 'containers/HeaderContainer/HeaderContainer.hook'
import { useHeaderContext } from '../Header.context'
import { PropsSearchbarHOC, TypeSearchResult } from './Searchbar.types'
import SearchbarDesktop from './SearchbarDesktop'
import SearchbarMobile from './SearchbarMobile'

export default function HeaderSearchbarController({ inputSearchId }) {
  const { flagBlackFriday, kernel, cookieIsMobile, searchVariant } =
    useHeaderContext()
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState<TypeSearchResult[]>([])
  const isPrime = useUser((state) => state.isPrime)
  const { isViewFloatingMenu } = useHeaderContainer()

  async function handleChange(text: string) {
    if (flagBlackFriday) return

    setSearchText(text)

    if (text.length > 1) {
      const result: TypeSearchResult[] = await getBuscaAutoComplete(
        text,
        searchVariant,
        kernel,
        isPrime
      )
      setSearchResult(result)
    } else {
      setSearchResult([])
    }
  }

  function handleFormOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    const inputValue = (
      document?.getElementById(inputSearchId) as HTMLInputElement
    )?.value
    const shouldSubmit = inputValue?.length > 0

    if (shouldSubmit)
      window.location.href = `/busca/${encodeURIComponent(inputValue)}`
    return false
  }

  function cleanSearch() {
    setSearchResult([])
    setSearchText('')
  }

  useEffect(() => {
    cleanSearch()
  }, [isViewFloatingMenu])

  return (
    <ResizeHOC<PropsSearchbarHOC>
      MobileComponent={SearchbarMobile}
      DesktopComponent={SearchbarDesktop}
      searchText={searchText}
      searchResult={searchResult}
      handleChange={handleChange}
      handleFormOnSubmit={handleFormOnSubmit}
      cleanSearch={cleanSearch}
      cookieIsMobile={cookieIsMobile}
      inputId={inputSearchId}
    />
  )
}
