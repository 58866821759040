import { Storage } from '@k_frontend/core'
import { RemoteCartDTO } from 'data/dto/remoteCartDTO.types'
import { LocalSellerGatewayProtocol } from 'data/protocols/localSellerGatewayProtocol'
import { LocalServicesGatewayProtocol } from 'data/protocols/localServicesGatewayProtocol'
import { Cart } from 'domain/entities/cart'
import { KABUM_COOKIE_URL } from 'settings/kernel'
import { parseStringfiedRemovedAssemblies } from 'utils/cart'

export class LocalSellerGateway implements LocalSellerGatewayProtocol {
  private readonly storageClient: Storage
  private readonly localServicesGateway: LocalServicesGatewayProtocol

  constructor(
    storageClient: Storage,
    localServicesGateway: LocalServicesGatewayProtocol
  ) {
    this.storageClient = storageClient
    this.localServicesGateway = localServicesGateway
  }

  parseSeller(): RemoteCartDTO.Seller[] {
    const stringfiedCartData: string = this.storageClient.get({
      name: 'sellers',
      options: {
        domain: KABUM_COOKIE_URL
      }
    })

    const stringifiedPcBuilderProducts: string =
      this.storageClient.get({
        name: 'pc-builder',
        options: {
          domain: KABUM_COOKIE_URL
        }
      }) || ''

    if (!stringfiedCartData) {
      return []
    }

    const services = this.localServicesGateway.parse()
    const removedAssemblies = parseStringfiedRemovedAssemblies()
    const splittedCartData: string[] = stringfiedCartData.split('#')

    if (splittedCartData.length === 0) {
      return []
    }

    const splittedPcBuilderProducts = stringifiedPcBuilderProducts.split('|')

    const parsedSellers = {}
    splittedCartData.forEach((cartData) => {
      const [
        sellerId,
        originId,
        shipping_code,
        shipping_date,
        shipping_period,
        shipping_name,
        id,
        productCode,
        quantity,
        offer_id,
        tag_code
      ] = cartData.split('|')

      if (!parsedSellers[sellerId]) {
        parsedSellers[sellerId] = {}
      }

      if (!parsedSellers[sellerId][originId]) {
        parsedSellers[sellerId][originId] = {
          id: originId,
          shipping_code: parseInt(shipping_code),
          shipping_date,
          shipping_period,
          shipping_name,
          products: []
        }
      }

      const serviceForProduct = services.filter(
        (service) =>
          service.sellerId === sellerId &&
          service.originId === originId &&
          service.productCode === parseInt(productCode)
      )

      const removedAssembly = removedAssemblies.find(
        (assembly) =>
          assembly.sellerId === sellerId &&
          assembly.originId === originId &&
          assembly.productCode == productCode
      )

      parsedSellers[sellerId][originId].products.push({
        id,
        code: productCode,
        quantity: parseInt(quantity) || 1,
        tag_code: tag_code || '',
        offer_id: offer_id || '',
        services: serviceForProduct
          ? serviceForProduct.map((service) => service.serviceOptionCode)
          : [],
        is_builder: splittedPcBuilderProducts.includes(productCode),
        assembly_selected: !removedAssembly
      })
    })

    return Object.keys(parsedSellers).map((sellerId) => ({
      id: sellerId,
      origins: Object.values(parsedSellers[sellerId])
    }))
  }

  updateSeller(cart: Cart): void {
    const stringfiedCoupons = cart.stringifyValidCoupons()
    const stringfiedSellers = cart.stringifySellers()
    this.storageClient.set({
      name: 'sellers',
      value: stringfiedSellers,
      options: {
        domain: KABUM_COOKIE_URL,
        path: '/'
      }
    })
    this.storageClient.set({
      name: 'coupons',
      value: stringfiedCoupons,
      options: {
        domain: KABUM_COOKIE_URL,
        path: '/'
      }
    })
  }
}
