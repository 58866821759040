import { tv } from 'tailwind-variants'

export const HeaderWrapper = tv({
  base: [
    'w-full',
    'h-[52px]',
    'flex',
    'flex-row',
    'items-center',
    'justify-center',
    'px-16',
    'z-[9997]',
    'inset-0',
    'bottom-0',
    'left-0',
    'desktop:h-64',
    'fixed',
    'transition-all',
    'duration-700',
    'ease-in-out',
    'transform'
  ],
  variants: {
    isPrime: {
      true: 'bg-black-900',
      false: 'bg-primary-500'
    },
    isViewFloatingMenu: {
      true: 'translate-y-0',
      false: '-translate-y-full'
    }
  }
})

export const ContainterSVG = tv({
  base: '[&_svg]:ml-6 [&_svg]:rotate-180 relative',
  variants: {
    rotate: {
      true: '[&_svg]:rotate-0 [&_svg]:duration-500'
    }
  }
})
