import styled, { css } from 'styled-components'

type MenuProps = {
  open?: boolean
  onlyMobile?: boolean
  isPrime?: boolean
  bottomHeaderPosition?: number
}

export const ButtonContainer = styled.button`
  cursor: pointer;
  background-color: transparent;
`

export const NavDrawer = styled.aside<MenuProps>`
  transition: left 0.3s ease;
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  width: 100%;
  position: fixed;
  top: ${({ bottomHeaderPosition }) => `${bottomHeaderPosition}px`};
  bottom: 0;
  left: ${(props) => (props.open ? '0' : '-100%')};
  background-color: ${(props) =>
    props.isPrime
      ? props.theme.colors.black(900)
      : props.theme.colors.primary(500)};
  z-index: 99;
  overscroll-behavior: contain;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    max-width: ${(props) => props.theme.rem(328)};
    height: 100vh;
    top: 0;
  }
`
export const Background = styled.div<MenuProps>`
  width: 100%;
  height: 100%;
  left: ${(props) => (props.open ? '0' : '-100vw')};
  top: 0;
  position: fixed;
  background-color: #000;
  mix-blend-mode: multiply;
  transition: opacity 1s ease;
  opacity: ${(props) => (props.open ? '.5' : '0')};
  overflow-y: hidden;
  z-index: 9;
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    display: block;
  }
`

export const Profile = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: ${(props) => props.theme.spacing.sm};
`

export const Label = styled.h4`
  ${(props) => props.theme.font(20, 30, 700)};
  color: ${({ theme }) => theme.colors.white(500)};
  margin-left: ${(props) => props.theme.spacing.sm};
`

export const CloseButton = styled.button`
  position: absolute;
  left: calc(100% + ${(props) => props.theme.spacing.sm});
  top: ${(props) => props.theme.spacing.md};
  background-color: transparent;
`

export const Content = styled.div<MenuProps>`
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.spacing.sm};
  padding-right: ${(props) => props.theme.spacing.lg};
  overflow-y: auto;
  scroll-snap-type: y mandatory;

  ${(props) =>
    props.isPrime
      ? props.theme.scrollbar(
          6,
          props.theme.colors.black(800),
          props.theme.colors.black(600)
        )
      : props.theme.scrollbar(
          6,
          props.theme.colors.primary(400),
          props.theme.colors.primary(200)
        )}
`

export const Wrapper = styled.ul<Pick<MenuProps, 'onlyMobile'>>`
  display: flex;
  align-content: center;
  flex-direction: column;

  .icons {
    padding-right: ${({ theme }) => theme.spacing.sm};
  }

  ${({ onlyMobile }) =>
    onlyMobile &&
    css`
      @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
        display: none;
      }
    `}
`
export const Separator = styled.div<MenuProps>`
  width: 100%;
  height: 1px;
  background-color: ${(props) =>
    props.isPrime
      ? props.theme.colors.black(800)
      : props.theme.colors.primary(400)};
  margin: ${(props) => props.theme.spacing.xs} 0;
`

export const Item = styled.li`
  padding: ${({ theme }) => theme.spacing.sm};
  display: flex;
  align-items: center;
  scroll-snap-stop: normal;
  scroll-snap-align: end;

  > a,
  button {
    border-radius: ${(props) => props.theme.borderRadius.md};
    text-decoration: none;
    display: flex;
    align-content: center;
    background-color: transparent;
    text-align: left;

    > svg {
      margin-right: ${(props) => props.theme.spacing.sm};
    }
    > span {
      ${(props) => props.theme.font(14, 14, 400)}
      color: ${({ theme }) => theme.colors.white(500)};
    }

    .icons {
      padding-right: ${({ theme }) => theme.spacing.sm};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.sm}`};
  }
`

export const OpenDepartmentsItem = styled(Item)<Pick<MenuProps, 'isPrime'>>`
  display: block;
  padding: 0;
  background-color: ${({ theme, isPrime }) =>
    `${isPrime ? theme.colors.black(800) : theme.colors.primary(600)}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};

  > a {
    flex: 1;
    padding: ${({ theme }) => theme.spacing.sm};
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: auto;
  padding: ${(props) => `${props.theme.spacing.lg} ${props.theme.spacing.md}`};

  > * {
    ${(props) => props.theme.font(18, 32, 600)};
    text-transform: uppercase;
  }

  > a {
    ${(props) => props.theme.font(18, 32, 600)};
    text-align: center;
    color: ${({ theme }) => theme.colors.white(500)};
    text-decoration: none;
    margin-top: ${(props) => props.theme.spacing.sm};

    &:hover {
      text-decoration: underline;
    }
  }
`

export const CategoriesContent = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black(400)};
`
