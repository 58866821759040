import styled, { css } from 'styled-components'

import { ButtonAddRemoveToCartStyledProps } from './ButtonAddRemoveToCart.types'

function getBackgroundAndFillColor(isInCart: boolean, isFullPrime: boolean) {
  if (isFullPrime) {
    return css`
      background: ${({ theme }) => theme.colors.black(900)};

      path {
        fill: ${({ theme }) => theme.colors.secondary(500)};
      }

      g path:last-of-type {
        fill: ${({ theme }) =>
          isInCart ? theme.colors.success(500) : theme.colors.secondary(500)};
      }
    `
  }

  return css`
    background: ${({ theme }) => theme.colors.secondary(500)};

    path {
      fill: ${({ theme }) => theme.colors.white(500)};
    }

    g path:last-of-type {
      fill: ${({ theme }) => (isInCart ? theme.colors.success(500) : '')};
    }
  `
}

export const ButtonHeaderCard = styled.button<ButtonAddRemoveToCartStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;

  ${({ minimalist, isInCart, isFullPrime, theme }) => {
    if (!minimalist)
      return css`
        height: ${({ theme }) => theme.rem(48)};
        border: ${({ theme }) => theme.rem(1)} solid
          ${() =>
            isFullPrime
              ? theme.colors.black(900)
              : theme.colors.secondary(500)};
        border-radius: ${({ theme }) => theme.borderRadius.md};
        ${getBackgroundAndFillColor(isInCart, isFullPrime)};
        @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
          width: ${({ theme }) => theme.rem(48)};
        }
      `
    return css`
      path {
        fill: ${({ theme }) =>
          isInCart ? theme.colors.secondary(500) : theme.colors.black(550)};
      }
    `
  }}
`
