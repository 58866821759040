import KLoader from 'components/Loaders/KLoader'

function LoadingStep() {
  return (
    <div className='flex w-full justify-center py-32'>
      <KLoader />
    </div>
  )
}

export default LoadingStep
