module.exports = class LoggerException {
  constructor({ level, dd, msg, content, errorType, http }) {
    this.level = level || 'error'
    this.dd = dd || ''
    this.msg = msg || ''
    this.content = content || {}
    this.errorType = errorType || 'ERROR'
    this.http = http || {}
  }

  getLevel() {
    return this.level
  }

  getDd() {
    return this.dd
  }

  getMsg() {
    return this.msg
  }

  getContent() {
    return this.content
  }

  getErrorType() {
    return this.errorType
  }

  getHttp() {
    return this.http
  }
}
