import { Item } from '@radix-ui/react-radio-group'
import { currency } from 'utils/paymentOptions'

import { optionStyle } from './Option.styles'
import { OptionProps } from './Option.types'

export function Option({ option }: OptionProps) {
  const { input, price } = optionStyle({ checked: option.selected })

  if (option.code === 0)
    return (
      <div className='grid grid-cols-2 items-center'>
        <label className='flex items-center gap-8 text-xs font-normal text-black-800'>
          <Item value={String(option.code)} className={input()} />
          {`${option.description}`}
        </label>
      </div>
    )

  return (
    <div className='grid grid-cols-2 items-center'>
      <label className='flex items-center gap-8 text-xs font-normal text-black-800'>
        <Item value={String(option.code)} className={input()} />
        {`${option.description} de ${option.name}`}
      </label>
      <p className={price()}>
        {`Até ${option.parcels}x sem juros de ${currency(
          option.parceledPrice
        )}`}
      </p>
    </div>
  )
}
