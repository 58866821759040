import { tv } from 'tailwind-variants'

export const cardContainer = tv({
  base: [
    'flex',
    'items-center',
    'gap-12',
    'bg-white-500',
    'py-12',
    'border-solid border-0 border-b-1 border-b-black-500',
    'cursor-pointer'
  ]
})

export const image = tv({
  base: ['w-64', 'h-64', 'object-contain']
})

export const x = tv({
  base: 'flex cursor-pointer'
})

export const mainContainer = tv({
  base: ['flex', 'flex-wrap', 'gap-8', 'w-full']
})

export const contentWrapper = tv({
  base: ['flex', 'flex-col', 'flex-grow', 'gap-4']
})

export const row = tv({
  base: ['flex', 'gap-8', 'items-center']
})

export const titleSvg = tv({
  base: ['w-20', 'h-20', '[&_path]:fill-secondary-500', 'fill-secondary-500'],
  variants: {
    isRead: {
      true: '[&_path]:fill-black-550 fill-black-550'
    }
  }
})

export const title = tv({
  base: ['text-black-800', 'text-sm', 'font-semibold'],
  variants: {
    isRead: {
      true: 'text-black-600 font-normal'
    }
  }
})

export const description = tv({
  base: [
    'text-black-700',
    'text-xs',
    '[&_b]:text-black-700',
    '[&_b]:font-semibold',
    '[&_b]:text-xs'
  ],
  variants: {
    isRead: {
      true: 'text-black-600 [&_b]:text-black-600'
    }
  }
})

export const actionsWrapper = tv({
  base: ['flex', 'gap-12', 'items-center', 'w-full', 'justify-between']
})

export const buttonDelete = tv({
  base: ['p-8', 'pt-4']
})

export const svg = tv({
  base: [
    'w-20',
    'h-20',
    'cursor-pointer',
    '[&_path]:fill-black-550',
    'hover:[&_path]:fill-secondary-500'
  ]
})

export const date = tv({
  base: ['text-xs', 'text-black-600']
})

export const alertCircle = tv({
  base: ['rounded-full bg-secondary-500 h-12 w-12']
})

export const banner = tv({
  base: ['w-64', 'h-20']
})
