import {
  IconAdresses,
  IconArrowOrangeRight,
  IconEye,
  IconEyeClose
} from '@k_frontend/icons'

import { useHeaderContext } from '../../Header.context'
import ModalSelectAddress from '../ModalSelectAddress'
import { useSelectAddress } from '../SelectAddress.hook'
import { PropsResizeAddress } from '../SelectAddress.type'
import * as S from './SelectAddressMobile.styles'

function SelectAddressMobile({
  handleCloseModal,
  handleOpenModal,
  isOpenModal,
  addressSelect
}: PropsResizeAddress) {
  const { isLoggedIn, addresses, isPrime } = useHeaderContext()
  const { firstAddressWord, remainderAddress, useSelectAddressStore } =
    useSelectAddress({ addressSelect })
  const { setShowAddress, showAddress } = useSelectAddressStore()

  return (
    <>
      {isLoggedIn && addresses?.length > 0 && (
        <div className='flex items-center justify-center'>
          <div
            onClick={() => setShowAddress(!showAddress)}
            onKeyUp={() => setShowAddress(!showAddress)}
            className='cursor-pointer mr-4 mt-12'
          >
            {showAddress ? (
              <IconEye color='#ffffff' />
            ) : (
              <IconEyeClose color='#ffffff' />
            )}
          </div>
          <S.ContainerModal onClick={handleOpenModal} isPrime={isPrime}>
            <S.WrapperAddress>
              <IconAdresses color={isPrime ? '#b6bbc2' : '#fff'} />
              <S.Label isPrime={isPrime}>Enviar para:</S.Label>
              <S.AddressSelect isPrime={isPrime}>
                {firstAddressWord}
                <span
                  className={`text-xs ml-4 ${showAddress ? 'blur-0' : 'blur-sm'}`}
                >
                  {remainderAddress}
                </span>
              </S.AddressSelect>
            </S.WrapperAddress>
            <IconArrowOrangeRight color={isPrime ? '#b6bbc2' : '#fff'} />
          </S.ContainerModal>
          <ModalSelectAddress
            isOpen={isOpenModal}
            handleClose={handleCloseModal}
          />
        </div>
      )}
    </>
  )
}

export default SelectAddressMobile
