import styled from 'styled-components'

import { ContainerOfferTimerProps } from './OfferTimer.types'

export const Container = styled.div<ContainerOfferTimerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    height: ${({ config }) => config?.svg?.height};
    margin-right: ${({ config }) => config?.svg?.marginRight};
  }

  span {
    ${({ config }) =>
      config?.text?.width ? `min-width: ${config?.text?.width}` : ''};
    font-size: ${({ config }) => config?.text?.size};
    font-weight: 700;
    line-height: ${(props) => props.theme.rem(16)};
    color: ${({ config }) => config?.text?.textColor};
    text-transform: uppercase;
  }
`

export const LoaderContainer = styled.div`
  width: 16px !important;
  height: 16px;
`
