import { makeAxiosHttpClient } from '@k_frontend/core'
import { RemoteLoadSearch } from 'data/usecases'
import { makeApiUrl } from 'factories/makeApiUrl'

export function makeRemoteLoadSearchFactory() {
  return new RemoteLoadSearch(
    makeApiUrl(`/catalog/v2/search`),
    makeAxiosHttpClient()
  )
}
