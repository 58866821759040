import { tv } from 'tailwind-variants'

export const mainPrice = tv({
  base: 'text-xs text-secondary-500 font-bold',
  variants: {
    isPrime: {
      true: 'text-black-900'
    },
    isScheduledOffer: {
      true: 'blur-sm drop-shadow-sm'
    },
    isClosedOffer: {
      true: 'text-black-550 line-through'
    }
  }
})

export const installmentPrice = tv({
  base: 'text-xs text-black-600 desktop:leading-4',
  variants: {
    isScheduledOffer: {
      true: 'blur-sm drop-shadow-sm'
    }
  }
})
